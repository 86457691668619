export default {
  CONFIRMED_CHECKOUT: {
    RESERVATION_CONFIRMED: 'Reserva confirmada',
    CONGRATULATIONS: 'Parabéns! Sua reserva foi confirmada com sucesso.',
    UNFORGETTABLE_STAY: 'Mal podemos esperar para tornar sua estadia inesquecível.',
    RESERVATION_IDENTIFIER: 'Identificador da reserva: ',
    CHECKIN_DATE: 'Data de entrada: ',
    CHECKOUT_DATE: 'Data de saída: ',
    BACK_TO_HOME: 'Voltar a tela inicial',
    ADDRESS: 'Endereço: ',
  },
  SUCCESS_CHECKOUT: {
    TITLE: 'Reserva confirmada com sucesso',
    MESSAGE: 'Para visualizar todos os detalhes clica no botão abaixo',
    BUTTON: 'Ver detalhes',
  },
  FAIL_CHECKOUT: {
    TITLE: 'Erro no pagamento',
    MESSAGE_1: 'Não foi possível processar seu pagamento',
    MESSAGE_2: 'Por favor, entre em contato com seu agente de viagens',
    BUTTON: 'Voltar a tela inicial',
  },
  CARD: {
    DAILYS: 'Diárias',
    TAXES: 'Impostos',
    SERVICE_FEE: 'Taxa de limpeza',
    PLATFORM_FEE: 'Taxa de serviço',
    LONG_STAY_DISCOUNT: 'Desconto de longa estadia',
    PARTNER_DISCOUNT: 'Desconto para Parceiros',
  },
  RESPONSE: {
    PRICE_RESERVE_ERROR: `A propriedade não está disponível para o período selecionado. 
    Por favor, selecione outro período.`,
    PRICE_RESERVE_ERROR_GENERIC: 'Erro ao buscar preço da reserva. Tente novamente mais tarde.',
    RESERVE_ERROR: 'Erro ao realizar a reserva. Tente novamente mais tarde.',
    PRICE_RESERVE_ERROR_CONFLICT: 'Erro ao buscar preço, já existe uma reserva para este período.',
  },
};
