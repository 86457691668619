import { axiosInstances } from 'services/api';
import { IApi } from 'models/application/interfaces/Api.interface';
import ChatResponse from 'models/contracts/response/ChatMessage.response';
import { ISectionHouseResume } from 'models/application/interfaces/ISectionHouseResume.interface';
import { HouseSearchResponse } from 'models/contracts/response/HouseSearch.response';
import { HouseResume } from 'models/application/HouseResume.model';
import { getUser } from 'services/Login/user.service';

const SEND_MESSAGE = '/';

const sendChatMessage = async (
  message: string,
): Promise<{ message: string; houses: ISectionHouseResume[]; checkin: string; checkout: string; guests: number }> => {
  const userData = getUser();
  const { data }: IApi<ChatResponse> = await axiosInstances.tom.instance.post(SEND_MESSAGE, {
    text: message,
    userId: userData?.id,
  });

  let sectionHouses: { title: string; data: HouseResume[] }[] = [];

  if (data.options) {
    const sections = Object.keys(data.options);

    const sectionWithoutCheckinCheckout = sections.filter((element) => element !== 'checkin' && element !== 'checkout');

    sectionHouses = sectionWithoutCheckinCheckout.map((section) => {
      const houses: any = data.options[section];

      return {
        title: section,
        data: houses.map(
          (house: HouseSearchResponse) =>
            new HouseResume({
              id: house.houseId,
              name: house.houseName,
              nameEs: house.houseNameEs,
              namePt: house.houseNamePt,
              nameUs: house.houseNameUs,
              mainPicture: house.mainPicture,
              price: {
                real: house.startPriceReal,
                dollar: house.startPriceDollar,
              },
              maxOccupancy: house.maxOccupancy,
              bedroomAmount: house.roomAmount,
              bathroomAmount: house.bathroomAmount,
              amenities: house.amenities.map((amenitie) => ({
                name: amenitie,
              })),
              coordinates: {
                lat: house.latitude,
                long: house.longitude,
              },
            }),
        ) as HouseResume[],
      };
    });
  }

  return {
    message: data?.results,
    houses: sectionHouses,
    checkin: data?.check_in,
    checkout: data?.check_out,
    guests: data?.guests,
  };
};

export default sendChatMessage;
