import React, { useContext, useEffect, useMemo } from 'react';

import ListHousesLoading from 'components/ListHousesSection/loading';
import useSearchHouse from 'hooks/useSearchHouse';
import { useSearchData } from 'layouts/Home';

import SectionList from './components/SectionList';

import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import useFilters from 'hooks/useFilters';
import ChatbotSearchContext from 'store/context/chatbot-search.context';
import { ISectionHouseResume } from 'models/application/interfaces/ISectionHouseResume.interface';
// import sortByTitlePriority from 'utils/sorts/house-list-priority.sort';
import { HouseResume } from 'models/application/HouseResume.model';

const SearchHouses: React.FC = () => {
  const { filters = [] } = useSearchData();
  const { sectionsHouses, searchHouse, isLoading } = useSearchHouse();
  const { sections } = useContext(ChatbotSearchContext);
  const { getCurrentBookingParameters } = useFilters();
  const { t } = useTranslation();

  const hasHousesOnContext = sections.length > 0;
  const housesSection = hasHousesOnContext ? sections : sectionsHouses;

  //const priorityList = ['TOWNHOMES', 'SINGLE_FAMILY', 'THEMATIC', 'FIRST_TIME_ORLANDO', 'NEAR_DISNEY'];

  const totalHousesFound = useMemo(() => {
    const uniqueHouseIds = new Set();

    housesSection.forEach((section: ISectionHouseResume) => {
      section.data.forEach((house: HouseResume) => {
        uniqueHouseIds.add(house.id);
      });
    });

    return uniqueHouseIds.size;
  }, [housesSection]);

  const foundText = totalHousesFound === 1 ? t('HOUSE_FOUND') : t('HOUSES_FOUND');

  const booking = getCurrentBookingParameters();

  useEffect(() => {
    if (!hasHousesOnContext) {
      searchHouse(booking.checkIn, booking.checkOut, filters, booking.getTotalGuests(), booking.pets, booking.elderly);
    }
    scrollToList();
  }, [
    filters,
    booking.checkIn,
    booking.checkOut,
    booking.pets,
    booking.elderly,
    booking.adults,
    booking.kids,
    booking.pcd,
    booking.babies,
  ]);

  const scrollToList = () => {
    const el = document.getElementById('section-list');
    if (el) {
      el.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div id="section-list">
      {!hasHousesOnContext && isLoading ? (
        <>
          <br />
          <br />
          <ListHousesLoading />
          <br />
          <br />
          <ListHousesLoading />
        </>
      ) : (
        <>
          <h2 className={styles.housesFound}>
            {totalHousesFound} {foundText}
          </h2>
          {/* {sortByTitlePriority(priorityList, housesSection).map((section: ISectionHouseResume, index: number) => (
            <SectionList key={index} title={section.title} housesFound={section.data} booking={booking}></SectionList>
          ))} */}
          {housesSection.map((section: ISectionHouseResume, index: number) => (
            <SectionList key={index} title={section.title} housesFound={section.data} booking={booking}></SectionList>
          ))}
        </>
      )}
    </div>
  );
};

export default SearchHouses;
