import houseDetails from './houseDetails';
import faq from './faq';
import home from './home';
import publicTranslations from './public';
import search from './search';
import checkOut from './checkout';
import condominiums from './condominiums';
import whoWeAre from './whoWeAre';
import modals from './modals';
import backoffice from './backoffice';
import administrative from './administrative';
import reservation from './reservation';

export default {
  ...checkOut,
  ...houseDetails,
  ...faq,
  ...home,
  ...publicTranslations,
  ...search,
  ...condominiums,
  ...whoWeAre,
  ...modals,
  ...backoffice,
  ...administrative,
  ...reservation,
  MENU: {
    MANAGEMENT: 'Gerenciamento',
    CHANGE_REGISTER: 'Alterar cadastro',
    ADMINISTRATIVE: 'Administrativo',
    LOGOUT: 'Sair',
    MY_RESERVATIONS: 'Minhas Reservas',
  },
  TERMS_OF_SERVICE: {
    TITLE: 'Termos de Serviço',
  },
  CANCELLATION_POLICY: {
    TITLE: 'Política de Cancelamento',
  },
  PRIVACY_POLICY: {
    TITLE: 'Política de Privacidade',
  },
  TOM_ASSISTANT: {
    FIRST_MESSAGE: 'Olá! Tudo bem? Sou o Tom, a inteligência artificial da GoWeasy.',
    SECOND_MESSAGE: 'Como posso ajudar você hoje?',
    QUESTIONS: {
      QUESTION_1: 'É a sua primeira vez em Orlando?',
      QUESTION_2: 'Escolha uma casa para suas férias',
      QUESTION_3: 'Conheça os melhores condomínios para suas férias',
      QUESTION_4: 'O que fazer em Orlando além dos parques temáticos',
    },
  },
  HOUSE_CARD: {
    PER_NIGHT: 'por noite',
    ROOMS: 'quartos',
    GUESTS: 'hospedes',
  },
  COMMON: {
    SHOW_MORE: 'Ver mais',
    SHOW_LESS: 'Ver menos',
  },
  BACKEND: {
    TITLE: '',
    DESCRIPTION: '',
  },
  AMENITIES: {
    HIDRO_MASSAGE: 'hidromassagem',
    ROOMS: 'quartos',
    BEDS: 'camas',
    BATHROOM: 'banheiros',
    LAVATORY: 'lavatório',
    GARAGE: 'garagem',
    SOFABED: 'sofá-cama',
    MAXOCCUPANCY: 'hóspedes',
    WIFI: 'wifi',
    ALARM: 'alarme',
    CAMERA: 'câmera',
    SAFE: 'cofre',
    SAFETY_FENCE_POOL: 'cerca de segurança na piscina',
    POOL_ACCESS_ALARM: 'alarme de acesso à piscina',
    POOL: 'piscina',
    PRIVATE_POOL: 'piscina privativa',
    SPA: 'spa',
    OUT_TUB: 'banheira externa',
    IND_TUB: 'banheira individual',
    SOLAR_HEAT: 'aquecimento solar',
    POOL_HEATABLE: 'piscina aquecida',
    FACING_POOL: 'de frente para a piscina',
    POOL_ACCESS: 'acesso à piscina',
    IND_JACUZZI: 'jacuzzi individual',
    BBQ: 'churrasqueira',
    GRELL: 'grell',
    HAIR_DRYER: 'secador de cabelo',
    DISHWASHER: 'máquina de lavar louça',
    MINI_KITCHEN: 'cozinha compacta',
    FULL_KITCHEN: 'cozinha completa',
    RESORT_AMEN: 'comodidades do resort',
    ELEVATOR: 'elevador',
    WHEL_CHAIR_ACC: 'acessível a cadeira de rodas',
    FREE_CALLS: 'chamadas gratuitas',
    PAV_PARK: 'estacionamento pavimentado',
    ROCK_CHAIRS: 'cadeiras de balanço',
    MICROWAVE: 'micro-ondas',
    LAUNDRY_IN: 'lavanderia interna',
    LAUNDRY_ON: 'lavanderia disponível',
    UNIT_AMEN: 'comodidades da unidade',
    DOCK: 'doca',
    MOTOR_CYDE: 'moto',
    WIRED_INTERNET: 'internet com fio',
    INTERNET_PC: 'computador com internet',
    STROLLER: 'carrinho de bebê',
    CRIB: 'berço',
    PACKNPLAY: 'packnplay',
    HIGH_CHAIR: 'cadeira alta',
    ELETRIC_FIREPLACE: 'lareira elétrica',
    WOOD_BURNING: 'lareira a lenha',
    UNDER_FLOOR_HEAT: 'aquecimento sob o piso',
    GAS_FIREPLACE: 'lareira a gás',
    AIRCONDITIONING: 'ar condicionado',
    GAMES_ROOM: 'sala de jogos',
    COMMUNAL_GYM: 'academia comunitária',
    AIR_HOCKEY: 'air hockey',
    FOOSBALL: 'pebolim',
    POOL_TABLE: 'mesa de sinuca',
    VIDEO_GAMES: 'jogos de vídeo',
    VCR: 'videocassete',
    TABLE_TENNIS: 'tênis de mesa',
    GOLF_INCL: 'golfe incluído',
    BIG_SCR_TV: 'TV de tela grande',
    TV_BEDROOMS: 'TV nos quartos',
    CDPLAYER: 'tocador de CD',
    DVD_BLUERAY: 'DVD/Blu-ray',
    FISHING: 'pescaria',
    CLUB_HOUSE: 'casa do clube',
    TENNIS_COURT: 'quadra de tênis',
    PETS: 'animais de estimação',
    GAS_FREE: 'gás grátis',
    PRIVACY_FENCE: 'cerca de privacidade',
    BEACH_ON: 'próximo à praia',
    WASHER: 'máquina de lavar',
    DRYER: 'secadora',
    WASHER_DRYER: 'lavadora e secadora',
    HEATING: 'aquecimento',
    PRIVATE_ENTRANCE: 'entrada privativa',
    BEDDING_ESSENTIALS: 'essenciais de cama',
    TOILETRIES: 'artigos de toalete',
  },
  HOME: {
    TEST: '',
    BE_A_PARTNER: {
      MESSAGE_BUSSINESS_EASIER: 'Deixe seu negocio mais fácil\nse tornando um parceiro WE',
      MESSAGE_BE_PART_OF_OUR_JOURNEY: 'Venha fazer parte da nossa jornada para democratizar\nas viagens globais.',
      MESSAGE_CLICK_NOW: 'Clique agora e seja um parceiro de sucesso!',
      WANT_TO_KNOW_MORE: 'Quero saber mais',
    },
    ENJOY_MORE: {
      WANT_TO_ENJOY_MORE: 'Quer aproveitar mais?',
      MESSAGE_SPECIAL_OFFERS: 'Receba ofertas exclusivas e dicas importantes para sua viagem se tornar inesquecível!',
      SUBSCRIBE: 'Inscreva-se',
    },
    FOOTER: {
      KNOW_MORE: 'Saber Mais',
      OUR_HISTORY: 'Nossa história',
      CAREERS: 'Carreiras',
      FREQUENTLY_QUESTIONS: 'Perguntas Frequentes',
      TERMS_AND_CONDITIONS: 'Termos de uso',
      PRIVACY_POLICIES: 'Política de Privacidade',
      WHO_WE_ARE: 'Quem somos',
      CONTACT: 'Contato',
      STREET: 'Rua',
      MESSAGE_COPYRIGHT: 'Copyright 2024 GoWeasy | Todos os direitos reservados.',
    },
    HEADER: {
      HOME: 'Home',
      ABOUT_US: 'Sobre nós',
      WANT_TO_BE_A_PARTNER: 'Quero ser parceiro',
      PORTUGUESE: 'Português',
      SPANISH: 'Espanhol',
      ENGLISH: 'Inglês',
      ENTER: 'Entrar',
      REGISTER: 'Cadastre-se',
      WHO_WE_ARE: 'Quem somos',
      SOLUTIONS: 'Soluções',
      PARTNERS: 'Parceiros',
      CONTACT: 'Contato',
      OUT: 'Sair',
    },
    WHY_CHOOSE_US: {
      UNBEATABLE_PRICES: 'Preços imbatíveis',
      MESSAGE_UNBEATABLE_PRICES:
        'Reserve aluguéis de temporada em orlando com segurança! Garanta o melhor valor e preço ao reservar diretamente conosco.',
      SAFE_HOSPITALITY: 'Hospitalidade segura',
      MESSAGE_SAFE_HOSPITALITY:
        'Especializados em transformar sonhos de férias em realidade, cada dia de sua estadia é uma jornada de memórias inestimáveis.',
      EXCELLENT_VARIETY_OF_OPTIONS: 'Excelente variedade de opções',
      MESSAGE_EXCELLENT_VARIETY_OF_OPTIONS:
        'Apresentamos uma vasta gama de aluguéis de temporada. Nosso estoque selecionado ajudará a encontrar a casa idea.',
      PRIVILEGED_LOCATION: 'Localização privilegiada',
      MESSAGE_PRIVILEGED_LOCATION:
        'Oferecemos residências nas melhores áreas de Orlando, próximas a todas as atrações que suas férias merecem.',
    },
    EXPERIENCES: {
      RENT_EXOTIC_CARS: 'Aluguel de carros exóticos',
      CHEF_IN_THE_HOUSE: 'Chef em casa',
      GOLF_PACKAGES: 'Pacotes de golfe',
      IOGA_LESSONS: 'Aulas de ioga',
    },
    HOME_VIDEO: {
      LOCATION: 'Localização',
      CHECK_IN: 'Check-in',
      CHECK_IN_DATE: 'Data de chegada',
      CHECKOUT: 'Checkout',
      CHECK_OUT_DATE: 'Data de saída',
      GUESTS: 'Hóspedes',
      HOW_MUCH_PEOPLE: 'Quantas pessoas',
      SEARCH: 'Buscar',
      MESSAGE_FIND_YOUR_PERFECT_RENT: 'Encontre a hospedagem perfeita em Orlando!',
      MESSAGE_HALVED_FIND_YOUR_PERFECT_RENT: 'Encontre a hospedagem\nperfeita em Orlando',
      ADULTS: 'Adultos',
      '13_YEARS_OR_PLUS': '13 anos ou mais',
      CHILDREN: 'Crianças',
      FROM_TWO_TO_TWELVE_YEARS: 'De 2 a 12 anos',
      BABIES: 'Bebês',
      ABOVE_TWO_YEARS: 'Menor de 2 anos',
      PETIES: 'Animais de estimação',
      PETIES_SMALL_PORT: 'Animais de pequeno porte',
      ENTER_THE_DESIRED_DATE: 'Informe a data desejada',
      CONFIRM: 'Confirmar',
    },
    TITLES: {
      TITLE_OUR_PROPERTIES: 'Nossos imóveis em destaque',
      TITLE_WHY_CHOOSE_US: 'Por que preferir a WE para suas casas de férias?',
      TITLE_THEMED_HOMES: 'Casas temáticas',
      TITLE_TRANSFORM_YOUR_STAY: 'Transforme a estadia de seus clientes em momentos inesquecíveis',
      TITLE_TRANSFORM_YOUR_STAY_DESCRIPTION: 'Com a GoWeasy criar momentos memoráveis e fácil',
    },
    USER: {
      TYPE_YOUR_EMAIL_ADDRESS: 'Digite seu endereço de e-mail',
      EMAIL: 'E-mail',
      EXCLUSIVE: 'Exclusivo',
    },
  },
  LIST_HOUSES: {
    PROPERTIES_FOUND: 'imóveis encontrados',
    PROPERTY_FOUND: 'imóvel encontrado',
    PROPERTIES_NOT_FOUND: 'Não foi possível encontrar os imóveis',
    SUITS: 'suites',
    BATHROOMS: 'banheiros',
    GUESTS: 'hospédes',
    ORDER: 'Ordenar',
    MORE_RELEVANT: 'Mais relevante',
    LOWER_PRICE: 'Menor preço',
    BIGGER_PRICE: 'Maior preço',
    FILTERS: 'Filtros',
    PRICE: 'Preço',
    UNTIL: 'Até',
    ROOMS: 'Quartos',
    VACANCIES: 'Vagas',
    AMENITIES: 'Comodidades',
    AIR_CONDITIONING: 'Ar condicionado',
    GYM: 'Academia',
    PETS: 'Animais de estimação',
    GRILL: 'Churrasqueira',
    CLUB: 'Clube',
    FULL_KITCHEN: 'Cozinha completa',
    BABY_CARRIAGE: 'Carrinho de bebê',
    GOLF: 'Golfe',
    WIFI: 'Wi-fi',
    POOL: 'Piscina',
    PRIVATE_POOL: 'Piscina privativa',
    TENNIS_COURT: 'Quadra de tênis',
    SPA: 'Spa',
    VIDEO_GAME: 'Video game',
    CLEAN_FILTER: 'Limpar Filtro',
    SEARCH: 'Buscar',
    DAILY: 'diária',
    MIN: 'Mín',
    MAX: 'Máx',
  },
  YUP: {
    OBJECT: {
      FIELD_HAVE_UNKNOWN_PROPERTIES: 'O campo tem propriedades desconhecidas: ',
    },
    MIXED: {
      ISNT_VALID: 'Não é válido',
      REQUIRED_FIELD: 'Campo obrigatório',
      INVALID_TYPE: 'Tipo inválido',
      HAS_TO_BE_ONE_OF_THE_VALUES: 'Tem que ser um dos valores',
      CANT_BE_ONE_OF_THE_VALUES: 'Não pode ser esse valor',
      VALUE_HAS_TO_BE_DEFINED: 'Valor deve ser definido',
    },
    NUMBER: {
      HAS_TO_BE_A_POSITIVE_NUMBER: 'Deve ser um número positivo',
      HAS_TO_BE_A_NEGATIVE_NUMBER: 'Deve ser um número negativo',
      HAS_TO_BE_A_INTEGER_NUMBER: 'Deve ser um número inteiro',
      MUST_BE_LESS_THAN: 'Deve ser menor que ',
      MUST_BE_BIGGER_THAN: 'Deve ser maior que ',
      HAS_TO_BE_LESS_THAN: 'Deve ser menos que ',
      HAS_TO_BE_MORE_THAN: 'Deve ser mais que ',
    },
    STRING: {
      MUST_BE_A_VALID_EMAIL: 'Deve ser um e-mail válido',
      MUST_BE_A_VALID_URL: 'Deve ser uma url válida',
      MUST_BE_A_UUID: 'Deve ser um código único',
      MUST_BE_A_TRIM: 'Deve ser uma parte do texto',
      MUST_BE_A_LOWERCASE_TEXT: 'Deve ser um texto em caixa baixa',
      MUST_BE_A_UPPERCASE_TEXT: 'Deve ser um texto em caixa alta',
      MUST_HAVE_EXACLY_CARACTER_PLURAL: 'Deve conter exatamente {{value}} caracteres',
      MUST_HAVE_EXACLY_CARACTER: 'Deve conter exatamente {{value}} caracter',
      MUST_HAVE_AT_LEAST_CARACTER_PLURAL: 'Deve conter no mínimo {{value}} caracteres',
      MUST_HAVE_AT_LEAST_CARACTER: 'Deve conter no mínimo {{value}} caracter',
      MUST_HAVE_AT_MAXIMUM_CARACTER_PLURAL: 'Deve conter no mínimo {{value}} caracteres',
      MUST_HAVE_AT_MAXIMUM_CARACTER: 'Deve conter no mínimo {{value}} caracter',
      MUST_MATCHES_TO_THIS_VALUE: 'Deve corresponder ao seguinte {{value}}',
    },
    DATE: {
      DATE_MUST_BE_LESS_THAN: 'A data deve ser menor que {{value}}',
      DATE_MUST_BE_MORE_THAN: 'A data deve ser maior que {{value}}',
    },
    BOOLEAN: {
      VALUE_HAS_TO_BE: 'O valor tem que ser {{value}}',
    },
    ARRAY: {
      MUST_CONTAIN_AT_LEAST_PLURAL: 'Deve conter pelo menos {{value}} itens',
      MUST_CONTAIN_AT_LEAST: 'Deve conter pelo menos {{value}} item',
      MUST_CONTAIN_AT_MAX_PLURAL: 'Deve conter no máximo {{value}} itens',
      MUST_CONTAIN_AT_MAX: 'Deve conter no máximo {{value}} item',
      MUST_CONTAIN_PLURAL: 'Deve conter {{value}} itens',
      MUST_CONTAIN: 'Deve conter {{value}} item',
    },
    CUSTOM: {
      CANT_BE_THE_SAME_AS_THIS_VALUE: 'Não pode ser o mesmo que {{VALUE}}',
      MUST_BE_LESS_OR_EQUAL_THAN: 'Deve ser menor ou igual a {{value}}',
      MUST_BE_MORE_OR_EQUAL_THAN: 'Deve ser maior ou igual a {{value}}',
      CANT_BE_ZERO: 'O valor não pode ser zero.',
    },
  },
  HOUSE_DETAILS: {
    CHECKOUT: {
      TOTAL_REMINDER: 'Total (Sem impostos)',
      CANCELLATION_UNTIL: 'Cancelamento grátis até um dia antes do Check-in',
      GUESTS: 'Convidados',
      CURRENCY: 'R$',
      ADULTS: 'Adultos',
      CHILDS: 'Crianças',
      PETS: 'Animais de estimação',
      BABIES: 'Bebês',
      ELDERLY: 'Idosos',
      PCD: 'PcD',
      ADULTS_HELP_TEXT: '17 anos ou mais',
      CHILDS_HELP_TEXT: 'De 2 a 17 anos',
      BABIES_HELP_TEXT: 'Menor de 2 anos',
      ELDERLY_HELP_TEXT: '60 anos ou mais',
      PCD_HELP_TEXT: 'pessoas com deficiência',
      PETS_HELP_TEXT: 'Animais de pequeno porte',
      BUTTON_RESERVE: 'Reservar',
      BUTTON_LOGIN: 'Acessar conta',
      SHOW_MORE: 'Mostrar todas as {{quantity}} comodidades',
      SHOW_LESS: 'Ocultar comodidades',
      ERROR_0_GUESTS: 'Selecione ao menos um convidado para fazer a reserva.',
    },
    ABOUT_HOUSE: 'Sobre esta propriedade',
    EXPLORE_REGION: 'Explore a região',
    RULES: {
      TITLE: 'Regras da propriedade',
      CHECK_IN: 'Check-in',
      MINIMUM_AGE_TO_RENT: 'Idade mínima para alugar',
      PETS_ALLOWED: 'Animais de estimação',
      EVENTS: 'Eventos',
      CHECK_OUT: 'Check-out',
      CHILDREN: 'Crianças',
      SMOKING_POLICY: 'Política para fumantes',
      DAMAGES: 'Danos e despesas adicionais',
      DESCRIPTION: {
        ABOUT_HOUSE: 'Sobre esta propriedade',
        CHECK_IN: 'Após',
        MINIMUM_AGE_TO_RENT: '21 anos',
        PETS_ALLOWED: 'Aceita animais de estimação',
        PETS_NOT_ALLOWED: 'Não aceita animais',
        EVENTS: 'Não permitido',
        CHECK_OUT: 'Antes',
        CHILDREN: 'Aceita hóspedes de 0 a 17 anos',
        SMOKING_POLICY: 'Política para fumantes',
        DAMAGES:
          'Você será responsável por qualquer dano à propriedade alugada causado por você ou outra pessoa durante a sua estadia.',
      },
    },
    NOTES: {
      TITLE: 'Informações importantes',
      SUBTITLE: 'O que você precisa saber',
      EXTRA_PEOPLE:
        'Pessoas extras podem incorrer em taxas adicionais que variam dependendo da política da propriedade.',
      OFFICIAL_ID:
        'Documento de identificação oficial com foto e cartão de crédito, cartão de débito ou depósito em dinheiro podem ser exigidos no momento do check-in para despesas extras.',
      SPECIAL_REQUESTS:
        'Solicitações especiais estão sujeitas à disponibilidade no momento do check-in e podem incorrer em taxas adicionais. Essas solicitações não estão garantidas.',
      NO_GUARANTEE:
        'Festas ou eventos de grupos no local são estritamente proibidos. Os recursos de segurança disponíveis na propriedade incluem extintor de incêndio e detector de fumaça.',
    },
  },
  CHECKOUT: {
    CONFIRM_BOOKING: 'Confirmar reserva',
    YOUR_TRAVEL: 'Sua viagem',
    FORM: {
      DATES: 'Datas',
      GUESTS: 'Hóspedes',
      TO_EDIT: 'Alterar',
      PAY_WITH: 'Detalhes do pagamento',
      YOUR_DATA: 'Seus dados',
      NAME: 'Nome',
      NAME_PLACEHOLDER: 'Digite seu nome',
      SURNAME: 'Sobrenome',
      SURNAME_PLACEHOLDER: 'Digite seu sobrenome',
      DOCUMENT: 'CPF / Passaport',
      DOCUMENT_PLACEHOLDER: 'Digite seu CPF / Passaport',
      PHONE_NUMBER: 'Telefone',
      PHONE_NUMBER_PLACEHOLDER: 'Digite seu telefone',
      ADDRESS: 'Endereço',
      ADDRESS_PLACEHOLDER: 'Digite seu endereço',
      POSTAL_CODE: 'CEP',
      POSTAL_CODE_PLACEHOLDER: 'Digite seu CEP',
      COUNTRY: 'País',
      COUNTRY_PLACEHOLDER: 'Selecione seu país',
      CITY: 'Cidade',
      CITY_PLACEHOLDER: 'Digite o nome da sua cidade',
      STATE: 'Estado',
      STATE_PLACEHOLDER: 'Digite o nome do seu estado',
      AGENT_DATA: 'Dados do agente',
      AGENTS_CODE: 'Código do agente',
      AGENTS_CODE_PLACEHOLDER: 'Digite o código do agente',
      AGENTS_NAME: 'Nome do agente',
      AGENTS_NAME_PLACEHOLDER: 'Digite o nome do agente',
      AGENCIA: 'Agência',
      AGENCIA_PLACEHOLDER: 'Digite o nome da gência',
      INPORTANT_INFORMATION: 'Informações importantes',
      INSTALLMENT: 'parcela',
      OF: ' de R$ ',
      INTEREST_FREE: 'DESCONTO PIX',
      INSTALLMENT_PLACEHOLDER: 'Selecione a quantidade de parcelas',
    },
    INFORMATIONS: {
      NEED_KNOW: 'O que você precisa saber ?',
      NEED_KNOW_DESCRIPTION: [
        'Pessoas extras podem incorrer em taxas adicionais que variam dependendo da política da propriedade.',
        'Documento de identificação oficial com foto e cartão de crédito, cartão de débito ou depósito em dinheiro podem ser exigidos no momento do check-in para despesas extras. ',
        'Solicitações especiais estão sujeitas à disponibilidade no momento do check-in e podem incorrer em taxas adicionais.',
        'Essas solicitações não estão garantidas. Festas ou eventos de grupos no local são estritamente proibidos. Os recursos de segurança disponíveis na propriedade incluem extintor de incêndio e detector de fumaça.',
      ],
      PAYMENT_TERMS: [
        'Ao clicar no botão abaixo, concordo com as seguintes políticas: Regras da Casa estabelecidas pelo proprietário, Regras básicas para os hóspedes, Política de Reembolso. Além disso, concordo que a Plataforma pode enviar a cobrança para minha forma de pagamento se eu for responsável por algum dano. Concordo em pagar o valor total indicado se o proprietário aceitar meu pedido de reserva. Termos de Pagamento entre você e Plataforma Plataforma Digital Ltda.',
        'Também concordo com os Termos de Serviço atualizados, os Termos de Serviço de Pagamentos e confirmo que li a Política de Privacidade.',
      ],
    },
    HOUSE_DETAILS: {
      PRICE_INFO: 'Informações de preço',
      SERVICES_FEE: 'Taxa de limpeza',
      NIGHTS: 'noites',
    },
    ACTIONS: {
      SUBMIT: 'Confirmar reserva',
      BOOKING_MADE: 'Reserva confirmada com sucesso!',
      FAILURE_PAYMENT: 'Falha no pagamento',
      EXTERNAL_LINK: 'Gerar link de pagamento',
    },
  },
  RESERVATION_CONFIRMED: {
    TITLE: 'Reserva confirmada',
    DEAR: 'Prezado(a),',
    DESCRIPTIONS: [
      'Agradecemos por escolher',
      'para sua próxima estadia! Estamos empolgados em recebê-lo(a) e queremos garantir que sua experiência seja excepcional.Aqui estão os detalhes da sua reserva:',
    ],
    RESERVATION_ID: 'Id da reserva:',
    CHECKIN_DATE: 'Check-in:',
    CHECKOUT_DATE: 'Check-out:',
    TOTAL_COST: 'Valor total:',
  },
};
