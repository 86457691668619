export default {
  CONFIRMED_CHECKOUT: {
    RESERVATION_CONFIRMED: 'Reserva confirmada',
    CONGRATULATIONS: '¡Felicidades! Su reserva ha sido confirmada con éxito.',
    UNFORGETTABLE_STAY: '¡Estamos ansiosos por hacer su estadía inolvidable!',
    RESERVATION_IDENTIFIER: 'Identificador de reserva: ',
    CHECKIN_DATE: 'Fecha de llegada: ',
    CHECKOUT_DATE: 'Fecha de salida: ',
    BACK_TO_HOME: 'Volver a la pantalla principal',
    ADDRESS: 'Dirección: ',
  },
  SUCCESS_CHECKOUT: {
    TITLE: 'Reserva confirmada con éxito',
    MESSAGE: 'Para ver todos los detalles, haga clic en el botón de abajo',
    BUTTON: 'Ver detalles',
  },
  FAIL_CHECKOUT: {
    TITLE: 'Error en el pago',
    MESSAGE_1: 'No pudimos procesar su pago',
    MESSAGE_2: 'Por favor, póngase en contacto con su agente de viajes',
    BUTTON: 'Volver a la pantalla principal',
  },
  CARD: {
    DAILYS: 'Tarifas diarias',
    TAXES: 'Impuestos',
    SERVICE_FEE: 'Tarifa de limpieza',
    PLATFORM_FEE: 'Tarifa de servicio',
    LONG_STAY_DISCOUNT: 'Descuento por estancia prolongada',
    PARTNER_DISCOUNT: 'Descuento para Socios',
  },
  RESPONSE: {
    PRICE_RESERVE_ERROR: `La propiedad no está disponible para el período seleccionado. 
    Tiente seleccionar otro período.`,
    PRICE_RESERVE_ERROR_GENERIC: 'Error al buscar precio de reserva. Inténtelo de nuevo más tarde.',
    RESERVE_ERROR: 'Error al realizar la reserva. Inténtelo de nuevo más tarde.',
    PRICE_RESERVE_ERROR_CONFLICT: 'Error al buscar precio, ya existe una reserva para este período.',
  },
};
