import { Menu, FocusableItem } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import { useEffect, useMemo, useState } from 'react';
import {
  Box,
  BoxCalculate,
  BoxInfo,
  DropDown,
  Hr,
  Total,
  Input,
  MenuBtn,
  TotalGuests,
  Title,
  SubTitle,
  IconAction,
} from './style';
import { BsFillCaretDownFill, BsFillCaretUpFill } from 'react-icons/bs';
import { HiPlus, HiMinus } from 'react-icons/hi';
import { useTranslation } from 'react-i18next';
import { Label } from 'components/atoms/Label';
import useFilters from 'hooks/useFilters';
import { IGuests } from 'models/application/interfaces/Guests.interface';

type WESelectGuestsProps = {
  limitGuests: number;
  petsAllowed: boolean;
  onChange: (guests: IGuests) => void;
  colorLabel?: string;
};
const WESelectGuests: React.FC<WESelectGuestsProps> = ({ petsAllowed, limitGuests, onChange, colorLabel }) => {
  const { t } = useTranslation();
  const [guests, setGuests] = useState<any>({
    adults: Number(new URLSearchParams(location.search).get('adults')) || 0,
    kids: Number(new URLSearchParams(location.search).get('kids')) || 0,
    babies: Number(new URLSearchParams(location.search).get('babies')) || 0,
    pets: Number(new URLSearchParams(location.search).get('pets')) || 0,
    elderly: Number(new URLSearchParams(location.search).get('elderly')) || 0,
    pcd: Number(new URLSearchParams(location.search).get('pcd')) || 0,
    total: Number(new URLSearchParams(location.search).get('total')) || 0,
  });
  const [isOpen, setIsOpen] = useState(false);
  const { getFilterParameters } = useFilters();
  const { pets, adults, babies, kids, pcd, elderly } = getFilterParameters();

  const DEFAULT_LIST = [
    {
      title: t('HOUSE_DETAILS.CHECKOUT.ADULTS'),
      subTitle: t('HOUSE_DETAILS.CHECKOUT.ADULTS_HELP_TEXT'),
      key: 'adults',
    },
    {
      title: t('HOUSE_DETAILS.CHECKOUT.CHILDS'),
      subTitle: t('HOUSE_DETAILS.CHECKOUT.CHILDS_HELP_TEXT'),
      key: 'kids',
    },
    {
      title: t('HOUSE_DETAILS.CHECKOUT.BABIES'),
      subTitle: t('HOUSE_DETAILS.CHECKOUT.BABIES_HELP_TEXT'),
      key: 'babies',
    },
    {
      title: t('HOUSE_DETAILS.CHECKOUT.ELDERLY'),
      subTitle: t('HOUSE_DETAILS.CHECKOUT.ELDERLY_HELP_TEXT'),
      key: 'elderly',
    },
    // {
    //   title: t('HOUSE_DETAILS.CHECKOUT.PCD'),
    //   subTitle: t('HOUSE_DETAILS.CHECKOUT.PCD_HELP_TEXT'),
    //   key: 'pcd',
    // },
  ];

  useEffect(() => {
    const total = Number(adults) + Number(kids) + Number(pcd) + Number(elderly);

    if (total <= limitGuests) {
      const newValue = {
        adults: Number(adults),
        kids: Number(kids),
        babies: Number(babies),
        pets: Number(pets),
        pcd: Number(pcd),
        elderly: Number(elderly),
        total,
      } as IGuests;
      setGuests(newValue);
      onChange(newValue);
    }
  }, [adults, kids, babies, pets, pcd, elderly]);

  const guestsType = useMemo(() => {
    if (petsAllowed) {
      return [
        ...DEFAULT_LIST,
        {
          title: t('HOUSE_DETAILS.CHECKOUT.PETS'),
          subTitle: t('HOUSE_DETAILS.CHECKOUT.PETS_HELP_TEXT'),
          key: 'pets',
        },
      ];
    }

    return DEFAULT_LIST;
  }, [DEFAULT_LIST]);

  const handleOpen = (value: any) => {
    setIsOpen(value.open);
  };

  const addItem = (key: string) => {
    const newValue = {
      ...guests,
      [key]: guests[key] + 1,
    };

    if (key === 'pets' || key === 'babies') {
      setGuests(newValue);
      return onChange(newValue);
    }

    const newTotal = guests.total + 1;

    if (newTotal <= limitGuests) {
      const newValueWithTotal = {
        ...newValue,
        total: newTotal,
      };
      setGuests(newValueWithTotal);
      onChange(newValueWithTotal);
    }
  };

  const removeItem = (key: string) => {
    if (guests[key] - 1 < 0) return;
    const newValue = {
      ...guests,
      [key]: guests[key] - 1,
    };

    if (key === 'pets' || key === 'babies') {
      setGuests(newValue);
      return onChange(newValue);
    }

    const newGuestValue = guests[key] - 1;

    if (newGuestValue >= 0) {
      const newValueWithTotal = {
        ...newValue,
        total: guests.total - 1,
      };
      setGuests(newValueWithTotal);
      onChange(newValueWithTotal);
    }
  };

  return (
    <Menu
      menuButton={
        <MenuBtn>
          <Label color={colorLabel}>{t('HOUSE_DETAILS.CHECKOUT.GUESTS')}</Label>
          <Input>
            <TotalGuests color={colorLabel}>
              {guests.total} {t('HOUSE_DETAILS.CHECKOUT.GUESTS')}
            </TotalGuests>
            {isOpen ? (
              <BsFillCaretUpFill color={colorLabel} size={14} />
            ) : (
              <BsFillCaretDownFill color={colorLabel} size={14} />
            )}
          </Input>
        </MenuBtn>
      }
      position="initial"
      overflow="visible"
      direction="bottom"
      align="end"
      onMenuChange={handleOpen}
    >
      <FocusableItem>
        {() => (
          <DropDown>
            {guestsType.map((guestType, index) => (
              <div key={index}>
                {index !== 0 ? <Hr /> : null}
                <Box>
                  <BoxInfo>
                    <Title>{guestType.title}</Title>
                    <SubTitle>{guestType.subTitle}</SubTitle>
                  </BoxInfo>
                  <BoxCalculate>
                    <IconAction onClick={() => removeItem(guestType.key)}>
                      <HiMinus color="#0D0D0D" size={24} />
                    </IconAction>
                    <Total>{guests[guestType.key]}</Total>
                    <IconAction onClick={() => addItem(guestType.key)}>
                      <HiPlus color="#0D0D0D" size={24} />
                    </IconAction>
                  </BoxCalculate>
                </Box>
              </div>
            ))}
          </DropDown>
        )}
      </FocusableItem>
    </Menu>
  );
};

export default WESelectGuests;
