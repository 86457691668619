class EmbraerRegisterModel {
  name: string;
  email: string;
  cel: string;
  cpf: string;
  cep: string;
  address: string;
  city: string;
  country: string;
  number: number;
  state: string;
  associationId: string;
  password: string;
  confirmPassword: string;

  constructor(data: EmbraerRegisterModel) {
    this.name = data?.name;
    this.email = data?.email;
    this.cel = data?.cel;
    this.cpf = data?.cpf;
    this.cep = data?.cep;
    this.address = data?.address;
    this.city = data?.city;
    this.country = data?.country;
    this.number = data?.number;
    this.state = data?.state;
    this.password = data?.password;
    this.associationId = data?.associationId;
    this.confirmPassword = data?.confirmPassword;
  }
}
export default EmbraerRegisterModel;
