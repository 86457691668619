import houseDetails from './houseDetails';
import faq from './faq';
import home from './home';
import publicTranslations from './public';
import search from './search';
import checkOut from './checkout';
import condominiums from './condominiums';
import whoWeAre from './whoWeAre';
import modals from './modals';
import backoffice from './backoffice';
import administrative from './administrative';
import reservation from './reservation';

export default {
  ...checkOut,
  ...houseDetails,
  ...faq,
  ...home,
  ...publicTranslations,
  ...search,
  ...condominiums,
  ...whoWeAre,
  ...modals,
  ...backoffice,
  ...administrative,
  ...reservation,
  MENU: {
    MANAGEMENT: 'Gerenciamento',
    CHANGE_REGISTER: 'Alterar cadastro',
    ADMINISTRATIVE: 'Administrativo',
    LOGOUT: 'Sair',
    MY_RESERVATIONS: 'Mis Reservaciones',
  },
  TERMS_OF_SERVICE: {
    TITLE: 'Términos de servicio',
  },
  CANCELLATION_POLICY: {
    TITLE: 'Política de cancelación',
  },
  PRIVACY_POLICY: {
    TITLE: 'Política de privacidad',
  },
  TOM_ASSISTANT: {
    FIRST_MESSAGE: '¡Hola! ¿Cómo estás? Soy Tom, la inteligencia artificial de GoWeasy.',
    SECOND_MESSAGE: '¿En qué puedo ayudarte hoy?',
    QUESTIONS: {
      QUESTION_1: '¿Es tu primera vez en Orlando?',
      QUESTION_2: 'Elige una casa para tus vacaciones',
      QUESTION_3: 'Descubre los mejores condominios para tus vacaciones',
      QUESTION_4: '¿Qué hacer en Orlando además de visitar los parques temáticos?',
    },
  },
  HOUSE_CARD: {
    PER_NIGHT: 'por noche',
    ROOMS: 'habitaciones',
    GUESTS: 'huéspedes',
  },
  COMMON: {
    SHOW_MORE: 'Ver más',
    SHOW_LESS: 'Ver menos',
  },
  BACKEND: {
    TITLE: '',
    DESCRIPTION: '',
  },
  AMENITIES: {
    HIDRO_MASSAGE: 'hidromasaje',
    ROOMS: 'habitaciones',
    BEDS: 'camas',
    BATHROOM: 'baños',
    LAVATORY: 'lavatorio',
    GARAGE: 'garaje',
    SOFABED: 'sofá cama',
    MAXOCCUPANCY: 'ocupación máxima',
    WIFI: 'wifi',
    ALARM: 'alarma',
    CAMERA: 'cámara',
    SAFE: 'caja fuerte',
    SAFETY_FENCE_POOL: 'valla de seguridad en la piscina',
    POOL_ACCESS_ALARM: 'alarma de acceso a la piscina',
    POOL: 'piscina',
    PRIVATE_POOL: 'piscina privada',
    SPA: 'spa',
    OUT_TUB: 'bañera al aire libre',
    IND_TUB: 'bañera individual',
    SOLAR_HEAT: 'calefacción solar',
    POOL_HEATABLE: 'piscina climatizada',
    FACING_POOL: 'frente a la piscina',
    POOL_ACCESS: 'acceso a la piscina',
    IND_JACUZZI: 'jacuzzi individual',
    BBQ: 'barbacoa',
    GRELL: 'parrilla',
    HAIR_DRYER: 'secador de pelo',
    DISHWASHER: 'lavavajillas',
    MINI_KITCHEN: 'cocina compacta',
    FULL_KITCHEN: 'cocina completa',
    RESORT_AMEN: 'comodidades del resort',
    ELEVATOR: 'ascensor',
    WHEL_CHAIR_ACC: 'accesible en silla de ruedas',
    FREE_CALLS: 'llamadas gratuitas',
    PAV_PARK: 'estacionamiento pavimentado',
    ROCK_CHAIRS: 'sillas de roca',
    MICROWAVE: 'microondas',
    LAUNDRY_IN: 'lavandería interna',
    LAUNDRY_ON: 'lavandería disponible',
    UNIT_AMEN: 'comodidades de la unidad',
    DOCK: 'muelle',
    MOTOR_CYDE: 'moto',
    WIRED_INTERNET: 'internet por cable',
    INTERNET_PC: 'computadora con internet',
    STROLLER: 'cochecito',
    CRIB: 'cuna',
    PACKNPLAY: 'packnplay',
    HIGH_CHAIR: 'silla alta',
    ELETRIC_FIREPLACE: 'chimenea eléctrica',
    WOOD_BURNING: 'chimenea de leña',
    UNDER_FLOOR_HEAT: 'calefacción por suelo radiante',
    GAS_FIREPLACE: 'chimenea de gas',
    AIRCONDITIONING: 'aire acondicionado',
    GAMES_ROOM: 'sala de juegos',
    COMMUNAL_GYM: 'gimnasio comunitario',
    AIR_HOCKEY: 'air hockey',
    FOOSBALL: 'futbolín',
    POOL_TABLE: 'mesa de billar',
    VIDEO_GAMES: 'videojuegos',
    VCR: 'videocasetera',
    TABLE_TENNIS: 'ping pong',
    GOLF_INCL: 'golf incluido',
    BIG_SCR_TV: 'TV de pantalla grande',
    TV_BEDROOMS: 'TV en las habitaciones',
    CDPLAYER: 'reproductor de CD',
    DVD_BLUERAY: 'DVD/Blu-ray',
    FISHING: 'pesca',
    CLUB_HOUSE: 'casa club',
    TENNIS_COURT: 'pista de tenis',
    PETS: 'mascotas',
    GAS_FREE: 'gas gratuito',
    PRIVACY_FENCE: 'valla de privacidad',
    BEACH_ON: 'cerca de la playa',
    WASHER: 'lavadora',
    DRYER: 'secadora',
    WASHER_DRYER: 'lavadora y secadora',
    HEATING: 'calefacción',
    PRIVATE_ENTRANCE: 'entrada privada',
    BEDDING_ESSENTIALS: 'ropa de cama esencial',
    TOILETRIES: 'artículos de tocador',
  },
  HOME: {
    BE_A_PARTNER: {
      MESSAGE_BUSSINESS_EASIER: 'Haz tu negocio más fácil\nconvirtiéndote en un socio de WE',
      MESSAGE_BE_PART_OF_OUR_JOURNEY: 'Únete a nuestro viaje para democratizar\nlos viajes globales.',
      MESSAGE_CLICK_NOW: '¡Haz clic ahora y sé un socio exitoso!',
      WANT_TO_KNOW_MORE: 'Quiero saber más',
    },
    ENJOY_MORE: {
      WANT_TO_ENJOY_MORE: '¿Quieres disfrutar más?',
      MESSAGE_SPECIAL_OFFERS: '¡Recibe ofertas exclusivas y consejos importantes para que tu viaje sea inolvidable!',
      SUBSCRIBE: 'Suscríbete',
    },
    FOOTER: {
      KNOW_MORE: 'Saber Más',
      OUR_HISTORY: 'Nuestra Historia',
      CAREERS: 'Carreras',
      FREQUENTLY_QUESTIONS: 'Preguntas Frecuentes',
      TERMS_AND_CONDITIONS: 'Terminos de uso',
      PRIVACY_POLICIES: 'Política de Privacidad',
      WHO_WE_ARE: 'Quiénes Somos',
      CONTACT: 'Contacto',
      STREET: 'Calle',
      MESSAGE_COPYRIGHT: 'Derechos de Autor 2024 GoWeasy | Todos los derechos reservados.',
    },
    HEADER: {
      HOME: 'Inicio',
      ABOUT_US: 'Sobre Nosotros',
      WANT_TO_BE_A_PARTNER: 'Quiero ser Socio',
      PORTUGUESE: 'Portugués',
      SPANISH: 'Español',
      ENGLISH: 'Inglés',
      ENTER: 'Entrar',
      REGISTER: 'Registrarse',
      WHO_WE_ARE: 'Quiénes Somos',
      SOLUTIONS: 'Soluciones',
      PARTNERS: 'Socios',
      CONTACT: 'Contacto',
      OUT: 'Salir',
    },
    WHY_CHOOSE_US: {
      UNBEATABLE_PRICES: 'Precios Inmejorables',
      MESSAGE_UNBEATABLE_PRICES:
        '¡Reserva alquileres de temporada en Orlando con seguridad! Garantiza el mejor valor y precio al reservar directamente con nosotros.',
      SAFE_HOSPITALITY: 'Hospitalidad Segura',
      MESSAGE_SAFE_HOSPITALITY:
        'Especializados en convertir sueños de vacaciones en realidad, ¡cada día de tu estadía es un viaje de memorias invaluables!',
      EXCELLENT_VARIETY_OF_OPTIONS: 'Excelente Variedad de Opciones',
      MESSAGE_EXCELLENT_VARIETY_OF_OPTIONS:
        'Presentamos una amplia gama de alquileres de temporada. Nuestro inventario seleccionado te ayudará a encontrar la casa ideal.',
      PRIVILEGED_LOCATION: 'Ubicación Privilegiada',
      MESSAGE_PRIVILEGED_LOCATION:
        'Ofrecemos residencias en las mejores áreas de Orlando, cerca de todas las atracciones que tus vacaciones merecen.',
    },
    EXPERIENCES: {
      RENT_EXOTIC_CARS: 'Alquiler de Autos Exóticos',
      CHEF_IN_THE_HOUSE: 'Chef en Casa',
      GOLF_PACKAGES: 'Paquetes de Golf',
      YOGA_LESSONS: 'Clases de Yoga',
    },
    HOME_VIDEO: {
      LOCATION: 'Ubicación',
      CHECK_IN: 'Check-in',
      CHECK_IN_DATE: 'Fecha de Llegada',
      CHECKOUT: 'Salida',
      CHECK_OUT_DATE: 'Fecha de Salida',
      GUESTS: 'Huéspedes',
      HOW_MUCH_PEOPLE: '¿Cuántas personas?',
      SEARCH: 'Buscar',
      MESSAGE_FIND_YOUR_PERFECT_RENT: '¡Encuentra el alojamiento perfecto en Orlando!',
      MESSAGE_HALVED_FIND_YOUR_PERFECT_RENT: 'Encuentra el alojamiento\nperfecto en Orlando',
      ADULTS: 'Adultos',
      '13_YEARS_OR_PLUS': '13 años o más',
      CHILDREN: 'Niños',
      FROM_TWO_TO_TWELVE_YEARS: 'De 2 a 12 años',
      BABIES: 'Bebés',
      ABOVE_TWO_YEARS: 'Menor de 2 años',
      PETS: 'Mascotas',
      PETIES_SMALL_PORT: 'Mascotas pequeñas',
      ENTER_THE_DESIRED_DATE: 'Ingresa la fecha deseada',
      CONFIRM: 'Confirmar',
    },
    TITLES: {
      TITLE_OUR_PROPERTIES: 'Nuestras Propiedades Destacadas',
      TITLE_WHY_CHOOSE_US: '¿Por qué preferir WE para tus casas de vacaciones?',
      TITLE_THEMED_HOMES: 'Casas Temáticas',
      TITLE_TRANSFORM_YOUR_STAY: 'Transforma la estancia de tus clientes en momentos inolvidables',
      TITLE_TRANSFORM_YOUR_STAY_DESCRIPTION: 'Con GoWeasy, crea momentos memorables fácilmente',
    },
    USER: {
      TYPE_YOUR_EMAIL_ADDRESS: 'Ingresa tu dirección de correo electrónico',
      EMAIL: 'Correo Electrónico',
      EXCLUSIVE: 'Exclusivo',
    },
  },
  LIST_HOUSES: {
    PROPERTIES_FOUND: 'propiedades encontradas',
    PROPERTY_FOUND: 'propiedad encontrada',
    PROPERTIES_NOT_FOUND: 'No se pudieron encontrar propiedades',
    SUITS: 'suites',
    BATHROOMS: 'baños',
    GUESTS: 'huéspedes',
    ORDER: 'Ordenar',
    MORE_RELEVANT: 'Más relevante',
    LOWER_PRICE: 'Precio más bajo',
    BIGGER_PRICE: 'Precio más alto',
    FILTERS: 'Filtros',
    PRICE: 'Precio',
    UNTIL: 'Hasta',
    ROOMS: 'Habitaciones',
    VACANCIES: 'Vacantes',
    AMENITIES: 'Servicios',
    AIR_CONDITIONING: 'Aire Acondicionado',
    GYM: 'Gimnasio',
    PETS: 'Mascotas',
    GRILL: 'Parrilla',
    CLUB: 'Club',
    FULL_KITCHEN: 'Cocina Completa',
    BABY_CARRIAGE: 'Cochecito de Bebé',
    GOLF: 'Golf',
    WIFI: 'Wi-Fi',
    POOL: 'Piscina',
    PRIVATE_POOL: 'Piscina Privada',
    TENNIS_COURT: 'Cancha de Tenis',
    SPA: 'Spa',
    VIDEO_GAME: 'Videojuego',
    CLEAN_FILTER: 'Limpiar Filtro',
    SEARCH: 'Buscar',
    DAILY: 'diaria',
    MIN: 'Mín',
    MAX: 'Máx',
  },
  YUP: {
    OBJECT: {
      FIELD_HAVE_UNKNOWN_PROPERTIES: 'El campo tiene propiedades desconocidas: ',
    },
    MIXED: {
      ISNT_VALID: 'No es válido',
      REQUIRED_FIELD: 'Campo requerido',
      INVALID_TYPE: 'Tipo inválido',
      HAS_TO_BE_ONE_OF_THE_VALUES: 'Debe ser uno de los valores',
      CANT_BE_ONE_OF_THE_VALUES: 'No puede ser uno de los valores',
      VALUE_HAS_TO_BE_DEFINED: 'El valor debe estar definido',
    },
    NUMBER: {
      HAS_TO_BE_A_POSITIVE_NUMBER: 'Debe ser un número positivo',
      HAS_TO_BE_A_NEGATIVE_NUMBER: 'Debe ser un número negativo',
      HAS_TO_BE_A_INTEGER_NUMBER: 'Debe ser un número entero',
      MUST_BE_LESS_THAN: 'Debe ser menor que ',
      MUST_BE_BIGGER_THAN: 'Debe ser mayor que ',
      HAS_TO_BE_LESS_THAN: 'Debe ser menos que ',
      HAS_TO_BE_MORE_THAN: 'Debe ser más que ',
    },
    STRING: {
      MUST_BE_A_VALID_EMAIL: 'Debe ser un correo electrónico válido',
      MUST_BE_A_VALID_URL: 'Debe ser una URL válida',
      MUST_BE_A_UUID: 'Debe ser un UUID',
      MUST_BE_A_TRIM: 'Debe estar recortado',
      MUST_BE_A_LOWERCASE_TEXT: 'Debe ser un texto en minúsculas',
      MUST_BE_A_UPPERCASE_TEXT: 'Debe ser un texto en mayúsculas',
      MUST_HAVE_EXACLY_CARACTER_PLURAL: 'Debe tener exactamente {{value}} caracteres',
      MUST_HAVE_EXACLY_CARACTER: 'Debe tener exactamente {{value}} caracter',
      MUST_HAVE_AT_LEAST_CARACTER_PLURAL: 'Debe tener al menos {{value}} caracteres',
      MUST_HAVE_AT_LEAST_CARACTER: 'Debe tener al menos {{value}} caracter',
      MUST_HAVE_AT_MAXIMUM_CARACTER_PLURAL: 'Debe tener como máximo {{value}} caracteres',
      MUST_HAVE_AT_MAXIMUM_CARACTER: 'Debe tener como máximo {{value}} caracter',
      MUST_MATCHES_TO_THIS_VALUE: 'Debe coincidir con el siguiente {{value}}',
    },
    DATE: {
      DATE_MUST_BE_LESS_THAN: 'La fecha debe ser anterior a {{value}}',
      DATE_MUST_BE_MORE_THAN: 'La fecha debe ser posterior a {{value}}',
    },
    BOOLEAN: {
      VALUE_HAS_TO_BE: 'El valor debe ser {{value}}',
    },
    ARRAY: {
      MUST_CONTAIN_AT_LEAST_PLURAL: 'Debe contener al menos {{value}} elementos',
      MUST_CONTAIN_AT_LEAST: 'Debe contener al menos {{value}} elemento',
      MUST_CONTAIN_AT_MAX_PLURAL: 'Debe contener como máximo {{value}} elementos',
      MUST_CONTAIN_AT_MAX: 'Debe contener como máximo {{value}} elemento',
      MUST_CONTAIN_PLURAL: 'Debe contener {{value}} elementos',
      MUST_CONTAIN: 'Debe contener {{value}} elemento',
    },
    CUSTOM: {
      CANT_BE_THE_SAME_AS_THIS_VALUE: 'No puede ser igual a {{VALUE}}',
      MUST_BE_LESS_OR_EQUAL_THAN: 'Debe ser menor o igual que {{value}}',
      MUST_BE_MORE_OR_EQUAL_THAN: 'Debe ser mayor o igual que {{value}}',
      CANT_BE_ZERO: 'El valor no puede ser cero.',
    },
  },
  HOUSE_DETAILS: {
    CHECKOUT: {
      TOTAL_REMINDER: 'Total (Sin impuestos)',
      CANCELLATION_UNTIL: 'Cancelación gratuita hasta un día antes del check-in',
      GUESTS: 'Huéspedes',
      CURRENCY: '$',
      ADULTS: 'Adultos',
      CHILDS: 'Niños',
      PETS: 'Mascotas',
      BABIES: 'Bebés',
      ELDERLY: 'Personas mayores',
      PCD: 'Discapacitados',
      ADULTS_HELP_TEXT: '17 años o más',
      CHILDS_HELP_TEXT: 'De 2 a 17 años',
      BABIES_HELP_TEXT: 'Menores de 2 años',
      ELDERLY_HELP_TEXT: '60 años o más',
      PCD_HELP_TEXT: 'Personas con discapacidad',
      PETS_HELP_TEXT: 'Mascotas pequeñas',
      BUTTON_RESERVE: 'Reservar',
      BUTTON_LOGIN: 'Iniciar sesión',
      SHOW_MORE: 'Mostrar todas las {{quantity}} comodidades',
      SHOW_LESS: 'Ocultar comodidades',
      ERROR_0_GUESTS: 'Por favor, seleccione al menos un invitado para hacer la reserva.',
    },
    ABOUT_HOUSE: 'Sobre esta propiedad',
    EXPLORE_REGION: 'Explorar la región',
    RULES: {
      TITLE: 'Normas de la propiedad',
      CHECK_IN: 'Check-in',
      MINIMUM_AGE_TO_RENT: 'Edad mínima para alquilar',
      PETS_ALLOWED: 'Se admiten mascotas',
      EVENTS: 'Eventos',
      CHECK_OUT: 'Check-out',
      CHILDREN: 'Niños',
      SMOKING_POLICY: 'Política de fumadores',
      DAMAGES: 'Daños y gastos adicionales',
      DESCRIPTION: {
        ABOUT_HOUSE: 'Sobre esta propiedad',
        CHECK_IN: 'Después',
        MINIMUM_AGE_TO_RENT: '21 años',
        PETS_ALLOWED: 'Permitido',
        PETS_NOT_ALLOWED: 'No permitido',
        EVENTS: 'No permitidos',
        CHECK_OUT: 'Antes',
        CHILDREN: 'Acepta huéspedes de 0 a 17 años',
        SMOKING_POLICY: 'Permitido',
        DAMAGES:
          'Usted será responsable de cualquier daño a la propiedad alquilada causado por usted u otra persona durante su estadía.',
      },
    },
    NOTES: {
      TITLE: 'Información importante',
      SUBTITLE: 'Lo que necesitas saber',
      EXTRA_PEOPLE:
        'Personas adicionales pueden incurrir en tarifas adicionales que varían dependiendo de la política de la propiedad.',
      OFFICIAL_ID:
        'Se puede requerir una identificación oficial con foto y una tarjeta de crédito, tarjeta de débito o depósito en efectivo en el momento del check-in para gastos adicionales.',
      SPECIAL_REQUESTS:
        'Las solicitudes especiales están sujetas a disponibilidad en el momento del check-in y pueden incurrir en tarifas adicionales. Estas solicitudes no están garantizadas.',
      NO_GUARANTEE:
        'Fiestas o eventos grupales en el lugar están estrictamente prohibidos. Los recursos de seguridad disponibles en la propiedad incluyen extintor de incendios y detector de humo.',
    },
  },
  CHECKOUT: {
    CONFIRM_BOOKING: 'Confirmar reserva',
    YOUR_TRAVEL: 'Tu viaje',
    FORM: {
      DATES: 'Fechas',
      GUESTS: 'Huéspedes',
      TO_EDIT: 'Editar',
      PAY_WITH: 'Detalles del pago',
      YOUR_DATA: 'Tus datos',
      NAME: 'Nombre',
      NAME_PLACEHOLDER: 'Ingrese su nombre',
      SURNAME: 'Apellido',
      SURNAME_PLACEHOLDER: 'Ingrese su apellido',
      DOCUMENT: 'CPF / Pasaporte',
      DOCUMENT_PLACEHOLDER: 'Ingrese su CPF / Pasaporte',
      PHONE_NUMBER: 'Teléfono',
      PHONE_NUMBER_PLACEHOLDER: 'Ingrese su teléfono',
      ADDRESS: 'Dirección',
      ADDRESS_PLACEHOLDER: 'Ingrese su dirección',
      POSTAL_CODE: 'Código postal',
      POSTAL_CODE_PLACEHOLDER: 'Ingrese su código postal',
      COUNTRY: 'País',
      COUNTRY_PLACEHOLDER: 'Seleccione su país',
      CITY: 'Ciudad',
      CITY_PLACEHOLDER: 'Ingrese el nombre de su ciudad',
      STATE: 'Estado',
      STATE_PLACEHOLDER: 'Ingrese el nombre de su estado',
      AGENT_DATA: 'Datos del agente',
      AGENTS_CODE: 'Código del agente',
      AGENTS_CODE_PLACEHOLDER: 'Ingrese el código del agente',
      AGENTS_NAME: 'Nombre del agente',
      AGENTS_NAME_PLACEHOLDER: 'Ingrese el nombre del agente',
      AGENCIA: 'Agencia',
      AGENCIA_PLACEHOLDER: 'Ingrese el nombre de la agencia',
      INPORTANT_INFORMATION: 'Informaciones importantes',
      INSTALLMENT: 'cuota',
      OF: ' de R$ ',
      INTEREST_FREE: 'DESCUENTO PIX',
      INSTALLMENT_PLACEHOLDER: 'Seleccione la cantidad de cuotas',
    },
    INFORMATIONS: {
      NEED_KNOW: '¿Qué necesitas saber?',
      NEED_KNOW_DESCRIPTION: [
        'Personas adicionales pueden incurrir en cargos adicionales que varían según la política de la propiedad.',
        'Se pueden requerir un documento de identificación oficial con foto y tarjeta de crédito, tarjeta de débito o depósito en efectivo al momento del check-in para gastos adicionales.',
        'Las solicitudes especiales están sujetas a disponibilidad en el momento del check-in y pueden incurrir en cargos adicionales. Estas solicitudes no están garantizadas.',
        'Las fiestas o eventos de grupos en el lugar están estrictamente prohibidos. Los recursos de seguridad disponibles en la propiedad incluyen extintor de incendios y detector de humo.',
      ],
      PAYMENT_TERMS: [
        'Al hacer clic en el botón a continuación, acepto las siguientes políticas: Reglas de la Casa establecidas por el propietario, Reglas básicas para los huéspedes, Política de Reembolso. Además, acepto que la Plataforma puede enviar el cargo a mi forma de pago si soy responsable de algún daño. Acepto pagar el monto total indicado si el propietario acepta mi solicitud de reserva. Términos de Pago entre usted y Plataforma Plataforma Digital Ltda.',
        'También acepto los Términos de Servicio actualizados, los Términos de Servicio de Pagos y confirmo que he leído la Política de Privacidad.',
      ],
    },
    HOUSE_DETAILS: {
      PRICE_INFO: 'Información de precio',
      SERVICES_FEE: 'Tarifa de servicios',
      NIGHTS: 'noches',
    },
    ACTIONS: {
      SUBMIT: 'Confirmar reserva',
      BOOKING_MADE: '¡Reserva confirmada con éxito!',
      FAILURE_PAYMENT: 'Fallo en el pago',
      EXTERNAL_LINK: 'Generar enlace de pago',
    },
  },
  RESERVATION_CONFIRMED: {
    TITLE: 'Reserva confirmada',
    DEAR: 'Estimado/a',
    DESCRIPTIONS: [
      '¡Gracias por elegir',
      'para su próxima estancia! Estamos emocionados de darle la bienvenida y queremos asegurarnos de que su experiencia sea excepcional. Aquí están los detalles de su reserva:',
    ],
    RESERVATION_ID: 'ID de reserva:',
    CHECKIN_DATE: 'Check-in:',
    CHECKOUT_DATE: 'Check-out:',
    TOTAL_COST: 'Costo total:',
  },
};
