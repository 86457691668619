import { APP_KEYS } from 'constants/api/keys';
import { axiosInstances } from 'services/api';

interface EmbraerUser {
  name: string;
  cpf?: string;
  email: string;
  phone: string;
  password?: string;
  association?: string;
  associationId?: string;
  address?: string;
  city?: string;
  zipcode?: string;
  country?: string;
  state?: string;
}

const createEmbraerUser = async (userData: EmbraerUser): Promise<void> => {
  const agencyCode = APP_KEYS.EMBRAER_AGENCY_CODE;
  await axiosInstances.base.instance.post('/service/users/create', {
    ...userData,
    agencyCode: agencyCode,
    role: 'USER_B2C',
  });
};

export default createEmbraerUser;
