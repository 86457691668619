import { axiosInstances } from 'services/api';
import { IApi } from 'models/application/interfaces/Api.interface';
import { HouseResume } from 'models/application/HouseResume.model';
import { IPrincipalHouseResponse } from 'models/contracts/response/PrincipalHouse.response';

export const getPrincipalHouses = async (): Promise<HouseResume[]> => {
  const { data }: IApi<IPrincipalHouseResponse[]> = await axiosInstances.base.instance.get(
    '/service/v1/house/principal-houses',
  );

  const houses = data.map((house: IPrincipalHouseResponse) => {
    return new HouseResume({
      id: house.houseId,
      name: house.houseName,
      nameEs: house.houseNameEs,
      namePt: house.houseNamePt,
      nameUs: house.houseNameUs,
      mainPicture: house.mainPicture,
      amenities: house.amenities.map((amenitie) => ({
        name: amenitie,
      })),
      maxOccupancy: house.maxOccupancy ?? 0,
      bedroomAmount: house.roomAmount,
      bathroomAmount: house.bathroomAmount,
      price: {
        real: house?.startPriceReal,
        dollar: house?.startPriceDollar,
      },
    });
  });

  return houses;
};
