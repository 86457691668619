import Box from '@mui/material/Box';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { Buttons } from 'components/molecules/buttons';
import { useMemo, useState } from 'react';
import US from 'country-flag-icons/react/3x2/US';
import BR from 'country-flag-icons/react/3x2/BR';
import ES from 'country-flag-icons/react/3x2/ES';
import { Dialog } from '@mui/material';
import WECircleFlag from 'components/atoms/WeCircleFlag';
import Inputs from 'components/molecules/inputs';
import i18next from 'i18next';
import { Languages } from 'languages/helpers.languages';

type LanguageChoiceModalProps = {
  onConfirm: (value: string, languageSelected: string) => void;
  isOpen: boolean;
};

const LanguageChoiceModal: React.FC<LanguageChoiceModalProps> = ({ onConfirm, isOpen }) => {
  const { t } = useTranslation();

  const [value, setValue] = useState<string>();
  const [languageSelected, setLanguageSelected] = useState<string>();

  const languages = useMemo(() => {
    return [
      {
        value: Languages.EN_US,
        message: `If you prefer to continue in English${'&nbsp;&nbsp;&nbsp;'}`,
        flag: <US title="" className={styles.flag} />,
      },
      {
        value: Languages.ES,
        message: `Si prefiere continuar en Español${'&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'}`,
        flag: <ES title="" className={styles.flag} style={{ marginRight: '2px' }} />,
      },
      {
        value: Languages.PT_BR,
        message: `${'  '}Se preferir continuar em Português`,
        flag: <BR title="" className={styles.flag} />,
      },
    ];
  }, []);

  const categories = [
    // {
    //   value: 'AGENT',
    //   label: t('LANGUAGE.CHOOSE.CATEGORY.AGENT'),
    // },
    {
      value: 'TRAVEL_AGENCY',
      label: t('LANGUAGE.CHOOSE.CATEGORY.AGENCY'),
    },
    // {
    //   value: 'PROPERTY_MANAGER',
    //   label: t('LANGUAGE.CHOOSE.CATEGORY.PROPERTY_MANAGER'),
    // },
    // {
    //   value: 'FREELANCER',
    //   label: t('LANGUAGE.CHOOSE.CATEGORY.FREELANCER'),
    // },
    // {
    //   value: 'GOWEASY',
    //   label: t('LANGUAGE.CHOOSE.CATEGORY.GOWEASY'),
    // },
    /*
    TODO: remover outras opções do código que envolvem essa opção.
    {
      value: 'TRAVEL_OPERATOR',
      label: t('LANGUAGE.CHOOSE.CATEGORY.TRAVEL_OPERATOR'),
    },*/
    {
      value: 'AGENCY_OPERATOR',
      label: t('LANGUAGE.CHOOSE.CATEGORY.OPERATOR'),
    },
  ];

  const handleSelectIdiom = (idiom: string) => {
    i18next.changeLanguage(idiom);
    setLanguageSelected(idiom);
    localStorage.setItem('idiom', idiom);
  };

  const handleClick = () => {
    if (!value || !languageSelected) return;

    onConfirm(value, languageSelected);
  };

  return (
    <Dialog open={isOpen}>
      <Box className={styles.content}>
        <div className={styles.title}>{t('LANGUAGE.CHOOSE.TITLE')}</div>

        <div className={styles.options}>
          {languages.map((language) => {
            return (
              <div
                key={language.value}
                onClick={() => handleSelectIdiom(language.value)}
                className={`${styles.option} ${language.value === languageSelected && styles.optionSelected}`}
              >
                <WECircleFlag>{language.flag}</WECircleFlag>
                <div dangerouslySetInnerHTML={{ __html: language.message }}></div>
              </div>
            );
          })}
        </div>

        <div className={styles.select}>
          <Inputs.Select
            className={styles.outlineSelect}
            label={t('LANGUAGE.CHOOSE.CATEGORY_INPUT.LABEL')}
            placeholder={t('LANGUAGE.CHOOSE.CATEGORY_INPUT.PLACEHOLDER')}
            autoComplete="new-password"
            value={value}
            onChangeValue={setValue}
            options={categories}
            labelClass={styles.labelClass}
          />
        </div>

        <Buttons.Default disabled={!value || !languageSelected} fullWidth variant="contained" onClick={handleClick}>
          {t('LANGUAGE.CHOOSE.CONFIRM')}
        </Buttons.Default>
      </Box>
    </Dialog>
  );
};

export default LanguageChoiceModal;
