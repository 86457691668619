export const LanguageEnum = {
  ENGLISH: 'ENGLISH' as Language,
  SPANISH: 'SPANISH' as Language,
  PORTUGUESE: 'PORTUGUESE' as Language,
};

export type Language = 'ENGLISH' | 'SPANISH' | 'PORTUGUESE';

// TODO: REFATORAR TODA A LINGUAGEM POIS O BROWSER TEM DIFENTES SIGLAS
export type LanguageAcronym = 'pt-BR' | 'en' | 'en-US' | 'es' | 'pt-PT' | 'pt';
