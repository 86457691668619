import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { FaEdit } from 'react-icons/fa';
import BasicTable from 'components/BasicTable';
import i18n from 'languages';
import { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

export type Language = 'pt-BR' | 'en' | 'es' | 'pt-PT';

const Status: React.FC<any> = ({ status }) => {
  const { t } = useTranslation();
  return (
    <div>
      {status === 'PRÉ-RESERVA' && <label className={styles.statusCreated}>{t('BACKOFFICE.TABLE.PRE_BOOKING')}</label>}
      {status === 'CONFIRMADA' && <label className={styles.statuSucceeded}>{t('BACKOFFICE.TABLE.CONFIRMED')}</label>}
      {status === 'CANCELADA' && <label className={styles.statusFailed}>{t('BACKOFFICE.TABLE.CANCELED')}</label>}
    </div>
  );
};

const ReservationTable: React.FC<any> = ({ reservations, isPartnerUser, isUser }) => {
  const { t } = useTranslation();
  const initialLanguage = i18n.language as Language;
  const [reservationsValues, setReservationValues] = useState([]);
  const [currentLanguage, setCurrentLanguage] = useState<Language>(initialLanguage);
  const navigate = useNavigate();

  const collumnsNames = isPartnerUser
    ? [
        t('BACKOFFICE.TABLE.RESERVATION'),
        t('BACKOFFICE.COMMISSIONS_TABLE.DATE'),
        t('BACKOFFICE.TABLE.CLIENT_NAME'),
        t('BACKOFFICE.TABLE.HOUSE'),
        t('BACKOFFICE.TABLE.PURCHASED_BY'),
        t('BACKOFFICE.TABLE.VALUE'),
        t('BACKOFFICE.TABLE.DAILY_RATES'),
        t('BACKOFFICE.TABLE.STATUS'),
        t('BACKOFFICE.TABLE.CHANGE'),
      ]
    : [
        t('BACKOFFICE.TABLE.RESERVATION'),
        t('BACKOFFICE.COMMISSIONS_TABLE.DATE'),
        t('BACKOFFICE.TABLE.CLIENT_NAME'),
        t('BACKOFFICE.TABLE.HOUSE'),
        t('BACKOFFICE.TABLE.SOLD_BY'),
        t('BACKOFFICE.TABLE.AGENCY'),
        t('BACKOFFICE.TABLE.VALUE'),
        t('BACKOFFICE.TABLE.DAILY_RATES'),
        t('BACKOFFICE.TABLE.STATUS'),
        t('BACKOFFICE.TABLE.CHANGE'),
      ];

  const convertStatus: any = (status: any) => {
    switch (status) {
      case 'PENDING':
        return 'PRÉ-RESERVA';
      case 'APPROVED':
        return 'CONFIRMADA';
      case 'CANCELED':
        return 'CANCELADA';
      default:
        return '';
    }
  };

  const handleNavigation = (id: any, nameClient: any, days: any, status: any, idReservation: any) => {
    isUser || isPartnerUser
      ? navigate(`/panel/reservations-details/${id}`, {
          state: {
            nameClient,
            days,
            status,
            idReservation,
          },
        })
      : navigate(`/admin/reservations-details/${id}`, {
          state: {
            nameClient,
            days,
            status,
            idReservation,
          },
        });
  };

  const formatDate = (dateString: string, language: Language): string => {
    if (language === 'pt-BR' || language === 'pt-PT') {
      //moment.locale('pt-br');
      return moment(dateString).format('DD MMM YYYY HH:mm');
    } else {
      //moment.locale('en');
      return moment(dateString).format('MMM DD, YYYY HH:mm');
    }
  };

  useEffect(() => {
    // TODO: tentar melhorar isso com um hook talvez
    i18n.on('languageChanged', (l) => {
      setCurrentLanguage(l as Language);
    });

    return () => {
      i18n.off('languageChanged');
    };
  }, []);

  useEffect(() => {
    const rows = reservations?.map((value: any) => {
      return {
        reservaCode: String(value?.reservaCode),
        date: String(value?.date),
        nameClient: value?.nameClient,
        houseId: String(value?.houseId),
        agente: value?.agente,
        agency: value?.agency,
        valueReal: String(value?.valueReal),
        valueDollar: String(value?.valueDollar),
        days: String(value?.days),
        status: convertStatus(value?.status),
      };
    });
    setReservationValues(rows);
  }, [reservations, currentLanguage]);

  return (
    <BasicTable collumnsNames={collumnsNames}>
      {reservationsValues?.map((row: any) => (
        <TableRow key={row?.reservaCode} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
          <TableCell>
            <label className={styles.reservationCode}>#{row?.reservaCode}</label>
          </TableCell>
          <TableCell>
            <div className={styles.dateReservation}>{formatDate(row?.date, currentLanguage)}</div>
          </TableCell>
          <TableCell>{row?.nameClient}</TableCell>
          <TableCell>
            <label className={styles.house}>#{row?.houseId}</label>
          </TableCell>
          <TableCell>{row?.agente}</TableCell>
          {!isPartnerUser && <TableCell>{row?.agency}</TableCell>}
          <TableCell>
            <label className={styles.currency}>
              {currentLanguage === 'pt-BR' || currentLanguage === 'pt-PT'
                ? 'R$' + ' ' + row?.valueReal
                : '$' + ' ' + row?.valueDollar}
            </label>
          </TableCell>
          <TableCell>{row?.days}</TableCell>
          <TableCell>
            <Status status={row?.status} />
          </TableCell>
          <TableCell>
            <FaEdit
              style={{ cursor: 'pointer' }}
              size={24}
              color="#0049A1"
              onClick={() =>
                handleNavigation(row?.reservaCode, row?.nameClient, row?.days, row?.status, row?.reservaCode)
              }
            />
          </TableCell>
        </TableRow>
      ))}
    </BasicTable>
  );
};

export default ReservationTable;
