import React from 'react';
import { BaseProps } from './BaseProps';

const IntegratedOfficeSVG: React.FC<BaseProps> = ({ color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46">
      <g
        id="Grupo_de_máscara_252"
        data-name="Grupo de máscara 252"
        transform="translate(-549 -642)"
        clipPath="url(#clip-path)"
      >
        <g id="monitor_13357447" transform="translate(549.753 644.09)">
          <path
            id="Caminho_7706"
            data-name="Caminho 7706"
            d="M21.345,36.039H9.109a1.373,1.373,0,0,1-1.372-1.372V33.033a1.373,1.373,0,0,1,1.372-1.372H21.345a1.373,1.373,0,0,1,1.372,1.372v1.642a1.366,1.366,0,0,1-1.372,1.364Z"
            transform="translate(-2.068 -4.663)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.565"
          />
          <path
            id="Caminho_7707"
            data-name="Caminho 7707"
            d="M9.867,30.876,6.021,13.82a1.206,1.206,0,0,0-1.185-.99H3.022a1.2,1.2,0,0,0-1.185,1.394l3.8,19.328H7.5"
            transform="translate(-1.822 -3.878)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.565"
          />
          <path
            id="Caminho_7708"
            data-name="Caminho 7708"
            d="M15.553,36.229V46.673"
            transform="translate(-2.394 -4.853)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.565"
          />
          <path
            id="Caminho_7709"
            data-name="Caminho 7709"
            d="M11.273,46.969v-.855a2.773,2.773,0,0,1,2.774-2.774h2.647a2.773,2.773,0,0,1,2.774,2.774v.855"
            transform="translate(-2.215 -5.15)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.565"
          />
          <path
            id="Caminho_7710"
            data-name="Caminho 7710"
            d="M8.584,24.433H46.572V21.4H7.9"
            transform="translate(-2.075 -4.236)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.565"
          />
          <path
            id="Caminho_7711"
            data-name="Caminho 7711"
            d="M31.246,24.565H44.891V46.187H31.246Z"
            transform="translate(-3.048 -4.367)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.565"
          />
          <path
            id="Caminho_7712"
            data-name="Caminho 7712"
            d="M31.246,31.668H44.891"
            transform="translate(-3.048 -4.663)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.565"
          />
          <path
            id="Caminho_7713"
            data-name="Caminho 7713"
            d="M31.246,40H44.891"
            transform="translate(-3.048 -5.01)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.565"
          />
          <path
            id="Caminho_7714"
            data-name="Caminho 7714"
            d="M8.61,36.065V46.6H6.016V34.453"
            transform="translate(-1.996 -4.779)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.565"
          />
          <path
            id="Caminho_7715"
            data-name="Caminho 7715"
            d="M29.313,17.779H11.7a1.488,1.488,0,0,1-1.484-1.484V4.974A1.488,1.488,0,0,1,11.7,3.489H29.313A1.488,1.488,0,0,1,30.8,4.974V16.294A1.478,1.478,0,0,1,29.313,17.779Z"
            transform="translate(-2.171 -3.489)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.565"
          />
          <path
            id="Caminho_7716"
            data-name="Caminho 7716"
            d="M10.217,15.114H30.8"
            transform="translate(-2.171 -3.974)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.565"
          />
          <path
            id="Caminho_7717"
            data-name="Caminho 7717"
            d="M18.877,18.4v2.879"
            transform="translate(-2.532 -4.11)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.565"
          />
          <path
            id="Caminho_7718"
            data-name="Caminho 7718"
            d="M23.039,18.4v2.879"
            transform="translate(-2.706 -4.11)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.565"
          />
          <path
            id="Caminho_7719"
            data-name="Caminho 7719"
            d="M39.751,21.184h.367a3.2,3.2,0,0,0,3.2-3.2V16.124H36.55v1.859a3.2,3.2,0,0,0,3.2,3.2Z"
            transform="translate(-3.269 -4.016)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.565"
          />
          <path
            id="Caminho_7720"
            data-name="Caminho 7720"
            d="M45.648,16.374v1.477"
            transform="translate(-3.648 -4.026)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.565"
          />
          <path
            id="Caminho_7721"
            data-name="Caminho 7721"
            d="M38.556,10.428a1.268,1.268,0,0,0,.1,1.747,1.26,1.26,0,0,1,.135,1.589"
            transform="translate(-3.34 -3.778)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.565"
          />
          <path
            id="Caminho_7722"
            data-name="Caminho 7722"
            d="M40.645,7.448a1.268,1.268,0,0,0,.1,1.747,1.26,1.26,0,0,1,.135,1.589"
            transform="translate(-3.427 -3.654)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.565"
          />
        </g>
      </g>
    </svg>
  );
};

export default IntegratedOfficeSVG;
