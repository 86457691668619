import { Outlet } from 'react-router-dom';
import { isDesktop } from 'react-device-detect';
import styles from './styles.module.scss';

import { ReactComponent as Logo } from '../../assets/svg/wordmark.svg';
import { ReactComponent as LogoWhite } from '../../assets/svg/wordmark-white.svg';
import usePublicNavigation from 'routes/navigation/public.navigation';

const PartnerRegisterLayout = () => {
  const { navigateToLogin } = usePublicNavigation();

  return (
    <>
      <div className={styles.layout}>
        <div className={styles.logoContainer}>
          <div className={styles.logo} onClick={navigateToLogin}>
            {isDesktop ? <LogoWhite width={180} /> : <Logo width={180} />}
          </div>
        </div>
        <Outlet />
      </div>
    </>
  );
};

export default PartnerRegisterLayout;
