import { Container, DivForm, GuestsContainer, Span, Value } from './Checkout.style';
import 'react-date-picker/dist/DatePicker.css';
import { useState } from 'react';
import WESelectGuests from 'components/WESelectGuests';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Buttons } from 'components/molecules/buttons';
import { isLogged } from 'services/Login/user.service';
import usePublicNavigation from 'routes/navigation/public.navigation';
import { setPath } from 'services/Login/redirect.service';
import WEDatePickerModalRange from 'components/WEDatePickerModalRange';
import { Skeleton } from '@mui/material';

type CheckoutProps = {
  loading?: boolean;
  totalWithouTaxes: number | string;
  maxGuests: number;
  petsAllowed: boolean;
  initialPeriod: string[];
  onSubmit: (data: any) => void;
  onChangePeriod: (data: any) => void;
  onChangeGuests: (data: any) => void;
  ratesCalendar?: any[];
  dateSchedule?: {
    date: string;
    label: string;
  }[];
  isValidBooking: boolean;
};

const Checkout: React.FC<CheckoutProps> = ({
  totalWithouTaxes,
  onSubmit,
  maxGuests,
  petsAllowed,
  initialPeriod,
  onChangePeriod,
  onChangeGuests,
  ratesCalendar,
  dateSchedule,
  loading,
}) => {
  const { navigateToLogin } = usePublicNavigation();
  const [period, setPeriod] = useState([
    initialPeriod[0] ? moment(initialPeriod[0]).utc().format() : null,
    initialPeriod[1] ? moment(initialPeriod[1]).utc().format() : null,
  ]);
  const [guests, setGuests] = useState({
    adults: Number(new URLSearchParams(location.search).get('adults')) || 0,
    kids: Number(new URLSearchParams(location.search).get('kids')) || 0,
    babies: Number(new URLSearchParams(location.search).get('babies')) || 0,
    pets: Number(new URLSearchParams(location.search).get('pets')) || 0,
    total: Number(new URLSearchParams(location.search).get('total')) || 0,
  });

  const totalGuests = guests?.total;

  const { t } = useTranslation();

  const handlePeriod = (value: any) => {
    setPeriod(value);
    if (value[0] && value[1]) {
      onChangePeriod(value);
    }
  };

  const handleGuests = (value: any) => {
    setGuests(value);
    onChangeGuests(value);
  };

  const handleSubmit = () => {
    if (guests.total === 0) return;

    onSubmit({ guests: guests, period });
  };

  const savePathForNextLogin = (pathToSave: string) => {
    setPath(pathToSave);
  };

  const handleNavigateToLogin = () => {
    const pathToRedirectInLogin = `${window.location.pathname}${window.location.search}`;
    savePathForNextLogin(pathToRedirectInLogin);
    navigateToLogin();
  };

  return (
    <Container>
      <div>
        {loading ? (
          <Skeleton variant="rectangular" width={150} height={25} />
        ) : (
          <>
            <Value>
              {t('HOUSE_DETAILS.CHECKOUT.CURRENCY')} {!initialPeriod[0] ? '--' : totalWithouTaxes}
            </Value>
            <Span>{t('HOUSE_DETAILS.CHECKOUT.TOTAL_REMINDER')}</Span>
          </>
        )}
      </div>
      <DivForm>
        <div>
          <WEDatePickerModalRange
            ratesCalendar={ratesCalendar}
            minDate={new Date()}
            value={period}
            onChangeValue={handlePeriod}
            daysLabel={dateSchedule}
          />
        </div>
        <GuestsContainer>
          <WESelectGuests petsAllowed={petsAllowed} limitGuests={maxGuests} onChange={handleGuests} />
        </GuestsContainer>
      </DivForm>

      {totalGuests < 1 && isLogged() && (
        <label style={{ color: 'red', fontSize: '14px' }}>{t('HOUSE_DETAILS.CHECKOUT.ERROR_0_GUESTS')}</label>
      )}

      {isLogged() ? (
        <Buttons.Primary disabled={totalGuests < 1} onClick={handleSubmit}>
          {t('HOUSE_DETAILS.CHECKOUT.BUTTON_RESERVE')}
        </Buttons.Primary>
      ) : (
        <Buttons.Primary onClick={handleNavigateToLogin}>{t('HOUSE_DETAILS.CHECKOUT.BUTTON_LOGIN')}</Buttons.Primary>
      )}
    </Container>
  );
};

export default Checkout;
