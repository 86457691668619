import i18next from 'i18next';
import { Languages } from 'languages/helpers.languages';
import { formatMoney } from 'utils/format';
import { IPrice } from './interfaces/IPrice.interface';
import { Installment } from 'pages/Checkout/Form/interfaces/Installment';

class Checkout {
  total: IPrice;
  taxes: IPrice;
  servicesFee: IPrice;
  platformFee: IPrice;
  discount: IPrice;
  installments: Installment[];
  descontoParceiro: boolean;

  constructor(
    total: IPrice,
    taxes: IPrice,
    servicesFee: IPrice,
    platformFee: IPrice,
    discount: IPrice,
    installments: Installment[],
    descontoParceiro: boolean,
  ) {
    this.total = total;
    this.taxes = taxes;
    this.servicesFee = servicesFee;
    this.platformFee = platformFee;
    this.discount = discount;
    this.installments = installments;
    this.descontoParceiro = descontoParceiro;
  }

  getTotalPrice = (): string => {
    const currentLanguage = i18next.language;
    if (currentLanguage === Languages.PT_BR) {
      return formatMoney(this.total.real);
    }

    return formatMoney(this.total.dollar);
  };

  getTotalPriceNumber = (): number => {
    const currentLanguage = i18next.language;

    if (currentLanguage === Languages.PT_BR) {
      return this.total.real;
    }

    return this.total.dollar;
  };

  getTaxes = (): string => {
    const currentLanguage = i18next.language;

    if (currentLanguage === Languages.PT_BR) {
      return formatMoney(this.taxes?.real);
    }

    return formatMoney(this.taxes?.dollar);
  };

  getServiceFee = (): string => {
    const currentLanguage = i18next.language;

    if (currentLanguage === Languages.PT_BR) {
      return formatMoney(this.servicesFee?.real);
    }

    return formatMoney(this.servicesFee?.dollar);
  };

  getPlatformFee = (): string => {
    const currentLanguage = i18next.language;

    if (currentLanguage === Languages.PT_BR) {
      return formatMoney(this.platformFee?.real);
    }

    return formatMoney(this.platformFee?.dollar);
  };

  getDailyTotal = (): string => {
    const currentLanguage = i18next.language;

    if (currentLanguage === Languages.PT_BR) {
      const total =
        this.total.real - this.taxes.real - this.platformFee.real - this.servicesFee.real - this.discount.real;

      return formatMoney(total);
    }

    const total =
      this.total.dollar - this.taxes.dollar - this.platformFee.dollar - this.servicesFee.dollar - this.discount.dollar;
    return formatMoney(total);
  };

  getDiscount = (): string => {
    const currentLanguage = i18next.language;

    if (this.discount.real === 0) {
      return '0';
    }

    if (currentLanguage === Languages.PT_BR) {
      return formatMoney(this.discount.real);
    }

    return formatMoney(this.discount?.dollar);
  };

  getIsPartner = (): boolean => {
    return this.descontoParceiro;
  };
}

export default Checkout;
