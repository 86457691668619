import Checkout from 'models/application/Checkout.model';
import StripeConfig from 'models/application/StripeConfig.model';
import { StripeConfigResponse } from 'models/contracts/response/StripeConfig.response';
import { axiosInstances } from 'services/api';
import { IApi } from 'models/application/interfaces/Api.interface';

export const getCheckoutValues = async (
  houseId: number,
  checkinDate: string,
  checkoutDate: string,
  userId: number,
  guests: number,
): Promise<{ stripeConfig: StripeConfig; checkOut: Checkout; paymentTypeEnum: string }> => {
  const { data }: IApi<StripeConfigResponse> = await axiosInstances.base.instance.post('service/payment/intent', {
    houseId,
    userId,
    checkin: checkinDate,
    checkout: checkoutDate,
    guests: Number(guests),
  });

  const stripeConfig = new StripeConfig(data.paymentIntentId, data.clientSecretId);

  const checkOut = new Checkout(
    {
      dollar: data.totalRent,
      real: data.totalRentConverted,
    },
    {
      dollar: data.totalTax,
      real: data.totalTaxConverted,
    },
    {
      dollar: data.totalRentService,
      real: data.totalRentServiceConverted,
    },
    {
      dollar: data.totalService,
      real: data.totalServiceConverted,
    },
    {
      dollar: data.totalDiscount,
      real: data.totalDiscountConverted,
    },
    data.parcelamento,
    data.descontoParceiro,
  );

  return { stripeConfig, checkOut, paymentTypeEnum: data.paymentTypeEnum };
};
