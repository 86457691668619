import i18next from 'i18next';
import { IAmenities } from './interfaces';
import { IHouseResume } from './interfaces/IHouseResume.interface';
import { IPrice } from './interfaces/IPrice.interface';
import { Languages } from 'languages/helpers.languages';
import { ICoordinates } from './interfaces/ICoordinates.interface';

export class HouseResume implements IHouseResume {
  mainPicture: string;
  price: IPrice;
  name: string;
  nameEs: string;
  namePt: string;
  nameUs: string;
  id: number;
  bedroomAmount: number;
  bathroomAmount: number;
  maxOccupancy: number;
  amenities: IAmenities[];
  coordinates?: ICoordinates;

  constructor(house: IHouseResume) {
    this.id = house.id;
    this.name = house.name;
    this.nameEs = house.nameEs;
    this.namePt = house.namePt;
    this.nameUs = house.nameUs;
    this.mainPicture = house.mainPicture;
    this.price = house.price;
    this.amenities = house.amenities;
    this.maxOccupancy = house.maxOccupancy;
    this.bedroomAmount = house.bedroomAmount;
    this.bathroomAmount = house.bathroomAmount;
    this.coordinates = house.coordinates;
  }

  getPrice(): number | undefined {
    const currentLanguage = i18next.language;

    return currentLanguage === Languages.PT_BR ? this.price.real : this.price.dollar;
  }
}
