import styles from './styles.module.scss';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Inputs from 'components/molecules/inputs';
import { Grid } from '@mui/material';
import { Buttons } from 'components/molecules/buttons';
import getAddressByCep from 'services/CEP/getAddressByCep';
import { useState } from 'react';
import { validateEmbraerUser } from './validadeUserEmbraer';
import EmbraerRegisterModel from 'models/application/Embraer/embraerRegister.model';
import useAsync from 'hooks/useAsync.hook';
import { toast } from 'react-toastify';
import createEmbraerUser from 'services/Embraer/createEmbraerUser';
import { useNavigate } from 'react-router-dom';

const EmbraerRegisterForm: React.FC<any> = () => {
  const navigate = useNavigate();
  const form = useForm<EmbraerRegisterModel>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    resolver: yupResolver(validateEmbraerUser),
  });
  const [isDirty, setIsDirty] = useState<boolean>();

  const { call: createUser } = useAsync(async (user: EmbraerRegisterModel) => {
    try {
      await createEmbraerUser({
        name: user.name,
        email: user.email,
        phone: user.cel,
        address: user.address,
        zipcode: user.cep,
        state: user.state,
        country: user.country,
        cpf: user.cpf,
        city: user.city,
        associationId: user.associationId,
        password: user.password,
      });
      toast.success('Cadastro realizado com sucesso');
      navigate('/');
    } catch {
      toast.error('Algo deu errado, por favor tente mais tarde.');
    }
  });

  const beforeSubmit = (data: EmbraerRegisterModel) => {
    const formData = new EmbraerRegisterModel(data);
    createUser(formData);
  };

  const getCep = async (c: string) => {
    const response = await getAddressByCep(c);
    if (response.logradouro) {
      form.setValue('state', response.estado, {
        shouldTouch: true,
        shouldDirty: true,
        shouldValidate: true,
      });
      form.setValue('city', response.localidade, {
        shouldTouch: true,
        shouldDirty: true,
        shouldValidate: true,
      });
      form.setValue('country', 'Brasil', {
        shouldTouch: true,
        shouldDirty: true,
        shouldValidate: true,
      });
      form.setValue('address', response.logradouro + ', ' + response.bairro, {
        shouldTouch: true,
        shouldDirty: true,
        shouldValidate: true,
      });
      setIsDirty(true);
    } else {
      form.setValue('state', '');
      form.setValue('address', '');
      form.setValue('city', '');
      form.setValue('country', '');
    }
  };

  const handleBlur = (e: any) => {
    const cepOnBlur = e?.target?.value;
    const cepNumbers = cepOnBlur?.match(/\d+/g)?.join('');
    if (cepNumbers) getCep(cepNumbers);
  };

  return (
    <div style={{ padding: '48px' }}>
      <h3>Dados cadastrais</h3>
      <div className={styles.hr} />
      <div className={styles.container}>
        <Grid container spacing={4}>
          <Grid item xl={3} md={3} xs={12}>
            <Inputs.Text form={form} required label="Nome:" name="name" />
          </Grid>
          <Grid item xl={3} md={3} xs={12}>
            <Inputs.Text form={form} required label="Email:" name="email" />
          </Grid>
          <Grid item xl={3} md={3} xs={12}>
            <Inputs.Mask
              whiteVersion={false}
              form={form}
              mask="(99) 99999-9999"
              required
              label="Celular :"
              name="cel"
              id="cel"
            />
          </Grid>
          <Grid item xl={3} md={3} xs={12}>
            <Inputs.Mask
              whiteVersion={false}
              form={form}
              mask="999.999.999-99"
              required
              label="CPF:"
              name="cpf"
              id="cpf"
            />
          </Grid>
        </Grid>
        <Grid container spacing={4} style={{ marginTop: '20px' }}>
          <Grid item xl={3} md={3} xs={12}>
            <Inputs.Mask
              whiteVersion={false}
              form={form}
              mask="99999-999"
              required
              label="CEP:"
              name="cep"
              id="cep"
              onBlur={handleBlur}
            />
          </Grid>
          <Grid item xl={3} md={3} xs={12}>
            <Inputs.Text
              form={form}
              required
              label="Endereço:"
              name="address"
              inputLabelProps={{
                shrink: isDirty,
              }}
            />
          </Grid>
          <Grid item xl={3} md={3} xs={12}>
            <Inputs.Text
              form={form}
              required
              label="Número:"
              name="number"
              inputLabelProps={{
                shrink: isDirty,
              }}
            />
          </Grid>
          <Grid item xl={3} md={3} xs={12}>
            <Inputs.Text
              form={form}
              required
              label="Cidade:"
              name="city"
              inputLabelProps={{
                shrink: isDirty,
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={4} style={{ marginTop: '20px' }}>
          <Grid item xl={3} md={3} xs={12}>
            <Inputs.Text
              form={form}
              required
              label="Estado:"
              name="state"
              inputLabelProps={{
                shrink: isDirty,
              }}
            />
          </Grid>
          <Grid item xl={3} md={3} xs={12}>
            <Inputs.Text
              form={form}
              required
              label="País:"
              name="country"
              inputLabelProps={{
                shrink: isDirty,
              }}
            />
          </Grid>
          <Grid item xl={3} md={3} xs={12}>
            <Inputs.Text
              form={form}
              required
              label="Numero de Chapa:"
              name="associationId"
              inputLabelProps={{
                shrink: isDirty,
              }}
            />
          </Grid>
        </Grid>
      </div>
      <h3>Senha de Acesso</h3>
      <div className={styles.hr} />
      <Grid container spacing={4}>
        <Grid item xl={3} md={3} xs={12}>
          <Inputs.Text type="password" autoComplete="new-password" form={form} label="Senha:" name="password" />
        </Grid>
        <Grid item xl={3} md={3} xs={12}>
          <Inputs.Text
            type="password"
            autoComplete="new-password"
            form={form}
            label="Confirmar Senha:"
            name="confirmPassword"
          />
        </Grid>
      </Grid>
      <div className={styles.buttons}>
        <Buttons.Orange className={styles.buttonConfirm} variant="contained" onClick={form.handleSubmit(beforeSubmit)}>
          Cadastrar
        </Buttons.Orange>
      </div>
    </div>
  );
};

export default EmbraerRegisterForm;
