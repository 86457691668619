import { useEffect, useMemo, useState } from 'react';
import styles from './styles.module.scss';
import Header from 'components/Header';
import SearchHouseMini from 'components/SearchHouseMini';
import { useTranslation } from 'react-i18next';
import { Buttons } from 'components/molecules/buttons';
import COLORS from 'constants/colors';
import { HiOutlineMap } from 'react-icons/hi';
import SearchMap from 'components/Maps/SearchMap/Location.component';
import { useLocation, useNavigate } from 'react-router-dom';
import useHouseListNavigation from 'routes/navigation/listHouses.navigation';
import FilterHome from 'components/Filters/FilterHome';
import ListHousesSection from 'components/ListHousesSection';
import ListHousesLoading from 'components/ListHousesSection/loading';
import useHouses from 'hooks/useHouses';
import { ImageNotAvailable, VistaCay } from 'assets';
import { DISNEY_LOCATION } from 'pages/SearchHouses/components/SectionList';
import { HouseResume } from 'models/application/HouseResume.model';
import FiltersEnum from 'utils/enums/filters.enum';
import useFilters from 'hooks/useFilters';
import { MdOutlineChevronLeft } from 'react-icons/md';
import { getFiltersFromStorage, setOrigim } from 'utils/helpers';

const DEFAULT_FILTERS = [
  FiltersEnum.SINGLE_FAMILY,
  FiltersEnum.TOWNHOMES,
  FiltersEnum.APARTMENT,
  FiltersEnum.CONDOMINIUM,
  FiltersEnum.VILLA,
  FiltersEnum.LUXURY_HOUSE,
  FiltersEnum.MANOR,
  FiltersEnum.THEMATIC,
];

const HousesInCondominium = () => {
  const [mapIsOpen, setMapIsOpen] = useState(false);
  const [filters, setFiltersHouse] = useState<string[]>([]);

  const location = useLocation();
  const navigate = useNavigate();

  const { getFiltersAsQueryString, getCurrentBookingParameters } = useFilters();
  const { t } = useTranslation();
  const { getCurrentURLData, navigateToCondominiums } = useHouseListNavigation();
  const { checkIn, checkOut } = getCurrentURLData();
  const { houses, loadingHouses, loadHousesByCondominium } = useHouses(filters);
  const booking = getCurrentBookingParameters();

  const housesData = useMemo(() => {
    const data: HouseResume[] = [];

    houses.forEach((house) => {
      data.push(...house.data);
    });

    return data;
  }, [houses]);

  const handleMap = () => {
    setMapIsOpen((m) => !m);
  };

  useEffect(() => {
    if (!location.state) {
      navigate('/');
    }
  }, []);

  useEffect(() => {
    scrollToTop();
    loadHousesByCondominium(location.state.condominiumName, checkIn, checkOut, filters);
  }, [checkIn, checkOut, filters, booking.adults, booking.kids, booking.pets, booking.babies]);

  const handleSearch = (searchValues: any) => {
    if (!searchValues.checkIn || !searchValues.checkOut) return;

    navigate(`/condominium?checkIn=${searchValues.checkIn}&checkOut=${searchValues.checkOut}`, {
      state: location.state,
    });
  };

  const scrollToTop = () => {
    const el = document.getElementById('section-list');
    if (el) {
      el.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleClickItem = (id: number | string) => {
    setOrigim(`${location.pathname}${location.search}`);

    const url = `/house-details/${id}?${getFiltersAsQueryString()}`;
    navigate(url, { state: location.state });
  };

  const handleFilter = (filtersValue: any[]) => {
    if (filters.length === filtersValue.length) return;
    setFiltersHouse(filtersValue);
  };

  const goBack = () => {
    navigateToCondominiums(location.state.city, booking);
  };

  const isVistaCay = (condominiumName: string): boolean => {
    if (!condominiumName) return false;

    return condominiumName.toLowerCase().includes('vista cay resort');
  };

  const getFilters = () => {
    try {
      const storageFilters = getFiltersFromStorage();
      if (storageFilters.length > 0) {
        return storageFilters;
      }
      return DEFAULT_FILTERS;
    } catch (error) {
      return DEFAULT_FILTERS;
    }
  };

  return (
    <div className={styles.container} id="section-list">
      {!location.state ? null : (
        <>
          <Header backgroundTransparent>
            <SearchHouseMini onSearch={handleSearch} initialPeriod={[checkIn, checkOut]} />
          </Header>

          {isVistaCay(location?.state?.condominiumName) ? (
            <div className={styles.background}>
              <video className={styles.videoOpener} autoPlay loop muted>
                <source src={VistaCay} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          ) : (
            <div
              className={styles.background}
              style={{
                backgroundImage: `url(${location.state.image || ImageNotAvailable})`,
              }}
            ></div>
          )}
          <div className={styles.layer}></div>
          <div className={styles.backgroundContent}>
            <div onClick={goBack} className={styles.backButton}>
              <MdOutlineChevronLeft size={40} color="#FFFFFF" />
            </div>
            <div className={styles.infos}>
              <div className={styles.title}>{`${location.state.condominiumName}`.toLowerCase()}</div>
              <div className={styles.description}>{location.state.description}</div>
            </div>

            <Buttons.Default
              className={styles.button}
              endIcon={<HiOutlineMap size={18} />}
              backgroundColor={COLORS.neutral.white}
              color={COLORS.neutral.black}
              variant="contained"
              onClick={handleMap}
            >
              {t('OPEN_MAP')}
            </Buttons.Default>
          </div>
          <div className={styles.blueBackground}>
            {mapIsOpen ? (
              <SearchMap
                center={{
                  lat: housesData[0]?.coordinates?.lat ?? DISNEY_LOCATION.lat,
                  long: housesData[0]?.coordinates?.long ?? DISNEY_LOCATION.long,
                }}
                houses={housesData}
                zoom={13}
                className={styles.location}
                onClickMarker={handleClickItem}
              />
            ) : null}
            <div className={styles.filter}>
              <FilterHome defaultFilters={getFilters()} onChangeFilters={handleFilter} />
            </div>
            {loadingHouses ? (
              <>
                <ListHousesLoading />
              </>
            ) : (
              <div className={styles.houses}>
                {houses.map((house, index) => (
                  <ListHousesSection
                    key={index}
                    title={t(`SECTIONS.${house.title}`)}
                    data={house.data}
                    onClickItem={handleClickItem}
                  />
                ))}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default HousesInCondominium;
