import { useEffect, useState } from 'react';
import './styles.modules.scss';
import { Widget, addResponseMessage, markAllAsRead, setQuickButtons, toggleMsgLoader } from 'react-chat-widget';
import { useTranslation } from 'react-i18next';
import { ChatGPTLogo, TomAvatar } from 'assets';
import useAIChat from 'hooks/AIChat/useAIChat.hook';

const WEChat = () => {
  const { t } = useTranslation();
  const { chatbotAvailable, sendMessage, sendMessageLoading } = useAIChat();

  const [messageAdded, setMessageAdded] = useState(false);

  const buttons = [
    { label: t('TOM_ASSISTANT.QUESTIONS.QUESTION_1'), value: '1' },
    { label: t('TOM_ASSISTANT.QUESTIONS.QUESTION_2'), value: '2' },
    { label: t('TOM_ASSISTANT.QUESTIONS.QUESTION_3'), value: '3' },
    { label: t('TOM_ASSISTANT.QUESTIONS.QUESTION_4'), value: '4' },
  ];

  useEffect(() => {
    if (!chatbotAvailable) return;

    markAllAsRead();

    if (!messageAdded) {
      addResponseMessage(t('TOM_ASSISTANT.FIRST_MESSAGE'));
      addResponseMessage(t('TOM_ASSISTANT.SECOND_MESSAGE'));
      setMessageAdded(true);
    }
    // setQuickButtons(buttons);
  }, [chatbotAvailable]);

  const handleNewUserMessage = async (value: string) => {
    try {
      toggleMsgLoader();
      const answer = await sendMessage(value);

      if (!answer) return;

      toggleMsgLoader();
      addResponseMessage(answer);
    } catch (error) {
      toggleMsgLoader();
    }
  };

  const handleQuickButton = (value: string) => {
    setQuickButtons([]);
    addResponseMessage(`Você selecionou a opção: ${value} - ${buttons.find((el: any) => el.value === value)?.label}`);
  };

  return chatbotAvailable ? (
    <Widget
      handleNewUserMessage={handleNewUserMessage}
      senderPlaceHolder="Pergunte ao tom:"
      profileAvatar={TomAvatar}
      launcherOpenImg={ChatGPTLogo}
      handleQuickButtonClicked={handleQuickButton}
      loading={sendMessageLoading}
      toggleMsgLoader
    />
  ) : (
    <></>
  );
};

export default WEChat;
