import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ShowoffExperiences from 'components/ShowoffExperiences';

import styles from './styles.module.scss';
import SearchHouse from 'components/SearchHouse';
import FilterHome from 'components/Filters/FilterHome';
import Footer from 'components/Footer';
import COLORS from 'constants/colors';
import Copyright from 'components/Copyright';
import useHouseListNavigation from 'routes/navigation/listHouses.navigation';
import Booking from 'models/application/Booking.model';
import { Outlet, useLocation, useOutletContext } from 'react-router-dom';
import Banners from 'pages/Home/components/Banners';
import FAQ from 'components/FAQ';
import { IoLocationOutline } from 'react-icons/io5';
import { CITIES } from 'utils/enums/cities.enums';
import WeTooltip from 'components/WETooltip';
import ChatbotSearchContext from 'store/context/chatbot-search.context';
import FiltersEnum from 'utils/enums/filters.enum';
import { getFiltersFromStorage } from 'utils/helpers';
import moment from 'moment';

const DEFAULT_FILTERS = [
  FiltersEnum.SINGLE_FAMILY,
  FiltersEnum.TOWNHOMES,
  FiltersEnum.APARTMENT,
  FiltersEnum.CONDOMINIUM,
  FiltersEnum.VILLA,
  FiltersEnum.LUXURY_HOUSE,
  FiltersEnum.MANOR,
  FiltersEnum.THEMATIC,
];

const backgroundWeeklyVideos = [
  'https://dev.we.paxtecnologia.com.br/properties-galery/video_home_1.mp4',
  'https://dev.we.paxtecnologia.com.br/properties-galery/video_home_3.mp4',
  'https://dev.we.paxtecnologia.com.br/properties-galery/video_home_5.mp4',
  'https://dev.we.paxtecnologia.com.br/properties-galery/video_home_6.mp4',
];

const HomeLayout: React.FC = () => {
  const [filters, setFiltersHouse] = useState<string[]>([]);
  const [bookingParams, setBookingParams] = useState<Booking>();
  const [selectedCity, setSelectedCity] = useState<string>();
  const [initialPeriod, setInitialPeriod] = useState<any>(ChatbotSearchContext);
  const [backgroundVideo, setBackgroundVideo] = useState('');

  const { t } = useTranslation();
  const { navigateToHouseList, navigateToCondominiums } = useHouseListNavigation();
  const location = useLocation();
  const { getCurrentURLData, getCondominium } = useHouseListNavigation();
  const { setSections } = useContext(ChatbotSearchContext);

  const isHome = location.pathname === '/';

  useEffect(() => {
    const { checkIn, checkOut } = getCurrentURLData();

    if (checkIn != initialPeriod[0] || checkOut != initialPeriod[1]) {
      const dates = checkIn && checkOut ? [checkIn, checkOut] : [];
      setInitialPeriod(dates);
    }
  }, [location.search]);

  const handleFilter = (filtersValue: any[]) => {
    if (filters.length === filtersValue.length) return;
    setFiltersHouse(filtersValue);
  };

  const handleSearch = (searchParams: Booking) => {
    setSections([]);
    if (selectedCity) {
      return navigateToCondominiums(selectedCity, searchParams);
    }
    setBookingParams(searchParams);
    navigateToHouseList(searchParams);
  };

  const handleClick = (city: string) => {
    if (selectedCity === city) {
      return setSelectedCity(undefined);
    }
    setSelectedCity(city);
  };

  const selectedColor = (city: string): string => {
    if (selectedCity === city) {
      return '#0049a1';
    }

    return '#FF7400';
  };

  const getFilters = () => {
    try {
      const storageFilters = getFiltersFromStorage();
      if (storageFilters.length > 0) {
        return storageFilters;
      }
      return DEFAULT_FILTERS;
    } catch (error) {
      return DEFAULT_FILTERS;
    }
  };

  const calculateWeekVideoToShow = () => {
    const today = moment().date();
    const startOfMonth = moment().startOf('month');
    const firstDayOfWeek = startOfMonth.isoWeekday();
    const daysDifference = today + (firstDayOfWeek - 1);
    const weekOfMonth = Math.ceil(daysDifference / 7);

    // Get the video URL based on the week of the month
    setBackgroundVideo(backgroundWeeklyVideos[(weekOfMonth - 1) % backgroundWeeklyVideos?.length]);
  };

  useEffect(() => {
    calculateWeekVideoToShow();
    const condominium = getCondominium();
    if (condominium) {
      setSelectedCity(condominium);
    }
  }, []);

  return (
    <div>
      <div className={styles.mainPage}>
        {backgroundVideo && (
          <video className={styles.videoOpener} autoPlay loop muted preload="auto">
            <source src={backgroundVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        )}

        <div className={styles.layer}></div>
        <div className={styles.backgroundContainer}>
          <h1 className={styles.mainTitle}>{t('TITLE')}</h1>
          <SearchHouse
            buttonColor={selectedCity ? '#0049a1' : '#FF7400'}
            onSearch={handleSearch}
            initialPeriod={initialPeriod}
          />
          <div className={styles.options}>
            <h2 className={styles.subTitle}>{t('SUB_TITLE')}</h2>
            <div className={styles.condominiums}>
              <WeTooltip hoverText={t('TOOLTIP.ORLANDO')}>
                <div className={styles.item} onClick={() => handleClick(CITIES.ORLANDO)}>
                  <div>Orlando</div>
                  <IoLocationOutline size={20} color={selectedColor(CITIES.ORLANDO)} />
                </div>
              </WeTooltip>
              <WeTooltip hoverText={t('TOOLTIP.KISSIMMEE')}>
                <div className={styles.item} onClick={() => handleClick(CITIES.KISSIMEE)}>
                  <div>Kissimmee</div>
                  <IoLocationOutline size={20} color={selectedColor(CITIES.KISSIMEE)} />
                </div>
              </WeTooltip>
              <WeTooltip hoverText={t('TOOLTIP.DAVENPORT')}>
                <div className={styles.item} onClick={() => handleClick(CITIES.DAVENPORT)}>
                  <div>Davenport</div>
                  <IoLocationOutline size={20} color={selectedColor(CITIES.DAVENPORT)} />
                </div>
              </WeTooltip>
              <WeTooltip hoverText={t('TOOLTIP.CLERMONT')}>
                <div className={styles.item} onClick={() => handleClick(CITIES.CLERMONT)}>
                  <div>Clermont</div>
                  <IoLocationOutline size={20} color={selectedColor(CITIES.CLERMONT)} />
                </div>
              </WeTooltip>
            </div>
          </div>
          <FilterHome onChangeFilters={handleFilter} defaultFilters={getFilters()} />
          <div className={styles.results}>
            <Outlet context={{ filters, bookingParams }} />
          </div>
          <div className={styles.pageContainer}>
            {/* <WEIconDivider /> */}

            <ShowoffExperiences
              title={t('HOME.TITLES.TITLE_TRANSFORM_YOUR_STAY')}
              description={t('HOME.TITLES.TITLE_TRANSFORM_YOUR_STAY_DESCRIPTION')}
            />

            {/* <WEIconDivider iconType="BEACH" /> */}
          </div>
        </div>
      </div>
      {isHome && (
        <div className={styles.secondaryPage}>
          {/* <Partners /> */}
          <Banners />
          <FAQ title={t('FAQ.TITLE')} />
        </div>
      )}
      <Footer />
      <Copyright color={COLORS.neutral.white} backgroundColor={COLORS.primary.cobalt} />
    </div>
  );
};

type ContextType = { filters: string[]; bookingParams: Booking };

export const useSearchData = () => {
  return useOutletContext<ContextType>();
};

export default HomeLayout;
