import { isValidCPF, isValidPhoneNumber } from 'utils/validator';
import Yup from 'utils/yup';

export const validateEmbraerUser = Yup.object({
  name: Yup.string().required(),
  email: Yup.string().email().required(),
  cel: Yup.number()
    .required()
    .test('is-valid-phone', 'Celular inválido', (value) => isValidPhoneNumber(value)),
  cpf: Yup.string()
    .required()
    .test('is-valid-cnpj', 'CPF inválido', (value: any) => isValidCPF(value)),
  cep: Yup.string().required(),
  number: Yup.number().required(),
  address: Yup.string().required(),
  city: Yup.string().required(),
  country: Yup.string().required(),
  complement: Yup.string().notRequired(),
  state: Yup.string().required(),
  associationId: Yup.string().required(),
  password: Yup.string().required(),
  confirmPassword: Yup.string()
    .required()
    .oneOf([Yup.ref('password'), null], 'Senhas não conferem'),
});
