import React from 'react';
import { BaseProps } from './BaseProps';

const SingleFamilySVG: React.FC<BaseProps> = ({ color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="49" height="49" viewBox="0 0 49 49">
      <g
        id="Grupo_de_máscara_250"
        data-name="Grupo de máscara 250"
        transform="translate(-1306 -660)"
        clipPath="url(#clip-path)"
      >
        <g id="house_6487077" transform="translate(1306 661.589)">
          <path
            id="Caminho_7697"
            data-name="Caminho 7697"
            d="M22.468,38.823h3.952a.79.79,0,0,0,.79-.79V31.71a.79.79,0,0,0-.79-.79H22.468a.79.79,0,0,0-.79.79v6.323A.79.79,0,0,0,22.468,38.823Zm.79-6.323h2.371v4.742H23.258Z"
            transform="translate(-0.339 -1.727)"
            fill={color}
          />
          <path
            id="Caminho_7698"
            data-name="Caminho 7698"
            d="M41.211,30.968H33.264a.79.79,0,0,0-.79.79v6.323a.79.79,0,0,0,.79.79h7.947a.79.79,0,0,0,.79-.79V31.758A.79.79,0,0,0,41.211,30.968ZM36.425,37.29H34.054V32.548h2.371Zm4,0H38.049V32.548H40.42Z"
            transform="translate(-0.114 -1.726)"
            fill={color}
          />
          <path
            id="Caminho_7699"
            data-name="Caminho 7699"
            d="M6.21,38.823h3.952a.79.79,0,0,0,.79-.79V31.71a.79.79,0,0,0-.79-.79H6.21a.79.79,0,0,0-.79.79v6.323A.79.79,0,0,0,6.21,38.823ZM7,32.5H9.371v4.742H7Z"
            transform="translate(-0.677 -1.727)"
            fill={color}
          />
          <circle
            id="Elipse_450"
            data-name="Elipse 450"
            cx="0.79"
            cy="0.79"
            r="0.79"
            transform="translate(16.597 35.515)"
            fill={color}
          />
          <path
            id="Caminho_7700"
            data-name="Caminho 7700"
            d="M48.984,44.193H47.4V22.871a.79.79,0,0,0-.79-.79H44.242V19.71h2.371a.79.79,0,0,0,.79-.79V16.548a.79.79,0,0,0-.79-.79H27.645V10.226h2.371a.79.79,0,0,0,.707-1.144L27.562,2.759a.791.791,0,0,0-.707-.437H6.306a.791.791,0,0,0-.707.437L2.438,9.082a.79.79,0,0,0,.707,1.144H5.516V25.271L1.1,28.507a.79.79,0,0,0,.467,1.428H3.145V44.193H1.565a.79.79,0,0,0-.79.79v2.371a.79.79,0,0,0,.79.79H48.984a.79.79,0,0,0,.79-.79V44.983a.79.79,0,0,0-.79-.79ZM45.823,23.661V44.193H30.016V29.934H31.6a.79.79,0,0,0,.467-1.428l-4.419-3.236V23.661ZM27.645,22.081V19.71h2.371v2.371Zm3.952,0V19.71h1.581v2.371Zm3.161,0V19.71h1.581v2.371Zm3.161,0V19.71H39.5v2.371Zm3.161,0V19.71h1.581v2.371Zm4.742-4.742v.79H27.645v-.79ZM4.424,8.645,6.794,3.9H26.367l2.371,4.742Zm21.641,1.581V24.113l-9.017-6.605a.792.792,0,0,0-.935,0L7.1,24.113V10.226Zm-9.484,8.9,12.6,9.229H3.981Zm3.952,12.39h-7.9a.79.79,0,0,0-.79.79V44.16H4.726V29.934h23.71V44.16H21.323V32.305A.79.79,0,0,0,20.532,31.515Zm-.79,1.581V44.16H13.419V33.1ZM48.194,46.564H2.355v-.79H48.194Z"
            transform="translate(-0.774 -2.323)"
            fill={color}
          />
          <path
            id="Caminho_7701"
            data-name="Caminho 7701"
            d="M24.323,15.565V12.4a.79.79,0,0,0-.79-.79H9.306a.79.79,0,0,0-.79.79v3.161a.79.79,0,0,0,.79.79H23.532A.79.79,0,0,0,24.323,15.565Zm-1.581-.79H10.1V13.194H22.742Z"
            transform="translate(-0.613 -2.129)"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
};

export default SingleFamilySVG;
