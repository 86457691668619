import { HouseResume } from 'models/application/HouseResume.model';
import { HouseSearchResponse } from 'models/contracts/response/HouseSearch.response';
import { axiosInstances } from 'services/api';
import { getHouseListFilters } from 'utils/helpers';

export const getHousesByCondominium = async (
  filters: string[],
  checkIn: string,
  checkOut: string,
  condominium: string,
): Promise<{ title: string; data: HouseResume[] }[]> => {
  const { data } = await axiosInstances.base.instance.post('service/v1/house/availability/condominium', {
    filters: {
      ...getHouseListFilters(filters),
      nearDisney: true, // default
      townhomes: true, // default
      singleFamily: true,
      thematic: true, // default
      cinema: true, // default
      amazingPools: true, //default
      firstTimeOrlando: true, // default
      checkin: checkIn,
      checkout: checkOut,
    },
    condominium,
  });

  const sections = Object.keys(data).sort((a, b) => {
    const aIndex = filters.indexOf(a);
    const bIndex = filters.indexOf(b);

    // Se ambos estão em filters, compara as posições e deixa o último clicado em primeiro
    if (aIndex !== -1 && bIndex !== -1) {
      return bIndex - aIndex;
    }

    // Se apenas A está em filters, coloca A antes
    if (aIndex !== -1) {
      return -1;
    }

    // Se apenas B está em filters, coloca B antes
    if (bIndex !== -1) {
      return 1;
    }

    // Se nenhum está em filters, mantém a ordem padrão
    return 0;
  });

  const result = sections.map((section) => {
    const houses: any = data[section];

    return {
      title: section,
      data: houses.map(
        (house: HouseSearchResponse) =>
          new HouseResume({
            id: house.houseId,
            name: house.houseName,
            nameEs: house.houseNameEs,
            namePt: house.houseNamePt,
            nameUs: house.houseNameUs,
            mainPicture: house.mainPicture,
            price: {
              real: house.startPriceReal,
              dollar: house.startPriceDollar,
            },
            maxOccupancy: house.maxOccupancy,
            bedroomAmount: house.roomAmount,
            bathroomAmount: house.bathroomAmount,
            amenities: house.amenities.map((amenitie) => ({
              name: amenitie,
            })),
            coordinates: {
              lat: house.latitude,
              long: house.longitude,
            },
          }),
      ) as HouseResume[],
    };
  });

  return result.filter((el: any) => el.data.length > 0);
};
