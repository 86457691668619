import React from 'react';
import { BaseProps } from './BaseProps';

const ThematicSVG: React.FC<BaseProps> = ({ color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45">
      <g
        id="Grupo_de_máscara_244"
        data-name="Grupo de máscara 244"
        transform="translate(-814 -660)"
        clipPath="url(#clip-path)"
      >
        <g id="disneyland_404792" transform="translate(814.137 660)">
          <g id="Grupo_3264" data-name="Grupo 3264" transform="translate(0 20.754)">
            <g id="Grupo_3263" data-name="Grupo 3263">
              <path
                id="Caminho_7661"
                data-name="Caminho 7661"
                d="M43.46,22.137v1.177H42.047V22.137H40.635v1.177H39.223V22.137H37.81v2.589h.942V35.555H33.573V31.318h.942V28.728H33.1v1.177H31.69V28.728H30.277v1.177H28.865V28.728H27.453v2.589h.942V32.73H16.624V31.318h.942V28.728H16.153v1.177H14.741V28.728H13.328v1.177H11.916V28.728H10.5v2.589h.942v4.237H6.266V24.727h.942V22.137H5.8v1.177H4.383V22.137H2.971v1.177H1.558V22.137H.146v2.589h.942V46.384H43.931V24.727h.942V22.137ZM4.854,35.555v9.416H2.5V28.493H4.854Zm0-8.475H2.5V24.727H4.854Zm6.591,17.891H6.266v-8h5.179v8ZM15.212,32.73V44.971H12.858V31.318h2.354ZM25.569,44.971h-6.12V40.028a3.06,3.06,0,0,1,6.121,0v4.943Zm2.825,0H26.982V40.028a4.473,4.473,0,1,0-8.945,0v4.943H16.624V34.143h11.77V44.971Zm3.766-9.416v9.416H29.807V31.318h2.354Zm6.591,9.416H33.573v-8h5.179Zm3.766,0H40.164V28.493h2.354Zm0-17.891H40.164V24.727h2.354Z"
                transform="translate(-0.146 -22.137)"
                fill={color}
              />
            </g>
          </g>
          <g id="Grupo_3266" data-name="Grupo 3266" transform="translate(12.476 3.766)">
            <g id="Grupo_3265" data-name="Grupo 3265">
              <path
                id="Caminho_7662"
                data-name="Caminho 7662"
                d="M24.047,11.353V9.273l7.177-2.392L22.635,4.017v7.336L13.454,23.594H15.1v3.06h1.412v-3.06H30.167v3.06H31.58v-3.06h1.648Zm0-5.376,2.71.9-2.71.9Zm-7.768,16.2,7.062-9.416L30.4,22.182Z"
                transform="translate(-13.454 -4.017)"
                fill={color}
              />
            </g>
          </g>
          <g id="Grupo_3268" data-name="Grupo 3268" transform="translate(9.416)">
            <g id="Grupo_3267" data-name="Grupo 3267">
              <path
                id="Caminho_7663"
                data-name="Caminho 7663"
                d="M14.427,5.255,21.6,2.863,13.015,0V7.426L10.19,14.017V26.4H11.6V14.869h4.237v1.177h1.412V14.017L14.427,7.426V5.255Zm0-3.3,2.71.9-2.71.9Zm1.048,11.5H11.967l1.754-4.092Z"
                transform="translate(-10.19)"
                fill={color}
              />
            </g>
          </g>
          <g id="Grupo_3270" data-name="Grupo 3270" transform="translate(28.248)">
            <g id="Grupo_3269" data-name="Grupo 3269">
              <path
                id="Caminho_7664"
                data-name="Caminho 7664"
                d="M34.515,5.255l7.177-2.392L33.1,0V7.426l-2.825,6.591v2.028H31.69V14.869h4.237V26.4h1.412V14.017L34.515,7.426Zm0-3.3,2.71.9-2.71.9Zm1.048,11.5H32.055l1.754-4.092Z"
                transform="translate(-30.277)"
                fill={color}
              />
            </g>
          </g>
          <g id="Grupo_3272" data-name="Grupo 3272" transform="translate(23.022 24.756)">
            <g id="Grupo_3271" data-name="Grupo 3271">
              <rect id="Retângulo_4205" data-name="Retângulo 4205" width="1.412" height="1.412" fill={color} />
            </g>
          </g>
          <g id="Grupo_3274" data-name="Grupo 3274" transform="translate(25.847 24.756)">
            <g id="Grupo_3273" data-name="Grupo 3273">
              <rect id="Retângulo_4206" data-name="Retângulo 4206" width="1.412" height="1.412" fill={color} />
            </g>
          </g>
          <g id="Grupo_3276" data-name="Grupo 3276" transform="translate(20.197 24.756)">
            <g id="Grupo_3275" data-name="Grupo 3275">
              <rect id="Retângulo_4207" data-name="Retângulo 4207" width="1.412" height="1.412" fill={color} />
            </g>
          </g>
          <g id="Grupo_3278" data-name="Grupo 3278" transform="translate(17.372 24.756)">
            <g id="Grupo_3277" data-name="Grupo 3277">
              <rect id="Retângulo_4208" data-name="Retângulo 4208" width="1.412" height="1.412" fill={color} />
            </g>
          </g>
          <g id="Grupo_3280" data-name="Grupo 3280" transform="translate(20.197 27.581)">
            <g id="Grupo_3279" data-name="Grupo 3279">
              <rect id="Retângulo_4209" data-name="Retângulo 4209" width="1.412" height="1.412" fill={color} />
            </g>
          </g>
          <g id="Grupo_3282" data-name="Grupo 3282" transform="translate(23.022 27.581)">
            <g id="Grupo_3281" data-name="Grupo 3281">
              <rect id="Retângulo_4210" data-name="Retângulo 4210" width="1.412" height="1.412" fill={color} />
            </g>
          </g>
          <g id="Grupo_3284" data-name="Grupo 3284" transform="translate(12.241 18.871)">
            <g id="Grupo_3283" data-name="Grupo 3283">
              <rect id="Retângulo_4211" data-name="Retângulo 4211" width="1.412" height="1.412" fill={color} />
            </g>
          </g>
          <g id="Grupo_3286" data-name="Grupo 3286" transform="translate(12.241 16.046)">
            <g id="Grupo_3285" data-name="Grupo 3285">
              <rect id="Retângulo_4212" data-name="Retângulo 4212" width="1.412" height="1.412" fill={color} />
            </g>
          </g>
          <g id="Grupo_3288" data-name="Grupo 3288" transform="translate(31.073 16.046)">
            <g id="Grupo_3287" data-name="Grupo 3287">
              <rect id="Retângulo_4213" data-name="Retângulo 4213" width="1.412" height="1.412" fill={color} />
            </g>
          </g>
          <g id="Grupo_3290" data-name="Grupo 3290" transform="translate(31.073 18.871)">
            <g id="Grupo_3289" data-name="Grupo 3289">
              <rect id="Retângulo_4214" data-name="Retângulo 4214" width="1.412" height="1.412" fill={color} />
            </g>
          </g>
          <g id="Grupo_3292" data-name="Grupo 3292" transform="translate(7.533 36.761)">
            <g id="Grupo_3291" data-name="Grupo 3291">
              <rect id="Retângulo_4215" data-name="Retângulo 4215" width="1.412" height="1.412" fill={color} />
            </g>
          </g>
          <g id="Grupo_3294" data-name="Grupo 3294" transform="translate(7.533 39.586)">
            <g id="Grupo_3293" data-name="Grupo 3293">
              <rect id="Retângulo_4216" data-name="Retângulo 4216" width="1.412" height="1.412" fill={color} />
            </g>
          </g>
          <g id="Grupo_3296" data-name="Grupo 3296" transform="translate(35.781 39.586)">
            <g id="Grupo_3295" data-name="Grupo 3295">
              <rect id="Retângulo_4217" data-name="Retângulo 4217" width="1.412" height="1.412" fill={color} />
            </g>
          </g>
          <g id="Grupo_3298" data-name="Grupo 3298" transform="translate(35.781 36.761)">
            <g id="Grupo_3297" data-name="Grupo 3297">
              <rect id="Retângulo_4218" data-name="Retângulo 4218" width="1.412" height="1.412" fill={color} />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default ThematicSVG;
