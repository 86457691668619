import { useEffect } from 'react';
import { I18nextProvider } from 'react-i18next';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import i18next from 'i18next';
import i18n from 'languages';
import SEO from 'components/SEO';

import { Outlet } from 'react-router-dom';

import { ToastProvider } from 'store/context/useToast';
import { UserProvider } from 'store/context/user.context';

import { ChatbotProvider } from 'store/context/chatbot-search.context';
import WEChat from 'components/WEChat';

const queryClient = new QueryClient();

const ProviderLayout = () => {
  // TODO: com essa linha de código é possivel remover
  //todas as outras linhas de código que olham pra diferentes siglas
  const stdLanguage = () => {
    if (navigator.language.includes('pt')) {
      return 'pt-BR';
    } else if (navigator.language.includes('en')) {
      return 'en';
    } else {
      return navigator.language;
    }
  };
  useEffect(() => {
    const defaultLanguage = localStorage.getItem('idiom');
    i18next.changeLanguage(defaultLanguage ?? stdLanguage());
  }, []);

  return (
    <>
      <I18nextProvider i18n={i18n}>
        <QueryClientProvider client={queryClient}>
          <ToastProvider>
            <UserProvider>
              <ChatbotProvider>
                <WEChat />
                <SEO />
                <Outlet />
              </ChatbotProvider>
            </UserProvider>
          </ToastProvider>
        </QueryClientProvider>
      </I18nextProvider>
    </>
  );
};

export default ProviderLayout;
