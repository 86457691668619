import React from 'react';
import { BaseProps } from './BaseProps';

const CinemaSVG: React.FC<BaseProps> = ({ color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
      <g
        id="Grupo_de_máscara_255"
        data-name="Grupo de máscara 255"
        transform="translate(-892 -642.42)"
        clipPath="url(#clip-path)"
      >
        <g id="movie_16457013" transform="translate(892 646.727)">
          <path
            id="Caminho_7727"
            data-name="Caminho 7727"
            d="M19.984,12.888a.788.788,0,0,0-1.234.649V22.2a.788.788,0,0,0,1.234.649l6.3-4.333a.788.788,0,0,0,0-1.3Zm.342,7.817v-5.67l4.124,2.835Z"
            transform="translate(0.946 -4.084)"
            fill={color}
          />
          <path
            id="Caminho_7728"
            data-name="Caminho 7728"
            d="M13.916,37.37a.788.788,0,0,0,.788-.788v-1.97a2.3,2.3,0,0,0-2.22-2.363H7.47a2.3,2.3,0,0,0-2.22,2.363v1.969a.788.788,0,1,0,1.576,0V34.613a.728.728,0,0,1,.645-.788h5.013a.728.728,0,0,1,.645.788v1.969a.788.788,0,0,0,.788.788Z"
            transform="translate(0.266 -3.101)"
            fill={color}
          />
          <path
            id="Caminho_7729"
            data-name="Caminho 7729"
            d="M24.415,37.37a.788.788,0,0,0,.788-.788v-1.97a2.3,2.3,0,0,0-2.22-2.363H17.97a2.3,2.3,0,0,0-2.22,2.363v1.969a.788.788,0,1,0,1.576,0V34.613a.728.728,0,0,1,.645-.788h5.013a.728.728,0,0,1,.645.788v1.969a.788.788,0,0,0,.788.788Z"
            transform="translate(0.795 -3.101)"
            fill={color}
          />
          <path
            id="Caminho_7730"
            data-name="Caminho 7730"
            d="M7.233,38.624H2.22A2.3,2.3,0,0,0,0,40.988v1.969a.788.788,0,0,0,1.576,0V40.988A.728.728,0,0,1,2.22,40.2H7.233a.728.728,0,0,1,.645.788v1.969a.788.788,0,0,0,1.576,0V40.988A2.3,2.3,0,0,0,7.233,38.624Z"
            transform="translate(0.001 -2.78)"
            fill={color}
          />
          <path
            id="Caminho_7731"
            data-name="Caminho 7731"
            d="M17.733,38.624H12.72a2.3,2.3,0,0,0-2.22,2.363v1.969a.788.788,0,0,0,1.576,0V40.988a.728.728,0,0,1,.645-.788h5.013a.728.728,0,0,1,.645.788v1.969a.788.788,0,0,0,1.576,0V40.988a2.3,2.3,0,0,0-2.22-2.363Z"
            transform="translate(0.53 -2.78)"
            fill={color}
          />
          <path
            id="Caminho_7732"
            data-name="Caminho 7732"
            d="M28.233,38.624H23.22A2.3,2.3,0,0,0,21,40.988v1.969a.788.788,0,0,0,1.576,0V40.988a.728.728,0,0,1,.645-.788h5.013a.728.728,0,0,1,.645.788v1.969a.788.788,0,0,0,1.576,0V40.988A2.3,2.3,0,0,0,28.233,38.624Z"
            transform="translate(1.06 -2.78)"
            fill={color}
          />
          <path
            id="Caminho_7733"
            data-name="Caminho 7733"
            d="M48.982,26.721a2.36,2.36,0,0,0-1.092-2c0-.009.008-.017.011-.025a.791.791,0,0,0,.04-.2,2.485,2.485,0,0,0-1.565-3.019q.008-.1.008-.2a2.474,2.474,0,0,0-2.437-2.5c-.052,0-.1,0-.157.005a2.56,2.56,0,0,0-2.339-1.535,2.512,2.512,0,0,0-1.333.386,2.556,2.556,0,0,0-2.711.028,2.5,2.5,0,0,0-1.378-.415,2.559,2.559,0,0,0-2.339,1.535c-.052,0-.1-.005-.157-.005a2.474,2.474,0,0,0-2.437,2.5q0,.1.008.2a2.5,2.5,0,0,0-1.659,2.373,2.533,2.533,0,0,0,.152.863,2.375,2.375,0,0,0,.115,4.075l2.63,14.635a1.722,1.722,0,0,0,1.647,1.4h9.5a1.724,1.724,0,0,0,1.648-1.4l2.63-14.636a2.356,2.356,0,0,0,1.214-2.07Zm-17.1-3.8a1.321,1.321,0,0,0,.507-.021.788.788,0,0,0,.415-1.136.961.961,0,0,1-.134-.485.862.862,0,0,1,1.223-.836.788.788,0,0,0,1.137-.637,1.032,1.032,0,0,1,1-.984.977.977,0,0,1,.762.385.815.815,0,0,0,1.232,0,.947.947,0,0,1,1.5-.032.788.788,0,0,0,1.192,0,.967.967,0,0,1,.735-.352,1.032,1.032,0,0,1,1,.984.788.788,0,0,0,1.137.637.862.862,0,0,1,1.222.837.962.962,0,0,1-.133.485.785.785,0,0,0,.045.835.809.809,0,0,0,.782.334.734.734,0,0,1,.093-.012.9.9,0,0,1,.862.928.683.683,0,0,1-.7.745.765.765,0,0,1-.268-.056.788.788,0,0,0-.65,1.435,2.236,2.236,0,0,0,.924.2,2.269,2.269,0,0,0,.981-.221.774.774,0,0,1-.052,1.537H30.786a.743.743,0,0,1-.711-.77.752.752,0,0,1,.6-.758,2.3,2.3,0,0,0,.794.245,2.275,2.275,0,0,0,1.015-.114.788.788,0,0,0-.527-1.485.691.691,0,0,1-.309.034.754.754,0,0,1-.63-.79.9.9,0,0,1,.863-.928Zm2.01,20.224L31.364,29.067h6.589V43.247H33.994A.161.161,0,0,1,33.894,43.149Zm9.694,0a.163.163,0,0,1-.1.1H39.529V29.067h6.589Z"
            transform="translate(1.438 -3.857)"
            fill={color}
          />
          <path
            id="Caminho_7734"
            data-name="Caminho 7734"
            d="M35.853,22.8a2.218,2.218,0,0,0,.9.19,2.069,2.069,0,0,0,.787-.152.788.788,0,1,0-.6-1.456.626.626,0,0,1-.442-.021.4.4,0,0,1-.047-.024.788.788,0,0,0-1.57.021,1.494,1.494,0,0,0,.976,1.442Z"
            transform="translate(1.759 -3.687)"
            fill={color}
          />
          <path
            id="Caminho_7735"
            data-name="Caminho 7735"
            d="M35.364,25.475a.7.7,0,0,1,.411-.084l.035,0a.789.789,0,0,0,1.461-.3.846.846,0,0,0-.023-.306,1.421,1.421,0,0,0-1.307-.965,2.241,2.241,0,0,0-1.481.36.788.788,0,1,0,.9,1.291Z"
            transform="translate(1.721 -3.526)"
            fill={color}
          />
          <path
            id="Caminho_7736"
            data-name="Caminho 7736"
            d="M40.614,25.85a.7.7,0,0,1,.411-.084l.035,0a.788.788,0,0,0,1.438-.607,1.421,1.421,0,0,0-1.307-.965,2.24,2.24,0,0,0-1.481.36.788.788,0,1,0,.9,1.291Z"
            transform="translate(1.986 -3.507)"
            fill={color}
          />
          <path
            id="Caminho_7737"
            data-name="Caminho 7737"
            d="M40.249,22.716a2.289,2.289,0,0,0,1.332.478,1.563,1.563,0,0,0,.316-.031.788.788,0,0,0-.312-1.544.76.76,0,0,1-.4-.167.951.951,0,0,1-.24-.254.788.788,0,0,0-.787-.761h-.027a.788.788,0,0,0-.76.814,2.006,2.006,0,0,0,.875,1.464Z"
            transform="translate(1.986 -3.696)"
            fill={color}
          />
          <path
            id="Caminho_7738"
            data-name="Caminho 7738"
            d="M2.363,32.073h27.66L29.74,30.5H2.363a.789.789,0,0,1-.788-.788V6.863a.789.789,0,0,1,.788-.788H44.9a.789.789,0,0,1,.788.788V17.425a4.175,4.175,0,0,1,.461.5,3.943,3.943,0,0,1,1.115.4V6.863A2.366,2.366,0,0,0,44.9,4.5H2.363A2.366,2.366,0,0,0,0,6.863V29.709a2.366,2.366,0,0,0,2.363,2.363Z"
            transform="translate(0.001 -4.5)"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
};

export default CinemaSVG;
