import i18next from 'i18next';
import i18n from 'i18next';

export const Languages = {
  EN_US: 'en',
  EN_USA: 'en-US',
  PT_BR: 'pt-BR',
  ES: 'es',
  PT_PT: 'pt-PT',
  PT: 'pt',
};

export const addTranslation = (language: string, translation: object) => {
  try {
    i18n.addResourceBundle(language, 'translation', {
      BACKEND: {
        ...translation,
      },
    });
  } catch (err) {
    console.warn(err);
  }
};

export const getCurrency = () => {
  return i18next.language === Languages.PT_BR ? 'R$ ' : '$ ';
};
