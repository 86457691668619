export default {
  CONFIRMED_CHECKOUT: {
    RESERVATION_CONFIRMED: 'Confirmed reservation',
    CONGRATULATIONS: 'Congratulations! Your reservation has been successfully confirmed.',
    UNFORGETTABLE_STAY: 'We can’t wait to make your stay unforgettable.',
    RESERVATION_IDENTIFIER: 'Reservation identifier: ',
    CHECKIN_DATE: 'Check-in date: ',
    CHECKOUT_DATE: 'Check-out date: ',
    BACK_TO_HOME: 'Back to home screen',
    ADDRESS: 'Address: ',
  },
  SUCCESS_CHECKOUT: {
    TITLE: 'Payment error',
    MESSAGE: 'To view all the details click the button below',
    BUTTON: 'See details',
  },
  FAIL_CHECKOUT: {
    TITLE: 'Error in reservation',
    MESSAGE_1: 'We were unable to process your payment',
    MESSAGE_2: 'Please contact your travel agent',
    BUTTON: 'Back to home screen',
  },
  CARD: {
    DAILYS: 'Dailys',
    TAXES: 'Taxes',
    SERVICE_FEE: 'Cleaning fee',
    PLATFORM_FEE: 'Service fee',
    LONG_STAY_DISCOUNT: 'Long stay discount',
    PARTNER_DISCOUNT: 'Partner Discount',
  },
  RESPONSE: {
    PRICE_RESERVE_ERROR: `The property is not available for the selected period. Please select another period.`,
    PRICE_RESERVE_ERROR_GENERIC: 'Error when searching for reservation price. Try again later.',
    RESERVE_ERROR: 'Error when making the reservation. Try again later.',
    PRICE_RESERVE_ERROR_CONFLICT: 'Error when searching for price, there is already a reservation for this period.',
  },
};
