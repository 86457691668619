import Condominium from 'models/application/Condominium.model';
import styles from './style.module.scss';
import { ImageNotAvailable, VistaCay } from 'assets';
import { useMemo, useRef } from 'react';

type CardCondominiumProps = {
  condominium: Condominium;
  onClick: (condominium: Condominium) => void;
  staticCard?: boolean;
  video?: boolean;
};

const CardCondominium: React.FC<CardCondominiumProps> = ({ condominium, onClick, staticCard, video = false }) => {
  const videoRef = useRef(null) as any;

  const handleClick = () => {
    onClick(condominium);
  };

  const styleConfig = useMemo(() => {
    return {
      container: !staticCard ? styles.containerScale : null,
      visibility: staticCard ? styles.alwaysVisible : styles.hiddenContainer,
      imageHeight: staticCard ? '162px' : '192px',
    };
  }, [staticCard]);

  const handleMouseOver = () => {
    if (!videoRef?.current) return;
    videoRef?.current?.play(); // Reproduz o vídeo quando o mouse passa sobre ele
  };

  const handleMouseOut = () => {
    if (!videoRef?.current) return;
    videoRef?.current?.pause(); // Pausa o vídeo quando o mouse sai
  };

  return (
    <div
      className={`${styles.container} ${styleConfig.container}`}
      onClick={handleClick}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      <div className={styles.imageContainer}>
        <div className={styles.layer}></div>
        <h6>{condominium?.name?.toLowerCase()}</h6>
        {video ? (
          <video ref={videoRef} className={styles.imageBackground} height={styleConfig.imageHeight} loop muted>
            <source src={VistaCay} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        ) : (
          <img
            className={styles.imageBackground}
            height={styleConfig.imageHeight}
            src={condominium?.image ? condominium.image : ImageNotAvailable}
            alt="House background"
          />
        )}
      </div>
      {condominium.getSubtitle() && (
        <div className={styleConfig.visibility}>
          <div className={styles.infos}>
            <div className={styles.itens}>{condominium.getSubtitle() ?? ''}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CardCondominium;
