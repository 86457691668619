import React from 'react';
import { BaseProps } from './BaseProps';

const CondoSVG: React.FC<BaseProps> = ({ color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
      <g
        id="Grupo_de_máscara_241"
        data-name="Grupo de máscara 241"
        transform="translate(-568 -660)"
        clipPath="url(#clip-path)"
      >
        <g id="Layer_x0020_1" transform="translate(568 660)">
          <g id="_365816544">
            <g id="Grupo_3238" data-name="Grupo 3238">
              <path
                id="Caminho_7623"
                data-name="Caminho 7623"
                d="M21.989,47.522H.562A.564.564,0,0,1,0,46.96V44.654a.564.564,0,0,1,.562-.562H21.989a.564.564,0,0,1,.562.562V46.96A.564.564,0,0,1,21.989,47.522ZM1.125,46.4h20.3V45.216H1.125Z"
                fill={color}
                fillRule="evenodd"
              />
            </g>
            <g id="Grupo_3239" data-name="Grupo 3239">
              <path
                id="Caminho_7624"
                data-name="Caminho 7624"
                d="M47.438,47.522H26.011a.583.583,0,0,1-.562-.562V44.654a.564.564,0,0,1,.562-.562H47.438a.564.564,0,0,1,.562.562V46.96A.564.564,0,0,1,47.438,47.522ZM26.573,46.4h20.3V45.216h-20.3Z"
                fill={color}
                fillRule="evenodd"
              />
            </g>
            <g id="Grupo_3240" data-name="Grupo 3240">
              <path
                id="Caminho_7625"
                data-name="Caminho 7625"
                d="M43.782,45.216h-3.29a.564.564,0,0,1-.562-.562V34.728a.564.564,0,0,1,.562-.562.515.515,0,0,1,.506.366h2.784a.564.564,0,0,1,.562.562v9.561A.547.547,0,0,1,43.782,45.216Zm-2.728-1.125h2.193V35.656H41.054Zm2.728-28.485H40.858a.564.564,0,0,1-.562-.562V10.573a.511.511,0,0,1,.225-.45.5.5,0,0,1,.394-.112h2.84a.472.472,0,0,1,.366.112.477.477,0,0,1,.253.45v4.471A.589.589,0,0,1,43.782,15.606ZM41.42,14.482h1.8V11.135h-1.8Z"
                fill={color}
                fillRule="evenodd"
              />
            </g>
            <g id="Grupo_3241" data-name="Grupo 3241">
              <path
                id="Caminho_7626"
                data-name="Caminho 7626"
                d="M43.782,35.656H40.858a.936.936,0,0,1-.928-.928V15.409a.936.936,0,0,1,.928-.928h2.924a.918.918,0,0,1,.928.928V34.728A.9.9,0,0,1,43.782,35.656Zm-2.728-1.125h2.559V15.606H41.054Z"
                fill={color}
                fillRule="evenodd"
              />
            </g>
            <g id="Grupo_3242" data-name="Grupo 3242">
              <path
                id="Caminho_7627"
                data-name="Caminho 7627"
                d="M40.492,42.938H38.074a.564.564,0,0,1-.562-.562V17.462a.564.564,0,0,1,.562-.562h1.209a1.782,1.782,0,0,1,1.772,1.772v23.7A.564.564,0,0,1,40.492,42.938Zm-1.856-1.125H39.93V18.671a.654.654,0,0,0-.647-.647h-.647Z"
                fill={color}
                fillRule="evenodd"
              />
            </g>
            <g id="Grupo_3243" data-name="Grupo 3243">
              <path
                id="Caminho_7628"
                data-name="Caminho 7628"
                d="M38.074,42.938H35.656a.564.564,0,0,1-.562-.562V15.859a.564.564,0,0,1,.562-.562h1.209a1.764,1.764,0,0,1,1.772,1.772V42.376A.564.564,0,0,1,38.074,42.938Zm-1.856-1.125h1.293V17.069a.636.636,0,0,0-.647-.647h-.647Z"
                fill={color}
                fillRule="evenodd"
              />
            </g>
            <g id="Grupo_3244" data-name="Grupo 3244">
              <path
                id="Caminho_7629"
                data-name="Caminho 7629"
                d="M35.656,42.938H33.237a.564.564,0,0,1-.562-.562V14.2a.564.564,0,0,1,.562-.562h1.209a1.764,1.764,0,0,1,1.772,1.772V42.376A.564.564,0,0,1,35.656,42.938ZM33.8,41.814h1.293v-26.4a.636.636,0,0,0-.647-.647H33.8Z"
                fill={color}
                fillRule="evenodd"
              />
            </g>
            <g id="Grupo_3245" data-name="Grupo 3245">
              <path
                id="Caminho_7630"
                data-name="Caminho 7630"
                d="M33.237,42.938h-2.39a.564.564,0,0,1-.562-.562V12.654a.564.564,0,0,1,.562-.562h1.181A1.764,1.764,0,0,1,33.8,13.863V42.376A.564.564,0,0,1,33.237,42.938Zm-1.828-1.125h1.265V13.863a.636.636,0,0,0-.647-.647h-.619Z"
                fill={color}
                fillRule="evenodd"
              />
            </g>
            <g id="Grupo_3246" data-name="Grupo 3246">
              <path
                id="Caminho_7631"
                data-name="Caminho 7631"
                d="M30.847,42.938H28.429a.564.564,0,0,1-.562-.562V10.91a.564.564,0,0,1,.562-.562h1.209a1.782,1.782,0,0,1,1.772,1.772V42.376A.583.583,0,0,1,30.847,42.938Zm-1.856-1.125h1.293V12.12a.654.654,0,0,0-.647-.647h-.647Z"
                fill={color}
                fillRule="evenodd"
              />
            </g>
            <g id="Grupo_3247" data-name="Grupo 3247">
              <path
                id="Caminho_7632"
                data-name="Caminho 7632"
                d="M28.429,42.938H26.011a.564.564,0,0,1-.562-.562V9.223a.564.564,0,0,1,.562-.562H27.22a1.782,1.782,0,0,1,1.772,1.772V42.376A.564.564,0,0,1,28.429,42.938Zm-1.856-1.125h1.293V10.432a.674.674,0,0,0-.647-.647h-.647Z"
                fill={color}
                fillRule="evenodd"
              />
            </g>
            <g id="Grupo_3248" data-name="Grupo 3248">
              <path
                id="Caminho_7633"
                data-name="Caminho 7633"
                d="M7.508,45.216H4.19a.564.564,0,0,1-.562-.562V35.093a.564.564,0,0,1,.562-.562H6.974a.55.55,0,0,1,.534-.366.564.564,0,0,1,.562.562v9.926A.564.564,0,0,1,7.508,45.216ZM4.752,44.091H6.946V35.656H4.752Zm2.39-28.485H4.19a.564.564,0,0,1-.562-.562V10.573a.511.511,0,0,1,.225-.45.5.5,0,0,1,.394-.112h2.84a.5.5,0,0,1,.394.112.511.511,0,0,1,.225.45v4.471A.564.564,0,0,1,7.142,15.606Zm-2.39-1.125H6.58V11.135H4.752Z"
                fill={color}
                fillRule="evenodd"
              />
            </g>
            <g id="Grupo_3249" data-name="Grupo 3249">
              <path
                id="Caminho_7634"
                data-name="Caminho 7634"
                d="M7.142,35.656H4.19a.936.936,0,0,1-.928-.928V15.409a.936.936,0,0,1,.928-.928H7.142a.936.936,0,0,1,.928.928V34.728A.936.936,0,0,1,7.142,35.656ZM4.387,34.531H6.946V15.606H4.387Z"
                fill={color}
                fillRule="evenodd"
              />
            </g>
            <g id="Grupo_3250" data-name="Grupo 3250">
              <path
                id="Caminho_7635"
                data-name="Caminho 7635"
                d="M9.926,42.938H7.508a.564.564,0,0,1-.562-.562v-23.7A1.782,1.782,0,0,1,8.717,16.9H9.926a.564.564,0,0,1,.562.562V42.376A.564.564,0,0,1,9.926,42.938ZM8.07,41.814H9.364V18.025H8.717a.654.654,0,0,0-.647.647Z"
                fill={color}
                fillRule="evenodd"
              />
            </g>
            <g id="Grupo_3251" data-name="Grupo 3251">
              <path
                id="Caminho_7636"
                data-name="Caminho 7636"
                d="M12.316,42.938H9.926a.564.564,0,0,1-.562-.562V17.069A1.764,1.764,0,0,1,11.135,15.3h1.181a.547.547,0,0,1,.562.562V42.376A.547.547,0,0,1,12.316,42.938Zm-1.828-1.125h1.265V16.422h-.619a.636.636,0,0,0-.647.647Z"
                fill={color}
                fillRule="evenodd"
              />
            </g>
            <g id="Grupo_3252" data-name="Grupo 3252">
              <path
                id="Caminho_7637"
                data-name="Caminho 7637"
                d="M14.735,42.938H12.316a.564.564,0,0,1-.562-.562V15.409a1.782,1.782,0,0,1,1.772-1.772h1.209a.564.564,0,0,1,.562.562V42.376A.564.564,0,0,1,14.735,42.938Zm-1.856-1.125h1.293V14.763h-.647a.654.654,0,0,0-.647.647Z"
                fill={color}
                fillRule="evenodd"
              />
            </g>
            <g id="Grupo_3253" data-name="Grupo 3253">
              <path
                id="Caminho_7638"
                data-name="Caminho 7638"
                d="M17.153,42.938H14.735a.564.564,0,0,1-.562-.562V13.863a1.764,1.764,0,0,1,1.772-1.772h1.209a.564.564,0,0,1,.562.562V42.376A.564.564,0,0,1,17.153,42.938ZM15.3,41.814h1.293v-28.6h-.647a.654.654,0,0,0-.647.647Z"
                fill={color}
                fillRule="evenodd"
              />
            </g>
            <g id="Grupo_3254" data-name="Grupo 3254">
              <path
                id="Caminho_7639"
                data-name="Caminho 7639"
                d="M19.571,42.938H17.153a.564.564,0,0,1-.562-.562V12.12a1.764,1.764,0,0,1,1.772-1.772h1.209a.564.564,0,0,1,.562.562V42.376A.564.564,0,0,1,19.571,42.938Zm-1.856-1.125h1.293V11.473h-.647a.636.636,0,0,0-.647.647Z"
                fill={color}
                fillRule="evenodd"
              />
            </g>
            <g id="Grupo_3255" data-name="Grupo 3255">
              <path
                id="Caminho_7640"
                data-name="Caminho 7640"
                d="M40.492,45.216H26.011a.564.564,0,0,1-.562-.562V6.946a.564.564,0,0,1,.562-.562H27.22a4.109,4.109,0,0,1,3.346,1.772,4.031,4.031,0,0,1,2.475,1.8,4.1,4.1,0,0,1,2.25,1.49,4.078,4.078,0,0,1,2.5,1.687,4.108,4.108,0,0,1,2.39,1.6,3.4,3.4,0,0,1,.506.141.577.577,0,0,1,.366.534V44.682A.559.559,0,0,1,40.492,45.216ZM26.573,44.091H39.93V15.831a.417.417,0,0,0-.2-.028.6.6,0,0,1-.366-.281,2.975,2.975,0,0,0-2-1.322.581.581,0,0,1-.394-.281,3.059,3.059,0,0,0-2.109-1.406.683.683,0,0,1-.394-.253,2.936,2.936,0,0,0-1.884-1.265.581.581,0,0,1-.394-.281,3,3,0,0,0-2.081-1.49.547.547,0,0,1-.394-.253,2.952,2.952,0,0,0-2.531-1.462h-.619Z"
                fill={color}
                fillRule="evenodd"
              />
            </g>
            <g id="Grupo_3256" data-name="Grupo 3256">
              <path
                id="Caminho_7641"
                data-name="Caminho 7641"
                d="M21.989,42.938H19.571a.564.564,0,0,1-.562-.562V10.432A1.764,1.764,0,0,1,20.78,8.661h1.209a.564.564,0,0,1,.562.562V42.376A.564.564,0,0,1,21.989,42.938Zm-1.856-1.125h1.293V9.786H20.78a.654.654,0,0,0-.647.647Z"
                fill={color}
                fillRule="evenodd"
              />
            </g>
            <g id="Grupo_3257" data-name="Grupo 3257">
              <path
                id="Caminho_7642"
                data-name="Caminho 7642"
                d="M21.989,45.216H7.508a.564.564,0,0,1-.562-.562V15.409a.577.577,0,0,1,.366-.534,3.4,3.4,0,0,1,.506-.141,3.96,3.96,0,0,1,2.362-1.6,4.063,4.063,0,0,1,2.531-1.687,4.136,4.136,0,0,1,2.221-1.49,4.168,4.168,0,0,1,2.5-1.8A4.032,4.032,0,0,1,20.78,6.383h1.209a.564.564,0,0,1,.562.562V44.654A.564.564,0,0,1,21.989,45.216ZM8.07,44.091H21.427V7.508H20.78A2.952,2.952,0,0,0,18.25,8.97a.547.547,0,0,1-.394.253,2.871,2.871,0,0,0-2.053,1.49.581.581,0,0,1-.394.281A3,3,0,0,0,13.5,12.26a.547.547,0,0,1-.394.253,2.948,2.948,0,0,0-2.081,1.406.581.581,0,0,1-.394.281,2.913,2.913,0,0,0-2,1.322.581.581,0,0,1-.394.281.357.357,0,0,0-.169.028Z"
                fill={color}
                fillRule="evenodd"
              />
            </g>
            <g id="Grupo_3258" data-name="Grupo 3258">
              <path
                id="Caminho_7643"
                data-name="Caminho 7643"
                d="M42.32,8.52A4.021,4.021,0,1,1,46.341,4.5,4.02,4.02,0,0,1,42.32,8.52Zm0-6.917a2.9,2.9,0,1,0,2.9,2.9A2.892,2.892,0,0,0,42.32,1.6Z"
                fill={color}
                fillRule="evenodd"
              />
            </g>
            <g id="Grupo_3259" data-name="Grupo 3259">
              <path
                id="Caminho_7644"
                data-name="Caminho 7644"
                d="M42.32,11.135a.564.564,0,0,1-.562-.562V7.958a.562.562,0,0,1,1.125,0v2.615A.564.564,0,0,1,42.32,11.135Z"
                fill={color}
                fillRule="evenodd"
              />
            </g>
            <g id="Grupo_3260" data-name="Grupo 3260">
              <path
                id="Caminho_7645"
                data-name="Caminho 7645"
                d="M5.849,8.52A4.021,4.021,0,1,1,9.87,4.5,4.02,4.02,0,0,1,5.849,8.52Zm0-6.917a2.9,2.9,0,1,0,2.9,2.9A2.892,2.892,0,0,0,5.849,1.6Z"
                fill={color}
                fillRule="evenodd"
              />
            </g>
            <g id="Grupo_3261" data-name="Grupo 3261">
              <path
                id="Caminho_7646"
                data-name="Caminho 7646"
                d="M5.849,11.135a.564.564,0,0,1-.562-.562V7.958a.562.562,0,1,1,1.125,0v2.615A.564.564,0,0,1,5.849,11.135Z"
                fill={color}
                fillRule="evenodd"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default CondoSVG;
