import React from 'react';
import { BaseProps } from './BaseProps';

const LuxuryHousesSVG: React.FC<BaseProps> = ({ color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="63" height="63" viewBox="0 0 63 63">
      <g
        id="Grupo_de_máscara_248"
        data-name="Grupo de máscara 248"
        transform="translate(-1142.273 -660.273)"
        clipPath="url(#clip-path)"
      >
        <g id="Modern_house" data-name="Modern house" transform="translate(1142 671.604)">
          <path
            id="Caminho_7682"
            data-name="Caminho 7682"
            d="M58.3,53.458H57.286V35.293h3.027a1.009,1.009,0,0,0,1.009-1.009V31.257a1.009,1.009,0,0,0-1.009-1.009H59.3V24.193h1.009a1.009,1.009,0,0,0,1.009-1.009V20.156a1.009,1.009,0,0,0-1.009-1.009H29.03V11.074a1.009,1.009,0,0,0-1.364-.946L3.446,19.211a1.009,1.009,0,0,0-.654.946V34.284A1.009,1.009,0,0,0,3.8,35.293H6.829V53.458H1.783a1.009,1.009,0,0,0,0,2.018H58.3a1.009,1.009,0,1,0,0-2.018Zm-1.009-23.21H32.058V24.193H57.286ZM59.3,21.165v1.009H31.049a1.009,1.009,0,0,0-1.009,1.009v8.073a1.009,1.009,0,0,0,1.009,1.009H59.3v1.009H29.03V21.165Zm-54.494-.31,22.2-8.325V33.275H4.811Zm7.064,32.6V39.33H14.9V53.458Zm5.046,0V39.33h3.027V53.458Zm5.046,0V39.33h3.027V53.458Zm5.046,0V39.33h3.027V53.458Zm5.046,0V38.321a1.009,1.009,0,0,0-1.009-1.009H10.866a1.009,1.009,0,0,0-1.009,1.009V53.458H8.847V35.293H55.268V53.458Z"
            transform="translate(-0.774 -10.064)"
            fill={color}
          />
          <path
            id="Caminho_7683"
            data-name="Caminho 7683"
            d="M48.47,43.355H47.461a1.009,1.009,0,1,0,0,2.018H48.47a1.009,1.009,0,0,0,0-2.018Z"
            transform="translate(13.088 0.039)"
            fill={color}
          />
          <path
            id="Caminho_7684"
            data-name="Caminho 7684"
            d="M45.5,30.968H27.332a1.009,1.009,0,0,0-1.009,1.009V42.068a1.009,1.009,0,0,0,1.009,1.009H45.5a1.009,1.009,0,0,0,1.009-1.009V31.977A1.009,1.009,0,0,0,45.5,30.968ZM28.341,41.059V32.986h4.037v8.073Zm6.055,0V32.986h4.037v8.073Zm10.091,0H40.451V32.986h4.037Z"
            transform="translate(6.979 -3.72)"
            fill={color}
          />
          <path
            id="Caminho_7685"
            data-name="Caminho 7685"
            d="M23.584,29.446a1.009,1.009,0,0,0,1.009-1.009V20.364a1.009,1.009,0,0,0-1.009-1.009H6.429a1.009,1.009,0,0,0-1.009,1.009v8.073a1.009,1.009,0,0,0,1.009,1.009Zm-1.009-8.073v6.055H18.538V21.373Zm-6.055,0v6.055H13.493V21.373Zm-9.082,0h4.037v6.055H7.438Z"
            transform="translate(0.636 -7.244)"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
};

export default LuxuryHousesSVG;
