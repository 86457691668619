import React from 'react';
import { BaseProps } from './BaseProps';

const PetFriendlySVG: React.FC<BaseProps> = ({ color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46">
      <g
        id="Grupo_de_máscara_251"
        data-name="Grupo de máscara 251"
        transform="translate(-452 -642)"
        clipPath="url(#clip-path)"
      >
        <g id="dog_15149274" transform="translate(452.188 642)">
          <g id="Grupo_3299" data-name="Grupo 3299">
            <path
              id="Caminho_7702"
              data-name="Caminho 7702"
              d="M12.679,27.437a2.577,2.577,0,0,0-2.573,2.573,2.782,2.782,0,0,0,.049.5.863.863,0,0,0,1.7-.317.986.986,0,0,1-.02-.184.848.848,0,1,1,1.7,0,.977.977,0,0,1-.02.184.863.863,0,0,0,1.7.317,2.722,2.722,0,0,0,.049-.5,2.576,2.576,0,0,0-2.573-2.573Z"
              transform="translate(-1.74 -2.274)"
              fill={color}
            />
            <path
              id="Caminho_7703"
              data-name="Caminho 7703"
              d="M20.534,27.437A2.577,2.577,0,0,0,17.96,30.01a2.782,2.782,0,0,0,.049.5.863.863,0,0,0,1.7-.317.986.986,0,0,1-.02-.184.848.848,0,1,1,1.7,0,.977.977,0,0,1-.02.184.863.863,0,0,0,1.7.317,2.773,2.773,0,0,0,.049-.5,2.577,2.577,0,0,0-2.573-2.573Z"
              transform="translate(-2.067 -2.274)"
              fill={color}
            />
            <path
              id="Caminho_7704"
              data-name="Caminho 7704"
              d="M32.381,1.18A14.525,14.525,0,0,0,17.889,17.64a8.43,8.43,0,0,0-7.4,1.948h-.43a6.19,6.19,0,0,0-5.918,3.381l-2.08,4.1a6.173,6.173,0,0,0,2.805,8.379l-.085.328A9.123,9.123,0,0,0,13.62,47.18h4.923A9.13,9.13,0,0,0,27.4,35.825l-.094-.372a6.18,6.18,0,0,0,3.448-5.129A14.618,14.618,0,1,0,32.381,1.18ZM3.386,31.45a4.46,4.46,0,0,1,.211-3.6l2.08-4.094a4.47,4.47,0,0,1,3.272-2.371,8.568,8.568,0,0,0-1.1,2.475L5.305,33.729A4.486,4.486,0,0,1,3.386,31.45Zm12.723,5.505a1.805,1.805,0,0,1-1.755-1.369l-.084-.334a.6.6,0,0,1,.58-.743h2.517a.6.6,0,0,1,.58.743l-.083.334A1.806,1.806,0,0,1,16.109,36.955Zm8.271,5.65a7.357,7.357,0,0,1-5.838,2.85H16.933l.019-6.888A3.52,3.52,0,0,0,19.537,36l.083-.334a2.322,2.322,0,0,0-2.253-2.886H14.85A2.322,2.322,0,0,0,12.6,35.67L12.68,36a3.521,3.521,0,0,0,2.546,2.553l-.019,6.9H13.62A7.4,7.4,0,0,1,6.449,36.2L9.52,24.289a6.747,6.747,0,0,1,1.893-3.22,6.855,6.855,0,0,1,6.851-1.528,14.641,14.641,0,0,0,5.223,7.837l2.236,8.866a7.36,7.36,0,0,1-1.343,6.361Zm2.49-8.869-1.258-4.989a14.5,14.5,0,0,0,3.423,1.272A4.448,4.448,0,0,1,26.87,33.736Zm5.511-5.04a12.9,12.9,0,1,1,12.9-12.9A12.91,12.91,0,0,1,32.381,28.7Z"
              transform="translate(-1.376 -1.18)"
              fill={color}
            />
            <path
              id="Caminho_7705"
              data-name="Caminho 7705"
              d="M34.765,9.889A7,7,0,0,0,33.281,11.5a6.939,6.939,0,0,0-1.433-1.612,5.057,5.057,0,0,0-6.726.623,5.942,5.942,0,0,0-1.5,4.058,7.061,7.061,0,0,0,1.912,4.675A86.8,86.8,0,0,0,32,25.186a1.993,1.993,0,0,0,2.621,0,76.917,76.917,0,0,0,6.468-5.942A7.063,7.063,0,0,0,43,14.57a5.947,5.947,0,0,0-1.5-4.06,5.054,5.054,0,0,0-6.727-.621Zm4.991,8.255a72.372,72.372,0,0,1-6.267,5.737.277.277,0,0,1-.366,0,80.27,80.27,0,0,1-6.264-5.737,5.467,5.467,0,0,1-1.515-3.574,4.224,4.224,0,0,1,1.047-2.889,3.362,3.362,0,0,1,2.5-1.086,3.039,3.039,0,0,1,1.9.658,39.1,39.1,0,0,1,2.521,3.062,34.155,34.155,0,0,1,2.509-3.061,3.3,3.3,0,0,1,4.4.426,4.229,4.229,0,0,1,1.047,2.89,5.469,5.469,0,0,1-1.515,3.574Z"
              transform="translate(-2.303 -1.501)"
              fill={color}
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default PetFriendlySVG;
