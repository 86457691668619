export const BACKOFFICE_REGULAR_USER_URL = '/panel';
export const BACKOFFICE_BASE_URL = '/admin';
export const MANAGEMENT_URL = `${BACKOFFICE_BASE_URL}/management`;
export const AGENT_URL = `${BACKOFFICE_BASE_URL}/agent`;
export const AGENCY_URL = `${BACKOFFICE_BASE_URL}/agency/:agencyId`;
export const RESERVATIONS_URL = `${BACKOFFICE_BASE_URL}/reservations`;
export const RESERVATIONS_DETAILS_URL = `${BACKOFFICE_BASE_URL}/reservations-details/:reserveId`;
export const COMMISSION_URL = `${BACKOFFICE_BASE_URL}/commissions`;
export const CHECK_AVAILABILITY_URL = `${BACKOFFICE_BASE_URL}/check-availability`;

// TODO: criar um arquivo de administrative
export const LIST_OPERATORS = `${BACKOFFICE_BASE_URL}/operators-list`;
export const OPERATOR_DETAILS = `${BACKOFFICE_BASE_URL}/operators-details/:id`;
export const LINKED_AGENCIES = `${BACKOFFICE_BASE_URL}/linked-agencies/:agencyId`;
export const LINKED_AGENTS = `${BACKOFFICE_BASE_URL}/linked-agents/:id`;
export const LIST_AGENCY = `${BACKOFFICE_BASE_URL}/agency-list`;
export const AGENCY_DETAILS = `${BACKOFFICE_BASE_URL}/agency-details/:id`;
export const HOUSE_LIST_ADM = `${BACKOFFICE_BASE_URL}/house-list/:id`;
export const CONDOMINIUM_LIST_ADM = `${BACKOFFICE_BASE_URL}/condominium-list`;
export const INCLUDE_NEW_CONDOMINIUM = `${BACKOFFICE_BASE_URL}/condominium-list/new`;
export const HOUSE_MANAGEMENT = `${BACKOFFICE_BASE_URL}/house-management/:id`;
export const REPORTS_LIST = `${BACKOFFICE_BASE_URL}/reports-list`;
export const REGISTER_OPERATORS_AND_AGENCIES = `${BACKOFFICE_BASE_URL}/register`;

// TODO: Criar arquivo para usuarios e usuarios parceiros
export const RESERVATIONS_REGULAR_USER_URL = `${BACKOFFICE_REGULAR_USER_URL}/reservations`;
export const RESERVATIONS_DETAILS_REGULAR_USER_URL = `${BACKOFFICE_REGULAR_USER_URL}/reservations-details/:reserveId`;
