import { IAmenities, ICoordenates, IHouse, IPicture, IRentSchedule, ISchedule } from './interfaces';
// import { houseSpacesIcons } from 'utils/houseSpacesIcons';
// import { compareArray } from 'utils/sort';
import { IHouseSpaces } from './interfaces/IHouseSpaces.interface';
import { Languages, addTranslation } from 'languages/helpers.languages';

export class House implements IHouse {
  id: number;
  name: string;
  nameES: string;
  namePT: string;
  nameUS: string;
  description: string;
  descriptionPT: string;
  descriptionES: string;
  coordenates: ICoordenates;
  scheduleRules: ISchedule;
  pictures: IPicture[];
  amenities: IAmenities[];
  scheduleForRent: IRentSchedule[];
  maxOccupancy: number;
  bedroomAmount: number;
  bathroomAmount: number;
  minimunChildAge: number;
  isPetFriendly: boolean;
  poolProtectedToChildAndAnimal: boolean;
  poolRestritecHours: boolean;
  childFriendly: boolean;
  poolCovered: boolean;
  heatedPool: boolean;
  hasHydromassage: boolean;

  constructor(house: IHouse) {
    this.id = house.id;
    this.name = house.name;
    this.nameES = house.nameES;
    this.namePT = house.namePT;
    this.nameUS = house.nameUS;
    this.description = house.description;
    this.descriptionPT = house.descriptionPT;
    this.descriptionES = house.descriptionES;
    this.coordenates = house.coordenates;
    this.scheduleRules = house.scheduleRules;
    this.pictures = house.pictures;
    this.amenities = house.amenities;
    this.maxOccupancy = house.maxOccupancy;
    this.bedroomAmount = house.bedroomAmount;
    this.bathroomAmount = house.bathroomAmount;
    this.minimunChildAge = house.minimunChildAge ?? 0;
    this.isPetFriendly = house.isPetFriendly;
    this.poolProtectedToChildAndAnimal = house.poolProtectedToChildAndAnimal;
    this.poolRestritecHours = house.poolRestritecHours;
    this.childFriendly = house.childFriendly;
    this.poolCovered = house.poolCovered;
    this.heatedPool = house.heatedPool;
    this.scheduleForRent = house.scheduleForRent;
    this.hasHydromassage = house.hasHydromassage;

    // TODO: Trocar essa lógica de armazenar as traduções no arquivo, uma vez que o backend mudou
    // TODO: ARRUMAR AS TRADUÇÕES, ESTAMOS TENDO QUE LIDAR COM DIVERSAS SIGLAS PARA A MESMA LINGUA
    addTranslation(Languages.EN_US, {
      TITLE: house.nameUS,
      DESCRIPTION: house.description,
    });

    addTranslation(Languages.ES, {
      TITLE: house.nameES,
      DESCRIPTION: house.description,
    });

    addTranslation(Languages.PT_BR, {
      TITLE: house.namePT,
      DESCRIPTION: house.description,
    });

    addTranslation(Languages.PT_PT, {
      TITLE: house.namePT,
      DESCRIPTION: house.description,
    });
    addTranslation(Languages.PT, {
      TITLE: house.namePT,
      DESCRIPTION: house.description,
    });

    addTranslation(Languages.EN_USA, {
      TITLE: house.nameES,
      DESCRIPTION: house.description,
    });
  }

  private getMainSpaces(): IHouseSpaces[] {
    const spaces: IHouseSpaces[] = [
      {
        label: 'ROOMS',
        quantity: this.bedroomAmount,
      },
      {
        label: 'BATHROOM',
        quantity: this.bathroomAmount,
      },
      {
        label: 'MAXOCCUPANCY',
        quantity: this.maxOccupancy,
      },
    ];

    if (this.heatedPool || this.poolCovered || this.poolProtectedToChildAndAnimal) {
      spaces.push({
        label: 'POOL',
      });
    }

    if (this.hasHydromassage) {
      spaces.push({
        label: 'HIDRO_MASSAGE',
      });
    }

    if (this.isPetFriendly) {
      spaces.push({
        label: 'PETS',
      });
    }

    if (this.heatedPool) {
      spaces.push({
        label: 'POOL_HEATABLE',
      });
    }

    return spaces;
  }

  getSpaces(): IHouseSpaces[] {
    // TO DO: Adicionar amenities aqui quando o back passar a retornar um enum e não apenas o nome

    // const spacesOfHouse = [...this.amenities].sort((a: any, b: any) => compareArray(a, b, priorityFeatures));
    // const translatedAmentities = spacesOfHouse.map((amenitie) => {
    //   return {
    //     ...amenitie,
    //     label: `AMENITIES.${amenitie.label.toUpperCase()}`,
    //     icon: houseSpacesIcons[amenitie.label.toUpperCase()],
    //   };
    // });
    const mainSpaces = this.getMainSpaces();

    return [...mainSpaces];
  }
}
