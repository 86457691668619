import HouseCard from 'components/HouseCard';

import styles from './styles.module.scss';
import { HouseResume } from 'models/application/HouseResume.model';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as NumberOne } from '../../assets/svg/number-one.svg';
import { ReactComponent as NumberTwo } from '../../assets/svg/number-two.svg';
import { ReactComponent as NumberThree } from '../../assets/svg/number-three.svg';
import { ReactComponent as NumberFour } from '../../assets/svg/number-four.svg';
import { ReactComponent as NumberFive } from '../../assets/svg/number-five.svg';
import { ReactComponent as NumberSix } from '../../assets/svg/number-six.svg';
import { ReactComponent as NumberSeven } from '../../assets/svg/number-seven.svg';
import { ReactComponent as NumberEight } from '../../assets/svg/number-eight.svg';
import { ReactComponent as NumberNine } from '../../assets/svg/number-nine.svg';
import { ReactComponent as NumberTen } from '../../assets/svg/number-ten.svg';
import WECarousel from 'components/WECarousel';
import { setOrigim } from 'utils/helpers';

type ListHousesSectionProps = {
  title: string;
  data: HouseResume[];
  withNumbers?: boolean;
  onClickItem?: (id: number) => void;
};

const ListHousesSection: React.FC<ListHousesSectionProps> = ({ title, data, withNumbers, onClickItem }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = (id: number) => {
    if (onClickItem) {
      return onClickItem(id);
    }
    setOrigim(`${location.pathname}${location.search}`);
    navigate({
      pathname: `/house-details/${id}`,
      search: `?checkIn=${null}&checkOut=${null}`,
    });
  };

  const getImageNumber = (number: number) => {
    switch (number) {
      case 1:
        return <NumberOne />;
      case 2:
        return <NumberTwo />;
      case 3:
        return <NumberThree />;
      case 4:
        return <NumberFour />;
      case 5:
        return <NumberFive />;
      case 6:
        return <NumberSix />;
      case 7:
        return <NumberSeven />;
      case 8:
        return <NumberEight />;
      case 9:
        return <NumberNine />;
      case 10:
        return <NumberTen />;
      default:
        return null;
    }
  };

  return (
    <div className={styles.mainList}>
      <h1 className={styles.title}>{title}</h1>
      <WECarousel withNumbers={withNumbers}>
        {data.map((house, index) => (
          <div className={withNumbers ? styles.numberCointainer : undefined} key={index}>
            {withNumbers && <div className={styles.bigIndex}>{getImageNumber(index + 1)}</div>}
            <div className={withNumbers ? styles.houseCard : undefined}>
              <HouseCard
                image={house.mainPicture}
                pricePerNight={house.getPrice()}
                address={house.name}
                name={house.name}
                nameEs={house.nameEs}
                namePt={house.namePt}
                nameUs={house.nameUs}
                id={house.id}
                spaces={house.amenities.map((amenitie) => amenitie.name)}
                rooms={house.bedroomAmount}
                guests={house.maxOccupancy}
                key={index}
                onClickItem={handleClick}
              />
            </div>
          </div>
        ))}
      </WECarousel>
    </div>
  );
};

export default ListHousesSection;
