import { useEffect, useMemo, useState } from 'react';
import styles from './styles.module.scss';
import Header from 'components/Header';
import SearchHouseMini from 'components/SearchHouseMini';
import { Trans, useTranslation } from 'react-i18next';
import { Buttons } from 'components/molecules/buttons';
import COLORS from 'constants/colors';
import { HiOutlineMap } from 'react-icons/hi';
import SearchMap from 'components/Maps/SearchMap/Location.component';
import useCondominiums from 'hooks/useCondominiums';
import { useParams } from 'react-router-dom';
import { CITIES } from 'utils/enums/cities.enums';
import ListHousesLoading from 'components/ListHousesSection/loading';
import useHouseListNavigation from 'routes/navigation/listHouses.navigation';
import CardCondominium from 'components/CardCondominium';
import Booking from 'models/application/Booking.model';
import useFilters from 'hooks/useFilters';
import i18next from 'i18next';
import Condominium from 'models/application/Condominium.model';
import { MdOutlineChevronLeft } from 'react-icons/md';

const Condos = () => {
  const { t } = useTranslation();
  const { condominiums, loadingCondominiums, getCondominiumsByCity } = useCondominiums();
  const [mapIsOpen, setMapIsOpen] = useState(false);
  const urlParams = useParams();
  const { navigateToCondominiums, navigateToCondominium, navigateToHome } = useHouseListNavigation();
  const { getCurrentBookingParameters } = useFilters();
  const booking = getCurrentBookingParameters();

  const handleMap = () => {
    setMapIsOpen((m) => !m);
  };

  useEffect(() => {
    getCondominiumsByCity(booking.checkIn, booking.checkOut, city.id);
  }, [booking.checkIn, booking.checkOut, booking.adults, booking.kids, booking.pets, booking.babies]);

  const cities = useMemo(() => {
    return [
      {
        id: CITIES.CLERMONT,
        style: styles.clermont,
        name: 'Clermont',
        description: () => <Trans i18nKey="CLERMONT" i18n={i18next} />,
        coordinates: { lat: 28.549445, long: -81.772854 },
      },
      {
        id: CITIES.KISSIMEE,
        style: styles.kissimee,
        name: 'Kissimmee',
        description: () => <Trans i18nKey="KISSIMMEE" i18n={i18next} />,
        coordinates: { lat: 28.291956, long: -81.40757 },
      },
      {
        id: CITIES.DAVENPORT,
        style: styles.davenport,
        name: 'Davenport',
        description: () => <Trans i18nKey="DAVENPORT" i18n={i18next} />,
        coordinates: { lat: 28.1614046, long: -81.60174169999999 },
      },
      {
        id: CITIES.ORLANDO,
        style: styles.orlando,
        name: 'Orlando',
        description: () => <Trans i18nKey="ORLANDO" i18n={i18next} />,
        coordinates: { lat: 28.4159, long: -81.2988 },
      },
    ];
  }, [i18next.language]);

  const city = useMemo(() => {
    return cities.find((cityParam) => cityParam.id === urlParams.city) as any;
  }, []);

  const handleSearch = (searchValues: Booking) => {
    if (!searchValues.checkIn || !searchValues.checkOut) return;
    navigateToCondominiums(city.id, searchValues);
  };

  const goToItem = (condominium: Condominium) => {
    navigateToCondominium(booking, {
      condominiumName: condominium.name,
      image: condominium.image,
      cityCoordenates: city.coordinates,
      description: condominium.getDescription(),
      city: city.id,
    });
  };

  const handleClickMarker = (name: number | string) => {
    const selected = condominiums.find((condominium) => condominium.name === name) as Condominium;
    goToItem(selected);
  };

  const goBack = () => {
    navigateToHome(booking, city.id);
  };

  const isVistaCay = (condominiumName: string): boolean => {
    if (!condominiumName) return false;

    return condominiumName.toLowerCase().includes('vista cay resort');
  };

  return (
    <div className={styles.container}>
      <Header backgroundTransparent>
        <SearchHouseMini onSearch={handleSearch} initialPeriod={[booking.checkIn, booking.checkOut]} />
      </Header>
      <div className={`${styles.background} ${city.style}`}></div>
      <div className={styles.layer}></div>
      <div className={styles.backgroundContent}>
        <div onClick={goBack} className={styles.backButton}>
          <MdOutlineChevronLeft size={40} color="#FFFFFF" />
        </div>
        <div className={styles.infos}>
          <div className={styles.title}>{city.name}</div>
          <div className={styles.description}>{city.description()}</div>
        </div>

        <Buttons.Default
          className={styles.button}
          endIcon={<HiOutlineMap size={18} />}
          backgroundColor={COLORS.neutral.white}
          color={COLORS.neutral.black}
          variant="contained"
          onClick={handleMap}
        >
          {t('OPEN_MAP')}
        </Buttons.Default>
      </div>
      <div className={styles.blueBackground}>
        {mapIsOpen ? (
          <SearchMap
            center={{
              lat: condominiums[0]?.coordenate?.lat ?? city.coordinates.lat,
              long: condominiums[0]?.coordenate?.long ?? city.coordinates.long,
            }}
            houses={[]}
            condominiums={condominiums}
            zoom={13}
            className={styles.location}
            onClickMarker={handleClickMarker}
          />
        ) : null}

        <div className={styles.condominiumTitle}>{t('CONDOMINIUMS')}</div>
        <div className={styles.list}>
          {loadingCondominiums ? (
            <ListHousesLoading />
          ) : (
            <>
              {condominiums.map((condominium: any) => {
                return (
                  <CardCondominium video={isVistaCay(condominium.name)} condominium={condominium} onClick={goToItem} />
                );
              })}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Condos;
