export default {
  BACKOFFICE: {
    MANAGEMENT: 'Gerenciamento',
    SEARCH: 'Busca por nome ou código',
    RESERVATIONS: 'Reservas',
    COMMISSIONS: 'Comissões',
    AVAILABILITY: 'Consultar de Disponibilidade',
    REPORTS: 'Relatórios',
    REGISTER: 'Cadastrar usuario',
    INACTIVE_ACCOUNT: 'Inativar conta',
    SAVE_CHANGES: 'Salvar alterações',
    OPERATOR: 'Operadora',
    REGISTER_TITLE: 'O que deseja cadastrar?',
    TABLE: {
      MORE_INFORMATION: 'MAIS INFORMAÇÕES',
      LINKED_AGENCIES: 'AGÊNCIAS VINCULADAS',
      LINKED_AGENTS: 'AGENTES VINCULADOS',
      MONTH_TOTAL_SALES: 'TOTAL DE VENDAS MÊS',
      MONTH_TOTAL_RESERVATIONS: 'TOTAL RESERVAS MÊS',
      CODE: 'CÓDIGO',
      NAME: 'NOME',
      TYPE: 'TIPO',
      CONTACT: 'CONTATO',
      ADMIN: 'Gestor',
      USER: 'Agente',
      STATUS: 'STATUS',
      ACTIVE: 'ATIVO',
      WAITING_ACCESS: 'SOLICITANDO ACESSO',
      OPERATOR: 'OPERADORA',
      RESERVATION: 'RESERVA',
      CLIENT_NAME: 'NOME DO CLIENTE',
      HOUSE: 'IMÓVEL',
      SOLD_BY: 'VENDIDO POR',
      PURCHASED_BY: 'COMPRADO POR',
      AGENCIES: 'AGÊNCIAS',
      AGENTS: 'AGENTES',
      AGENCY: 'AGÊNCIA',
      VALUE: 'VALOR',
      DAILY_RATES: 'DIARIAS',
      CHANGE: 'ALTERAR',
      PRE_BOOKING: 'PRÉ_RESERVA',
      CONFIRMED: 'CONFIRMADA',
      CANCELED: 'CANCELADA',
    },
    AGENT: {
      PERSONAL_DATA: 'Dados pessoais',
      CODE: 'Código',
      NAME: 'Nome',
      EMAIL: 'Email',
      ROLE: 'Função',
      CELLPHONE: 'Celular',
      NATIONAL_REGISTRATION: 'CPF',
      ZIPCODE: 'CEP',
      ADDRESS: 'Endereço',
      COUNTRY: 'País',
      STATE: 'Estado',
      CITY: 'Cidade',
      SAVE: 'Salvar',
      PASSWORD: 'Senha',
      FEEDBACK: {
        EDIT_SUCCESS: 'Agente salvo com sucesso',
        CREATE_SUCCESS: 'Agente criado com sucesso',
      },
    },
    AGENCY: {
      TITLE: 'Dados agência',
      ID: 'Código',
      NAME: 'Nome',
      EMAIL: 'Email',
      CADASTUR: 'CADASTUR',
      PRIMARY_CONTACT: 'Contato Principal',
      URL_SITE: 'URL Site',
      DOCUMENT: 'Documento',
      PROFIT_PERCENTAGE: 'Porcentagem de Lucro',
      FEEDBACK: {
        EDIT_SUCCESS: 'Agência editada com sucesso',
      },
    },
    RESERVATIONSCREEN: {
      COMPLETED: 'realizadas',
      CONFIRMED: 'confirmadas',
      CANCELED: 'canceladas',
      SEARCH_BY_RESERVATION_ID: 'Busca pelo código da reserva',
      RESERVATIONID: 'ReservaID',
      CUSTOMERNAME: 'Nome Cliente',
      PROPERTY: 'Imovel',
      AGENT: 'Agente',
      AGENCY: 'Agencia',
      DOLLARAMOUNT: 'Valor Dolar',
      REALAMOUNT: 'Valor Real',
      NIGHTS: 'Diarias',
      STATUS: 'Status',
      DOWNLOAD: 'Gerar Relatórios',
    },
    RESERVATION_DETAIL: {
      RESERVATION_SUMMARY: 'Resumo da Reserva',
      SUMMARY: 'Resumo',
      VALUES: 'Valores',
      HISTORY: 'Histórico',
      DATES: 'Datas',
      GUESTS: 'Hóspedes',
      DAILY_RATE: 'Diária',
      NAME: 'Nome',
      CONTACT: 'Contato',
      ADULTS: 'Adultos',
      ADDRESS: 'Endereço',
      HOUSE_ID: 'Código da casa',
      DETAILS: 'Detalhes',
      CREATE_AT: 'Criado em',
      ACCOMMODATION_ADDRESS: 'Endereço da Hospedagem',
      SHOW_ON_MAP: 'Mostrar no mapa',
      PAYMENT_INFORMATION: 'Informações de pagamento',
      REFUND_POLICY: 'Politica de reembolso',
      AMOUNTS: 'Valores',
      CLEANING_FEE: 'Taxa de Limpeza',
      GOWEASY_SERVICE_FEE: 'Taxa de serviço GoWeasy',
      TAXES: 'Impostos',
      COMMISSION: 'Comissão',
      PAYMENT_INFORMATION_TEXT: `Se o Anfitrião cancelar uma reserva antes do check-in,
      o hóspede receberá um reembolso integral automaticamente. Se o Anfitrião cancelar
      uma reserva até 30 dias antes do check-in, também ajudaremos o hóspede a encontrar
      um lugar parecido, dependendo da disponibilidade na mesma faixa de preço.`,
      PAYMENT_LINK: 'Link de Pagamento',
      TIME: 'HORÁRIO',
      RESERVATION: 'RESERVA',
      CLIENT_NAME: 'NOME DO CLIENTE',
      PROPERTY: 'IMÓVEL',
      AGENCY: 'AGÊNCIA',
      CATEGORY: 'CATEGORIA',
      RESPONSIBLE: 'RESPONSÁVEL',
      DESCRIPTION: 'DESCRIÇÃO',
      PENDING: 'PENDENTE',
      CONFIRMED: 'CONFIRMADA',
      CANCELED: 'CANCELADA',
    },
    COMMISSIONS_TABLE: {
      COMMISSION_QUERY: 'Consulta de comissões',
      TOTAL_MONTHLY_COMMISSIONS: 'Total de comissões do mês',
      SALES_CANCELED_IN_THE_MONTH: 'Vendas canceladas no mês',
      SEARCH_BY_RESERVATION_CODE: 'Busca pelo código da reserva',
      PENDING: 'PENDENTE',
      CANCELED: 'CANCELADA',
      PAID: 'PAGA',
      BOOKING_COMPLETED: 'Reserva Realizada',
      PAYMENT_PENDING: 'Pagamento Pendente',
      BOOKING_CANCELED: 'Reserva Cancelada',
      PAYMENT_COMPLETED: 'Pago',
      DATE: 'DATA',
      RESERVATION: 'RESERVA',
      PROPERTY: 'IMÓVEL',
      SOLD_BY: 'VENDIDO POR',
      AGENCY: 'AGÊNCIA',
      SALE_VALUE: 'VALOR DA VENDA',
      COMMISSION: 'COMISSÃO',
      STATUS: 'STATUS',
      PAYMENT_HAS_BEEN_COMPLETED: 'Pagamento Realizado',
      REPORTS: {
        DATE: 'Data',
        RESERVACODE: 'Código da Reserva',
        HOUSEID: 'ID da Casa',
        AGENTE: 'Agente',
        AGENCY: 'Agencia',
        VALUEREAL: 'Valor em Reais',
        VALUEDOLLAR: 'Valor em Dólares',
        COMISSAOREAL: 'Comissão em Reais',
        COMISSAODOLLAR: 'Comissão em Dólares',
        COMISSAOPORCENTAGEM: 'Porcentagem da Comissão',
        STATUS: 'Status',
        AGENCY_PAYMENT: 'Pagamento Agência',
      },
    },
  },
};
