import houseDetails from './houseDetails';
import faq from './faq';
import home from './home';
import publicTranslations from './public';
import search from './search';
import checkOut from './checkout';
import condominiums from './condominiums';
import whoWeAre from './whoWeAre';
import modals from './modals';
import backoffice from './backoffice';
import administrative from './administrative';
import reservation from './reservation';

export default {
  ...checkOut,
  ...houseDetails,
  ...faq,
  ...home,
  ...publicTranslations,
  ...search,
  ...condominiums,
  ...whoWeAre,
  ...modals,
  ...backoffice,
  ...administrative,
  ...reservation,
  MENU: {
    MANAGEMENT: 'Management',
    CHANGE_REGISTER: 'Change register',
    ADMINISTRATIVE: 'Administrative',
    LOGOUT: 'Logout',
    MY_RESERVATIONS: 'My Reservations',
  },
  TERMS_OF_SERVICE: {
    TITLE: 'Terms of Service',
  },
  CANCELLATION_POLICY: {
    TITLE: 'Cancellation Policy',
  },
  PRIVACY_POLICY: {
    TITLE: 'Privacy Policy',
  },
  TOM_ASSISTANT: {
    FIRST_MESSAGE: 'Hello! I am Tom, the virtual assistant of GoWeasy.',
    SECOND_MESSAGE: 'How can I help you today?',
    QUESTIONS: {
      QUESTION_1: 'Is this your first time in Orlando?',
      QUESTION_2: 'Choose a house for your vacation.',
      QUESTION_3: 'Get to know the best condominiums for your vacation.',
      QUESTION_4: 'What to do in Orlando besides theme parks.',
    },
  },
  HOUSE_CARD: {
    PER_NIGHT: 'per night',
    ROOMS: 'rooms',
    GUESTS: 'guests',
  },
  COMMON: {
    SHOW_MORE: 'Show more',
    SHOW_LESS: 'Show less',
  },
  BACKEND: {
    TITLE: '',
    DESCRIPTION: '',
  },
  AMENITIES: {
    HIDRO_MASSAGE: 'Hydromassage',
    ROOMS: 'rooms',
    BEDS: 'beds',
    BATHROOM: 'bathrooms',
    LAVATORY: 'lavatory',
    GARAGE: 'garage',
    SOFABED: 'sofa bed',
    MAXOCCUPANCY: 'guests',
    WIFI: 'wifi',
    ALARM: 'alarm',
    CAMERA: 'camera',
    SAFE: 'safe',
    SAFETY_FENCE_POOL: 'safety fence pool',
    POOL_ACCESS_ALARM: 'pool access alarm',
    POOL: 'pool',
    PRIVATE_POOL: 'private pool',
    SPA: 'spa',
    OUT_TUB: 'out tub',
    IND_TUB: 'ind tub',
    SOLAR_HEAT: 'solar heat',
    POOL_HEATABLE: 'pool heatable',
    FACING_POOL: 'facing pool',
    POOL_ACCESS: 'pool access',
    IND_JACUZZI: 'jacuzzi',
    BBQ: 'barbecue',
    GRELL: 'grell',
    HAIR_DRYER: 'hair dryer',
    DISHWASHER: 'dishwasher',
    MINI_KITCHEN: 'mini kitchen',
    FULL_KITCHEN: 'full kitchen',
    RESORT_AMEN: 'resort amen',
    ELEVATOR: 'elevator',
    WHEL_CHAIR_ACC: 'whel chair acc',
    FREE_CALLS: 'free calls',
    PAV_PARK: 'pav park',
    ROCK_CHAIRS: 'rock chairs',
    MICROWAVE: 'microwave',
    LAUNDRY_IN: 'laundry in',
    LAUNDRY_ON: 'laundry on',
    UNIT_AMEN: 'unit amen',
    DOCK: 'dock',
    MOTOR_CYDE: 'motor cyde',
    WIRED_INTERNET: 'wired internet',
    INTERNET_PC: 'internet pc',
    STROLLER: 'stroller',
    CRIB: 'crib',
    PACKNPLAY: 'packnplay',
    HIGH_CHAIR: 'high chair',
    ELETRIC_FIREPLACE: 'eletric fireplace',
    WOOD_BURNING: 'wood burning',
    UNDER_FLOOR_HEAT: 'under floor heat',
    GAS_FIREPLACE: 'gas fireplace',
    AIRCONDITIONING: 'air conditioning',
    GAMES_ROOM: 'games room',
    COMMUNAL_GYM: 'communal gym',
    AIR_HOCKEY: 'air hockey',
    FOOSBALL: 'foosball',
    POOL_TABLE: 'pool table',
    VIDEO_GAMES: 'video games',
    VCR: 'vcr',
    TABLE_TENNIS: 'table tennis',
    GOLF_INCL: 'golf',
    BIG_SCR_TV: 'big scr tv',
    TV_BEDROOMS: 'tv bedrooms',
    CDPLAYER: 'cdplayer',
    DVD_BLUERAY: 'dvd blueray',
    FISHING: 'fishing',
    CLUB_HOUSE: 'club house',
    TENNIS_COURT: 'tennis court',
    PETS: 'pets',
    GAS_FREE: 'gas free',
    PRIVACY_FENCE: 'privacy fence',
    BEACH_ON: 'beach on',
    WASHER: 'washer',
    DRYER: 'dryer',
    WASHER_DRYER: 'washer dryer',
    HEATING: 'heating',
    PRIVATE_ENTRANCE: 'private entrance',
    BEDDING_ESSENTIALS: 'bedding essentials',
    TOILETRIES: 'toiletries',
  },
  HOME: {
    BE_A_PARTNER: {
      MESSAGE_BUSSINESS_EASIER: 'Make your business easier\nby becoming a WE partner',
      MESSAGE_BE_PART_OF_OUR_JOURNEY: 'Come be part of our journey to democratize\nglobal travel.',
      MESSAGE_CLICK_NOW: 'Click now and be a successful partner!',
      WANT_TO_KNOW_MORE: 'Want to know more',
    },
    ENJOY_MORE: {
      WANT_TO_ENJOY_MORE: 'Want to enjoy more?',
      MESSAGE_SPECIAL_OFFERS: 'Receive exclusive offers and important tips to make your trip unforgettable!',
      SUBSCRIBE: 'Subscribe',
    },
    FOOTER: {
      KNOW_MORE: 'Know More',
      OUR_HISTORY: 'Our History',
      CAREERS: 'Careers',
      WHO_WE_ARE: 'About us',
      TERMS_AND_CONDITIONS: 'Terms of use',
      PRIVACY_POLICIES: 'Privacy Policy',
      CONTACT: 'Contact',
      STREET: 'Street',
      MESSAGE_COPYRIGHT: 'Copyright 2024 GoWeasy | All rights reserved.',
    },
    HEADER: {
      HOME: 'Home',
      ABOUT_US: 'About Us',
      WANT_TO_BE_A_PARTNER: 'Want to be a partner',
      PORTUGUESE: 'Portuguese',
      SPANISH: 'Spanish',
      ENGLISH: 'English',
      ENTER: 'Enter',
      REGISTER: 'Register',
      WHO_WE_ARE: 'About us',
      SOLUTIONS: 'Solutions',
      PARTNERS: 'Partners',
      CONTACT: 'Contact',
      OUT: 'Logout',
    },
    WHY_CHOOSE_US: {
      UNBEATABLE_PRICES: 'Unbeatable Prices',
      MESSAGE_UNBEATABLE_PRICES:
        'Secure Vacation Rentals in Orlando! Ensure the Best Value and Price by Booking Directly with Us.',
      SAFE_HOSPITALITY: 'Safe Hospitality',
      MESSAGE_SAFE_HOSPITALITY:
        'Specializing in Turning Vacation Dreams into Reality, Each Day of Your Stay is a Journey of Priceless Memories.',
      EXCELLENT_VARIETY_OF_OPTIONS: 'Excellent Variety Of Options',
      MESSAGE_EXCELLENT_VARIETY_OF_OPTIONS:
        'We showcase a vast range of vacation rentals. Our curated inventory will help you find the ideal home.',
      PRIVILEGED_LOCATION: 'Privileged Location',
      MESSAGE_PRIVILEGED_LOCATION:
        'We offer residences in the finest areas of Orlando, close to all the attractions your vacation deserves.',
    },
    EXPERIENCES: {
      RENT_EXOTIC_CARS: 'Rent Exotic Cars',
      CHEF_IN_THE_HOUSE: 'Private chef',
      GOLF_PACKAGES: 'Golf Packages',
      IOGA_LESSONS: 'Yoga Lessons',
    },
    HOME_VIDEO: {
      LOCATION: 'Location',
      CHECK_IN: 'Check-in',
      CHECK_IN_DATE: 'Check-in Date',
      CHECKOUT: 'Checkout',
      CHECK_OUT_DATE: 'Checkout Date',
      GUESTS: 'Guests',
      HOW_MUCH_PEOPLE: 'How Many People',
      SEARCH: 'Search',
      MESSAGE_FIND_YOUR_PERFECT_RENT: 'Find the perfect accommodation in Orlando!',
      MESSAGE_HALVED_FIND_YOUR_PERFECT_RENT: 'Find the perfect\naccommodation in Orlando',
      ADULTS: 'Adults',
      '13_YEARS_OR_PLUS': '13 years or older',
      CHILDREN: 'Children',
      FROM_TWO_TO_TWELVE_YEARS: 'From 2 to 12 years',
      BABIES: 'Babies',
      ABOVE_TWO_YEARS: 'Less than 2 years',
      PETIES: 'Pets',
      PETIES_SMALL_PORT: 'Small pets',
      ENTER_THE_DESIRED_DATE: 'Enter the desired date',
      CONFIRM: 'Confirm',
    },
    TITLES: {
      TITLE_OUR_PROPERTIES: 'Our featured homes',
      TITLE_WHY_CHOOSE_US: 'Why choose WE for your vacation homes',
      TITLE_THEMED_HOMES: 'Themed Homes',
      TITLE_TRANSFORM_YOUR_STAY: `Transform your customers' stay into unforgettable moments`,
      TITLE_TRANSFORM_YOUR_STAY_DESCRIPTION: 'With GoWeasy, create memorable moments easily',
    },
    USER: {
      TYPE_YOUR_EMAIL_ADDRESS: 'Enter your email address',
      EMAIL: 'Email',
      EXCLUSIVE: 'Exclusive',
    },
    LOGIN: {
      EMAIL: 'E-mail',
      TYPE_YOUR_EMAIL: 'Digite seu e-mail',
      CELLPHONE: 'Celular',
      TYPE_YOUR_CELLPHONE: 'Digite seu celular',
      PASSWORD: 'Senha',
      TYPE_YOUR_PASSWORD: 'Digite sua senha',
      ENTER: 'Entrar',
    },
    LIST_HOUSES: {
      PROPERTIES_FOUND: 'properties found',
      PROPERTY_FOUND: 'propertie found',
      PROPERTIES_NOT_FOUND: 'Unable to find properties',
      SUITS: 'suites',
      BATHROOMS: 'bathrooms',
      GUESTS: 'guests',
      ORDER: 'Order',
      MORE_RELEVANT: 'More relevant',
      LOWER_PRICE: 'Lower price',
      BIGGER_PRICE: 'Higher price',
      FILTERS: 'Filters',
      PRICE: 'Price',
      UNTIL: 'Until',
      ROOMS: 'Rooms',
      VACANCIES: 'Vacancies',
      AMENITIES: 'Amenities',
      AIR_CONDITIONING: 'Air conditioning',
      GYM: 'Gym',
      PETS: 'Pets',
      GRILL: 'Grill',
      CLUB: 'Club',
      FULL_KITCHEN: 'Full kitchen',
      BABY_CARRIAGE: 'Baby carriage',
      GOLF: 'Golf',
      WIFI: 'Wi-Fi',
      POOL: 'Pool',
      PRIVATE_POOL: 'Private pool',
      TENNIS_COURT: 'Tennis court',
      SPA: 'Spa',
      VIDEO_GAME: 'Video game',
      CLEAN_FILTER: 'Clear Filter',
      SEARCH: 'Search',
      DAILY: 'daily',
      MIN: 'Min',
      MAX: 'Max',
    },
    YUP: {
      OBJECT: {
        FIELD_HAVE_UNKNOWN_PROPERTIES: 'The field has unknown properties: ',
      },
      MIXED: {
        ISNT_VALID: 'Is not valid',
        REQUIRED_FIELD: 'Required field',
        INVALID_TYPE: 'Invalid type',
        HAS_TO_BE_ONE_OF_THE_VALUES: 'Must be one of the values',
        CANT_BE_ONE_OF_THE_VALUES: 'Cannot be this value',
        VALUE_HAS_TO_BE_DEFINED: 'The value must be defined',
      },
      NUMBER: {
        HAS_TO_BE_A_POSITIVE_NUMBER: 'Must be a positive number',
        HAS_TO_BE_A_NEGATIVE_NUMBER: 'Must be a negative number',
        HAS_TO_BE_A_INTEGER_NUMBER: 'Must be an integer number',
        MUST_BE_LESS_THAN: 'Must be less than ',
        MUST_BE_BIGGER_THAN: 'Must be bigger than ',
        HAS_TO_BE_LESS_THAN: 'Must be less than ',
        HAS_TO_BE_MORE_THAN: 'Must be more than ',
      },
      STRING: {
        MUST_BE_A_VALID_EMAIL: 'Must be a valid email',
        MUST_BE_A_VALID_URL: 'Must be a valid URL',
        MUST_BE_A_UUID: 'Must be a unique code',
        MUST_BE_A_TRIM: 'Must be a trimmed text',
        MUST_BE_A_LOWERCASE_TEXT: 'Must be a lowercase text',
        MUST_BE_A_UPPERCASE_TEXT: 'Must be an uppercase text',
        MUST_HAVE_EXACTLY_CARACTER_PLURAL: 'Must contain exactly {{value}} characters',
        MUST_HAVE_EXACTLY_CARACTER: 'Must contain exactly {{value}} character',
        MUST_HAVE_AT_LEAST_CARACTER_PLURAL: 'Must contain at least {{value}} characters',
        MUST_HAVE_AT_LEAST_CARACTER: 'Must contain at least {{value}} character',
        MUST_HAVE_AT_MAXIMUM_CARACTER_PLURAL: 'Must contain at most {{value}} characters',
        MUST_HAVE_AT_MAXIMUM_CARACTER: 'Must contain at most {{value}} character',
        MUST_MATCHES_TO_THIS_VALUE: 'Must match the following value: {{value}}',
      },
      DATE: {
        DATE_MUST_BE_LESS_THAN: 'The date must be less than {{value}}',
        DATE_MUST_BE_MORE_THAN: 'The date must be more than {{value}}',
      },
      BOOLEAN: {
        VALUE_HAS_TO_BE: 'The value has to be {{value}}',
      },
      ARRAY: {
        MUST_CONTAIN_AT_LEAST_PLURAL: 'Must contain at least {{value}} items',
        MUST_CONTAIN_AT_LEAST: 'Must contain at least {{value}} item',
        MUST_CONTAIN_AT_MAX_PLURAL: 'Must contain at most {{value}} items',
        MUST_CONTAIN_AT_MAX: 'Must contain at most {{value}} item',
        MUST_CONTAIN_PLURAL: 'Must contain {{value}} items',
        MUST_CONTAIN: 'Must contain {{value}} item',
      },
      CUSTOM: {
        CANT_BE_THE_SAME_AS_THIS_VALUE: 'Cannot be the same as this value',
        MUST_BE_LESS_OR_EQUAL_THAN: 'Must be less than or equal to {{value}}',
        MUST_BE_MORE_OR_EQUAL_THAN: 'Must be more than or equal to {{value}}',
        CANT_BE_ZERO: 'The value cannot be zero.',
      },
    },
  },
  LOGIN: {
    EMAIL: 'E-mail',
    TYPE_YOUR_EMAIL: 'Digite seu e-mail',
    CELLPHONE: 'Celular',
    TYPE_YOUR_CELLPHONE: 'Digite seu celular',
    PASSWORD: 'Senha',
    TYPE_YOUR_PASSWORD: 'Digite sua senha',
    ENTER: 'Entrar',
  },
  LIST_HOUSES: {
    PROPERTIES_FOUND: 'properties found',
    PROPERTY_FOUND: 'propertie found',
    PROPERTIES_NOT_FOUND: 'Unable to find properties',
    SUITS: 'suites',
    BATHROOMS: 'bathrooms',
    GUESTS: 'guests',
    ORDER: 'Order',
    MORE_RELEVANT: 'More relevant',
    LOWER_PRICE: 'Lower price',
    BIGGER_PRICE: 'Higher price',
    FILTERS: 'Filters',
    PRICE: 'Price',
    UNTIL: 'Until',
    ROOMS: 'Rooms',
    VACANCIES: 'Vacancies',
    AMENITIES: 'Amenities',
    AIR_CONDITIONING: 'Air conditioning',
    GYM: 'Gym',
    PETS: 'Pets',
    GRILL: 'Grill',
    CLUB: 'Club',
    FULL_KITCHEN: 'Full kitchen',
    BABY_CARRIAGE: 'Baby carriage',
    GOLF: 'Golf',
    WIFI: 'Wi-Fi',
    POOL: 'Pool',
    PRIVATE_POOL: 'Private pool',
    TENNIS_COURT: 'Tennis court',
    SPA: 'Spa',
    VIDEO_GAME: 'Video game',
    CLEAN_FILTER: 'Clear Filter',
    SEARCH: 'Search',
    DAILY: 'daily',
    MIN: 'Min',
    MAX: 'Max',
  },
  YUP: {
    OBJECT: {
      FIELD_HAVE_UNKNOWN_PROPERTIES: 'The field has unknown properties: ',
    },
    MIXED: {
      ISNT_VALID: 'Is not valid',
      REQUIRED_FIELD: 'Required field',
      INVALID_TYPE: 'Invalid type',
      HAS_TO_BE_ONE_OF_THE_VALUES: 'Must be one of the values',
      CANT_BE_ONE_OF_THE_VALUES: 'Cannot be this value',
      VALUE_HAS_TO_BE_DEFINED: 'The value must be defined',
    },
    NUMBER: {
      HAS_TO_BE_A_POSITIVE_NUMBER: 'Must be a positive number',
      HAS_TO_BE_A_NEGATIVE_NUMBER: 'Must be a negative number',
      HAS_TO_BE_A_INTEGER_NUMBER: 'Must be an integer number',
      MUST_BE_LESS_THAN: 'Must be less than ',
      MUST_BE_BIGGER_THAN: 'Must be bigger than ',
      HAS_TO_BE_LESS_THAN: 'Must be less than ',
      HAS_TO_BE_MORE_THAN: 'Must be more than ',
    },
    STRING: {
      MUST_BE_A_VALID_EMAIL: 'Must be a valid email',
      MUST_BE_A_VALID_URL: 'Must be a valid URL',
      MUST_BE_A_UUID: 'Must be a unique code',
      MUST_BE_A_TRIM: 'Must be a trimmed text',
      MUST_BE_A_LOWERCASE_TEXT: 'Must be a lowercase text',
      MUST_BE_A_UPPERCASE_TEXT: 'Must be an uppercase text',
      MUST_HAVE_EXACTLY_CARACTER_PLURAL: 'Must contain exactly {{value}} characters',
      MUST_HAVE_EXACTLY_CARACTER: 'Must contain exactly {{value}} character',
      MUST_HAVE_AT_LEAST_CARACTER_PLURAL: 'Must contain at least {{value}} characters',
      MUST_HAVE_AT_LEAST_CARACTER: 'Must contain at least {{value}} character',
      MUST_HAVE_AT_MAXIMUM_CARACTER_PLURAL: 'Must contain at most {{value}} characters',
      MUST_HAVE_AT_MAXIMUM_CARACTER: 'Must contain at most {{value}} character',
      MUST_MATCHES_TO_THIS_VALUE: 'Must match the following value: {{value}}',
    },
    DATE: {
      DATE_MUST_BE_LESS_THAN: 'The date must be less than {{value}}',
      DATE_MUST_BE_MORE_THAN: 'The date must be more than {{value}}',
    },
    BOOLEAN: {
      VALUE_HAS_TO_BE: 'The value has to be {{value}}',
    },
    ARRAY: {
      MUST_CONTAIN_AT_LEAST_PLURAL: 'Must contain at least {{value}} items',
      MUST_CONTAIN_AT_LEAST: 'Must contain at least {{value}} item',
      MUST_CONTAIN_AT_MAX_PLURAL: 'Must contain at most {{value}} items',
      MUST_CONTAIN_AT_MAX: 'Must contain at most {{value}} item',
      MUST_CONTAIN_PLURAL: 'Must contain {{value}} items',
      MUST_CONTAIN: 'Must contain {{value}} item',
    },
    CUSTOM: {
      CANT_BE_THE_SAME_AS_THIS_VALUE: 'Cannot be the same as this value',
      MUST_BE_LESS_OR_EQUAL_THAN: 'Must be less than or equal to {{value}}',
      MUST_BE_MORE_OR_EQUAL_THAN: 'Must be more than or equal to {{value}}',
      CANT_BE_ZERO: 'The value cannot be zero.',
    },
  },

  CHECKOUT: {
    CONFIRM_BOOKING: 'Confirm booking',
    YOUR_TRAVEL: 'Your trip',
    FORM: {
      DATES: 'Dates',
      GUESTS: 'Guests',
      TO_EDIT: 'To edit',
      PAY_WITH: 'Pay with',
      YOUR_DATA: 'Your data',
      NAME_PLACEHOLDER: 'Type you name',
      SURNAME_PLACEHOLDER: 'Type your surname',
      DOCUMENT_PLACEHOLDER: 'Type your document',
      PHONE_NUMBER_PLACEHOLDER: 'Type your phone number',
      ADDRESS_PLACEHOLDER: 'Type your address',
      POSTAL_CODE_PLACEHOLDER: 'Type your postal code',
      COUNTRY_PLACEHOLDER: 'Select your country',
      CITY_PLACEHOLDER: 'Type your city',
      STATE_PLACEHOLDER: 'Type your state',
      AGENTS_CODE_PLACEHOLDER: "Type your agent's code",
      AGENTS_NAME_PLACEHOLDER: "Type your agent's name",
      AGENCIA_PLACEHOLDER: 'Type your agencia',
      NAME: 'Name',
      SURNAME: 'Surname',
      DOCUMENT: 'Document',
      PHONE_NUMBER: 'Phone number',
      ADDRESS: 'Address',
      POSTAL_CODE: 'Postal code',
      COUNTRY: 'Country',
      CITY: 'City',
      STATE: 'State',
      AGENT_DATA: 'Agent data',
      AGENTS_CODE: "Agent's code",
      AGENTS_NAME: "Agent's name",
      AGENCIA: 'Agencia',
      INPORTANT_INFORMATION: 'Inportant information',
      INSTALLMENT: 'installment',
      OF: ' of R$ ',
      INTEREST_FREE: 'PIX DISCOUNT',
      INSTALLMENT_PLACEHOLDER: 'Select the number of installments',
    },
    INFORMATIONS: {
      NEED_KNOW: 'What you need to know ?',
      NEED_KNOW_DESCRIPTION: [
        'Extra people may incur additional fees that vary depending on the property’s policy.',
        'Official photo ID and credit card, debit card or cash deposit may be required upon check-in for extra charges.',
        'Special requests are subject to availability at check-in and may incur additional fees.',
        'These requests are not guaranteed. Parties or group events on site are strictly prohibited. The security features available at the property include fire extinguisher and smoke detector.',
      ],
      PAYMENT_TERMS: [
        'By clicking the button below, I agree to the following policies: House Rules established by the owner, Basic rules for guests, Refund Policy. In addition, I agree that the Platform may send the charge to my payment method if I am responsible for any damage. I agree to pay the full amount indicated if the owner accepts my booking request. Terms of Payment between you and Plataforma Plataforma Digital Ltda.',
        'I also agree to the updated Terms of Service, Payment Terms of Service and confirm that I have read the Privacy Policy.',
      ],
    },
    HOUSE_DETAILS: {
      PRICE_INFO: 'Price information',
      SERVICES_FEE: 'Services fee',
      NIGHTS: 'nights',
    },
    ACTIONS: {
      SUBMIT: 'Confirm booking',
      BOOKING_MADE: 'Booking made with success!',
      FAILURE_PAYMENT: 'Failure to pay',
      EXTERNAL_LINK: 'Generate payment link',
    },
  },
  HOUSE_DETAILS: {
    CHECKOUT: {
      TOTAL_REMINDER: 'Total (Excluding taxes)',
      CANCELLATION_UNTIL: 'Free cancellation up to one day before check-in',
      GUESTS: 'guests',
      CURRENCY: '$',
      ADULTS: 'Adults',
      CHILDS: 'Children',
      PETS: 'Pets',
      BABIES: 'Babies',
      ELDERLY: 'Elderly',
      PCD: 'PwD',
      ADULTS_HELP_TEXT: '17 years and older',
      CHILDS_HELP_TEXT: '2 to 17 years old',
      BABIES_HELP_TEXT: 'Under 2 years',
      ELDERLY_HELP_TEXT: '60 years and older',
      PCD_HELP_TEXT: 'People with disabilities',
      PETS_HELP_TEXT: 'Small pets',
      BUTTON_RESERVE: 'Reserve',
      BUTTON_LOGIN: 'Access your account',
      SHOW_MORE: 'Show all {{quantity}} amenities',
      SHOW_LESS: 'Hide amenities',
      ERROR_0_GUESTS: 'Please select at least one guest to make the reservation.',
    },
    ABOUT_HOUSE: 'About this house',
    EXPLORE_REGION: 'Explore the region',
    RULES: {
      TITLE: 'Property rules',
      CHECK_IN: 'Check-in',
      MINIMUM_AGE_TO_RENT: 'Minimum age to rent',
      PETS_ALLOWED: 'Pets allowed',
      EVENTS: 'Events',
      CHECK_OUT: 'Check-out',
      CHILDREN: 'Children',
      SMOKING_POLICY: 'Smoking policy',
      DAMAGES: 'Additional damages and expenses',
      DESCRIPTION: {
        CHECK_IN: 'After',
        MINIMUM_AGE_TO_RENT: '21 years old',
        PETS_ALLOWED: 'Accepts pets',
        PETS_NOT_ALLOWED: 'Does not accept pets',
        EVENTS: 'Not allowed',
        CHECK_OUT: 'Before',
        CHILDREN: 'Accepts guests from 0 to 17 years old',
        SMOKING_POLICY: 'Smoking policy',
        DAMAGES:
          'You will be responsible for any damage to the rental property caused by you or anyone else during your stay.',
      },
    },
    NOTES: {
      TITLE: 'Important information',
      SUBTITLE: 'What you need to know',
      EXTRA_PEOPLE: "Extra people may incur additional fees that vary depending on the property's policy.",
      OFFICIAL_ID:
        'Official photo identification and credit card, debit card, or cash deposit may be required at check-in for extra expenses.',
      SPECIAL_REQUESTS:
        'Special requests are subject to availability at check-in and may incur additional charges. These requests are not guaranteed.',
      NO_GUARANTEE:
        'Parties or group events on-site are strictly prohibited. Safety features available at the property include a fire extinguisher and smoke detector.',
    },
  },
  RESERVATION_CONFIRMED: {
    TITLE: 'Reservation confirmed',
    DEAR: 'Dear,',
    DESCRIPTIONS: [
      'Thank you for choosing',
      'for your upcoming stay! We are thrilled to welcome you and want to ensure your experience is exceptional. Here are the details of your reservation:',
    ],
    RESERVATION_ID: 'Reservation Id',
    CHECKIN_DATE: 'Check-in Date:',
    CHECKOUT_DATE: 'Check-out Date:',
    TOTAL_COST: 'Total Cost:',
  },
};
