export default {
  AGENT: {
    CONFIRM: {
      AGENCY: 'Agency',
      NAME: 'Name',
      NATIONAL_REGISTRATION: 'Document',
      EMAIL: 'Email',
      CONTACT: 'Contact',
      BUTTON: 'Allow access',
      CANCEL: 'Cancel',
      TITLE: 'Confirme os dados do agente:',
    },
  },
  LANGUAGE: {
    CHOOSE: {
      TITLE: 'Choose the desired language to continue the registration and category:',
      CATEGORY_INPUT: {
        LABEL: 'Category',
        PLACEHOLDER: 'Select a category',
      },
      CATEGORY: {
        AGENT: 'Travel Agent',
        TRAVEL_AGENCY: 'Travel Agency',
        PROPERTY_MANAGER: 'Property Manager',
        FREELANCER: 'Freelancer',
        GOWEASY: 'GoWeasy',
        TRAVEL_OPERATOR: 'Travel Operator',
        AGENCY_OPERATOR: 'Agency Operator',
        AGENCY: 'Agency',
        OPERATOR: 'Operator',
      },
      CONFIRM: 'Confirm',
    },
  },
};
