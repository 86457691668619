import { useContext, useEffect, useState } from 'react';
import useAsync from 'hooks/useAsync.hook';
// import authenticateChatbot from 'services/AIChat/authenticateChatbot.service';
import sendChatMessage from 'services/AIChat/sendMessage.service';
import { clearTokenListener, createTokenListener, getUser, isLogged } from 'services/Login/user.service';
import ChatbotSearchContext from 'store/context/chatbot-search.context';
import { useNavigate } from 'react-router-dom';
import { formatDateFilter } from 'utils/format';

const ALLOWED_AGENCY = 1;

const useAIChat = () => {
  const user = getUser();
  const userAllowed = isLogged() && user?.agency?.id === ALLOWED_AGENCY;
  const [token] = useState<string>();
  const [userValid, setUserValid] = useState(false);

  const navigate = useNavigate();
  const { setSections } = useContext(ChatbotSearchContext);

  useEffect(() => {
    if (isLogged()) {
      authenticate();
    }
  }, [userValid]);

  useEffect(() => {
    setUserValid(userAllowed);
  }, [userAllowed]);

  useEffect(() => {
    createTokenListener(() => {
      setUserValid(userAllowed);
    });

    () => {
      clearTokenListener(() => {
        setUserValid(userAllowed);
      });
    };
  }, []);

  const { call: authenticate } = useAsync(async () => {
    // Removido temporariamente pois a nova API não exige autenticação ainda.
    // const newToken = await authenticateChatbot();
    // setToken(newToken);
  }, []);

  const { call: sendMessage, loading } = useAsync(
    async (message: string) => {
      // if (!token) return;

      const result = await sendChatMessage(message);

      if (result.houses.length > 0) {
        setSections(result.houses);
        navigate(
          `/list-houses?checkIn=${formatDateFilter(result.checkin)}&checkOut=${formatDateFilter(
            result.checkout,
          )}&adults=${result.guests}`,
        );
      }

      return result.message;
    },
    [token],
  );

  return {
    chatbotAvailable: userValid,
    sendMessageLoading: loading,
    sendMessage: sendMessage,
  };
};

export default useAIChat;
