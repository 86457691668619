import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import { LocationContainer, LocationTitle } from './Location.style';
import L from 'leaflet';
import icon from 'assets/svg/pin-go-weasy.svg';
import React from 'react';
import { ICoordinates } from 'models/application/interfaces/ICoordinates.interface';
import Sea from 'assets/icons/sea.png';
import Disney from 'assets/icons/disney.png';
import Walmart from 'assets/icons/walmart.png';
import PlanetEarth from 'assets/icons/planet-earth.png';
import Oscar from 'assets/icons/statue.png';
import WaterSlide from 'assets/icons/water-slide.png';
import ThemePark from 'assets/icons/theme-park.png';
import Livestock from 'assets/icons/livestock.png';
import Blizzard from 'assets/icons/blizzard.png';
import styles from './styles.module.scss';
import HouseCard from 'components/HouseCard';
import { HouseResume } from 'models/application/HouseResume.model';
import styled from 'styled-components';
import Condominium from 'models/application/Condominium.model';
import CardCondominium from 'components/CardCondominium';

const DEFAULT_ZOOM = 16;
const MIN_ZOOM = 12;

const ATTRACTIONS = [
  {
    name: 'Disney',
    icon: Disney,
    coordinates: { lat: 28.41783, long: -81.57533 },
    size: new L.Point(60, 60),
    maxZoom: 10,
  },
  {
    name: 'SeaWorld Orlando',
    icon: Sea,
    coordinates: { lat: 28.4065, long: -81.4575 },
    size: new L.Point(40, 40),
    maxZoom: 10,
  },
  {
    name: 'Walmart',
    icon: Walmart,
    coordinates: { lat: 28.5158857, long: -81.4706849 },
    size: new L.Point(40, 40),
    maxZoom: 10,
  },
  {
    name: 'Blizzard',
    icon: Blizzard,
    coordinates: { lat: 28.3522517, long: -81.5764737 },
    size: new L.Point(40, 40),
    maxZoom: 16,
  },
  {
    name: 'Hollywood studios',
    icon: Oscar,
    coordinates: { lat: 28.3580674, long: -81.5639684 },
    size: new L.Point(40, 40),
    maxZoom: 10,
  },
  {
    name: 'Animal kingdom',
    icon: Livestock,
    coordinates: { lat: 28.3548112, long: -81.6021759 },
    size: new L.Point(40, 40),
    maxZoom: 16,
  },
  {
    name: 'Epcot',
    icon: PlanetEarth,
    coordinates: { lat: 28.3764734, long: -81.5519783 },
    size: new L.Point(40, 40),
    maxZoom: 16,
  },
  {
    name: 'Universal Studios',
    icon: ThemePark,
    coordinates: { lat: 28.4793801, long: -81.4711171 },
    size: new L.Point(40, 40),
    maxZoom: 10,
  },
  {
    name: 'Typhoon Lagoon',
    icon: WaterSlide,
    coordinates: { lat: 28.3663612, long: -81.5310256 },
    size: new L.Point(40, 40),
    maxZoom: 16,
  },
];

const StyledPop = styled(Popup)<any>`
  padding: 0;
  border-radius: 0;
  .leaflet-popup-content-wrapper {
    padding: 0px;
    background: transparent;
    box-shadow: none;

    .leaflet-popup-content {
      width: 300px;
      height: ${(props) => (props.height ? props.height : '345px')};
    }
  }

  a {
    display: none;
  }

  .leaflet-popup-tip-container {
    visibility: hidden;
  }
`;

const iconPerson = new L.Icon({
  iconUrl: icon,
  iconRetinaUrl: icon,
  iconSize: new L.Point(45, 55),
});

interface SearchMapProps {
  houses: HouseResume[];
  condominiums?: Condominium[];
  zoom?: number;
  className?: string;
  title?: string;
  center: ICoordinates;
  onClickMarker?: (id: number | string) => void;
}

const SearchMap: React.FC<SearchMapProps> = ({
  houses,
  zoom = DEFAULT_ZOOM,
  title,
  className,
  onClickMarker,
  center,
  condominiums = [],
}) => {
  const handleClick = (id: number | string) => {
    if (!onClickMarker) return;

    onClickMarker(id);
  };

  return (
    <LocationContainer>
      {title ? <LocationTitle>{title}</LocationTitle> : null}
      <MapContainer
        style={{ height: '500px', zIndex: 0 }}
        center={[center.lat, center.long]}
        zoom={zoom}
        minZoom={MIN_ZOOM}
        className={className}
      >
        <TileLayer url="https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png?api_key=18bd8354-7dd9-476b-a618-b171f294926e" />
        {houses.map((house) => {
          if (!house.coordinates) return null;

          return (
            <Marker
              key={house.id}
              riseOnHover
              icon={iconPerson}
              position={[house.coordinates.lat, house.coordinates.long]}
              eventHandlers={{
                mouseover: (event) => event.target.openPopup(),
                mouseout: (event) => event.target.closePopup(),
                click: () => handleClick(house.id),
              }}
            >
              <StyledPop autoPan={false}>
                <HouseCard
                  image={house.mainPicture}
                  pricePerNight={house.getPrice()}
                  address={house.name}
                  name={house.name}
                  nameEs={house.nameEs}
                  namePt={house.namePt}
                  nameUs={house.nameUs}
                  id={house.id}
                  spaces={house.amenities.map((amenitie) => amenitie.name)}
                  rooms={house.bedroomAmount}
                  guests={house.maxOccupancy}
                  onClickItem={handleClick}
                  staticCard
                />
              </StyledPop>
            </Marker>
          );
        })}
        {condominiums.map((condominium, index) => (
          <Marker
            key={index}
            riseOnHover
            icon={iconPerson}
            position={[condominium.coordenate.lat, condominium.coordenate.long]}
            eventHandlers={{
              mouseover: (event) => event.target.openPopup(),
              mouseout: (event) => event.target.closePopup(),
              click: () => handleClick(condominium.name),
            }}
          >
            <StyledPop autoPan={false} height={'200px'}>
              <CardCondominium condominium={condominium} onClick={() => handleClick(condominium.name)} />
            </StyledPop>
          </Marker>
        ))}
        {ATTRACTIONS.map((attraction) => (
          <div key={attraction.name}>
            <Marker
              icon={new L.Icon({ iconUrl: attraction.icon, iconRetinaUrl: attraction.icon, iconSize: attraction.size })}
              position={[attraction.coordinates.lat, attraction.coordinates.long]}
            ></Marker>
            <Marker
              icon={L.divIcon({
                html: attraction.name,
                className: styles.marker,
              })}
              position={[attraction.coordinates.lat, attraction.coordinates.long]}
            ></Marker>
          </div>
        ))}
      </MapContainer>
    </LocationContainer>
  );
};

export default SearchMap;
