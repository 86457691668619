export default {
  BACKOFFICE: {
    MANAGEMENT: 'Management',
    SEARCH: 'Search by name or code',
    RESERVATIONS: 'Reservations',
    COMMISSIONS: 'Commissions',
    AVAILABILITY: 'Check Availability',
    REPORTS: 'Reports',
    REGISTER: 'Register user',
    INACTIVE_ACCOUNT: 'Inactive account',
    SAVE_CHANGES: 'Save changes',
    OPERATOR: 'Operator',
    REGISTER_TITLE: 'What would you like to register?',
    TABLE: {
      MORE_INFORMATION: 'MORE INFORMATION',
      LINKED_AGENCIES: 'LINKED AGENCIES',
      LINKED_AGENTS: 'LINKED AGENTS',
      MONTH_TOTAL_SALES: 'MONTH TOTAL SALES',
      MONTH_TOTAL_RESERVATIONS: 'MONTH TOTAL RESERVATIONS',
      CODE: 'CODE',
      NAME: 'NAME',
      TYPE: 'TYPE',
      CONTACT: 'CONTACT',
      ADMIN: 'Manager',
      USER: 'Agent',
      STATUS: 'STATUS',
      ACTIVE: 'Active',
      WAITING_ACCESS: 'Waiting Access',
      OPERATOR: 'OPERATOR',
      RESERVATION: 'RESERVATION',
      CLIENT_NAME: 'CLIENT NAME',
      HOUSE: 'HOUSE',
      SOLD_BY: 'SOLD BY',
      PURCHASED_BY: 'PURCHASED BY',
      AGENCY: 'AGENCY',
      AGENCIES: 'AGENCIES',
      AGENTS: 'AGENTS',
      VALUE: 'VALUE',
      DAILY_RATES: 'DAILY RATES',
      CHANGE: 'CHANGE',
      PRE_BOOKING: 'PRE-BOOKING',
      CONFIRMED: 'CONFIRMED',
      CANCELED: 'CANCELED',
    },
    AGENT: {
      PERSONAL_DATA: 'Personal Data',
      CODE: 'Code',
      NAME: 'Name',
      EMAIL: 'Email',
      ROLE: 'Role',
      CELLPHONE: 'Cellphone',
      NATIONAL_REGISTRATION: 'Document',
      ZIPCODE: 'ZIP Code',
      ADDRESS: 'Address',
      COUNTRY: 'Country',
      STATE: 'State',
      CITY: 'City',
      SAVE: 'Save',
      PASSWORD: 'Password',
      FEEDBACK: {
        EDIT_SUCCESS: 'Agent edited successfully',
        CREATE_SUCCESS: 'Agent created successfully',
      },
    },
    AGENCY: {
      ID: 'ID',
      NAME: 'Name',
      EMAIL: 'Email',
      CADASTUR: 'CADASTUR',
      PRIMARY_CONTACT: 'Primary Contact',
      URL_SITE: 'URL Site',
      DOCUMENT: 'Document',
      PROFIT_PERCENTAGE: 'Profit Percentage',
      FEEDBACK: {
        EDIT_SUCCESS: 'Agency edited successfully',
      },
    },
    RESERVATIONSCREEN: {
      COMPLETED: 'completed',
      CONFIRMED: 'confirmed',
      CANCELED: 'canceled',
      SEARCH_BY_RESERVATION_ID: 'Search by reservation ID',
      RESERVATIONID: 'Reservation ID',
      CUSTOMERNAME: 'Customer Name',
      PROPERTY: 'Property',
      AGENT: 'Agent',
      AGENCY: 'Agency',
      DOLLARAMOUNT: 'Dollar Amount',
      REALAMOUNT: 'Real Amount',
      NIGHTS: 'Nights',
      STATUS: 'Status',
      DOWNLOAD: 'Generate Reports',
    },
    RESERVATION_DETAIL: {
      RESERVATION_SUMMARY: 'Reservation Summary',
      SUMMARY: 'Summary',
      VALUES: 'Values',
      HISTORY: 'History',
      DATES: 'Dates',
      GUESTS: 'Guests',
      DAILY_RATE: 'Daily Rate',
      NAME: 'Name',
      CONTACT: 'Contact',
      ADULTS: 'Adults',
      ADDRESS: 'Address',
      HOUSE_ID: 'House ID',
      CREATE_AT: 'Created at',
      ACCOMMODATION_ADDRESS: 'Accommodation Address',
      SHOW_ON_MAP: 'Show on map',
      DETAILS: 'Details',
      PAYMENT_INFORMATION: 'Payment Information',
      REFUND_POLICY: 'Refund Policy',
      AMOUNTS: 'Amounts',
      CLEANING_FEE: 'Cleaning Fee',
      GOWEASY_SERVICE_FEE: 'GoWeasy Service Fee',
      TAXES: 'Taxes',
      COMMISSION: 'Commission',
      PAYMENT_INFORMATION_TEXT: `If the Host cancels a reservation before check-in,
      the guest will receive a full refund automatically.
      If the Host cancels a reservation up to 30 days before check-in,
      we'll also help the guest find a similar place, depending on availability in the same price range.`,
      PAYMENT_LINK: 'Payment Link',
      TIME: 'TIME',
      RESERVATION: 'RESERVATION',
      CLIENT_NAME: 'CLIENT NAME',
      PROPERTY: 'PROPERTY',
      AGENCY: 'AGENCY',
      CATEGORY: 'CATEGORY',
      RESPONSIBLE: 'RESPONSIBLE',
      DESCRIPTION: 'DESCRIPTION',
      PENDING: 'PENDING',
      CONFIRMED: 'CONFIRMED',
      CANCELED: 'CANCELED',
    },
    COMMISSIONS_TABLE: {
      COMMISSION_QUERY: 'Commission query',
      TOTAL_MONTHLY_COMMISSIONS: 'Total monthly commissions',
      SALES_CANCELED_IN_THE_MONTH: 'Sales canceled in the month',
      SEARCH_BY_RESERVATION_CODE: 'Search by reservation code',
      PENDING: 'PENDING',
      CANCELED: 'CANCELED',
      PAID: 'PAID',
      BOOKING_COMPLETED: 'Booking Completed',
      PAYMENT_PENDING: 'Payment Pending',
      BOOKING_CANCELED: 'Booking Canceled',
      PAYMENT_COMPLETED: 'Paid',
      DATE: 'DATE',
      RESERVATION: 'RESERVATION',
      PROPERTY: 'PROPERTY',
      SOLD_BY: 'SOLD BY',
      AGENCY: 'AGENCY',
      SALE_VALUE: 'SALE VALUE',
      COMMISSION: 'COMMISSION',
      STATUS: 'STATUS',
      PAYMENT_HAS_BEEN_COMPLETED: 'Payment Completed',
      REPORTS: {
        DATE: 'Date',
        RESERVACODE: 'Reservation Code',
        HOUSEID: 'House ID',
        AGENTE: 'Agent',
        AGENCY: 'Agency',
        VALUEREAL: 'Value in Real',
        VALUEDOLLAR: 'Value in Dollar',
        COMISSAOREAL: 'Commission in Real',
        COMISSAODOLLAR: 'Commission in Dollar',
        COMISSAOPORCENTAGEM: 'Commission Percentage',
        STATUS: 'Status',
        AGENCY_PAYMENT: 'Agency Payment',
      },
    },
  },
};
